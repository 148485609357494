import { cronosTokens } from '@pancakeswap/tokens'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedFarmConfig } from '../../types'

const priceHelperLps: SerializedFarmConfig[] = [
    /**
     * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
     * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
     * The absence of a PID means the masterchef contract calls are skipped for this farm.
     * Prices are then fetched for all farms (masterchef + priceHelperLps).
     * Before storing to redux, farms without a PID are filtered out.
     */
    {
      pid: null,
      lpSymbol: 'Troll-CRO LP',
      lpAddress: '0xBcFD3E2c802b7f654bFb28B962313493DBC8b794',
      token: cronosTokens.troll,
      quoteToken: cronosTokens.wcro,
    },
  ].map((p) => ({ ...p, token: serializeToken(p.token), quoteToken: serializeToken(p.quoteToken) }))

export default priceHelperLps
