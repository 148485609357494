import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_ONLY_CRONOS = [ChainId.CRONOS]
export const SUPPORT_SWAP = [ChainId.CRONOS, ChainId.POLYGON, ChainId.CRONOS_TESTNET]
export const SUPPORT_LAUNCHPAD = [ChainId.CRONOS, ChainId.POLYGON, ChainId.CRONOS_TESTNET]

// export const SUPPORT_FARMS = [ChainId.BSC, ChainId.BSC_TESTNET]
export const SUPPORT_FARMS = [ChainId.CRONOS, ChainId.CRONOS_TESTNET]

export const SUPPORT_VESTING = [ChainId.CRONOS, ChainId.CRONOS_TESTNET]

export const SUPPORT_LOCK = [ChainId.CRONOS, ChainId.CRONOS_TESTNET]

export const SUPPORT_PARTNER_FARMS = [ChainId.CRONOS, ChainId.POLYGON, ChainId.CRONOS_TESTNET]

export const SUPPORT_VAULTS = [ChainId.CRONOS, ChainId.CRONOS_TESTNET]

export const SUPPORT_POOLS = [ChainId.CRONOS, ChainId.POLYGON, ChainId.CRONOS_TESTNET]

export const SUPPORT_NFTS = [ChainId.CRONOS, ChainId.CRONOS_TESTNET, ChainId.POLYGON]

// export const SUPPORT_ZAP = [ChainId.BSC, ChainId.BSC_TESTNET]

export const SUPPORT_ZAP = [ChainId.CRONOS, ChainId.POLYGON, ChainId.CRONOS_TESTNET]

export const SUPPORT_CHART = [ChainId.BSC]

export const SUPPORT_GAME_HUNT = [ChainId.CRONOS]
