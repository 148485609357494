import BigNumber from 'bignumber.js'
import chunk from 'lodash/chunk'
import { ChainId } from '@pancakeswap/sdk'
import candyNftAbi from 'config/abi/candyNft.json'
import candyPolygonNftAbi from 'config/abi/candyPolygonNft.json'
import teendaccNftAbi from 'config/abi/teendaccNft.json'
import bingoNftAbi from 'config/abi/bingoNft.json'
import erc20Abi from 'config/abi/erc20.json'
import { publicClient } from 'utils/wagmi'
import { NftMintType, SerializedNftMintConfig } from 'config/constants/types'
import FreeMerkleTreeTestnet from 'config/constants/nfts/merkle/free-merkle-338.json'
import WlMerkleTreeTestnet from 'config/constants/nfts/merkle/wl-merkle-338.json'
import FreeMerkleTree from 'config/constants/nfts/merkle/free-merkle-25.json'
import WlMerkleTree from 'config/constants/nfts/merkle/wl-merkle-25.json'
import TeenDACCFreeMerkleTree from 'config/constants/nfts/merkle/free-merkle-teendacc.json'
import { BCC_API } from 'config/constants/endpoints'

const fetchSpecialNftMintsUserData = async (account: string, chainId: ChainId, dataToFetch: SerializedNftMintConfig[]) => {
  const client = publicClient({ chainId })
  const balanceCalls = dataToFetch.map((data) => {
    return { abi: candyNftAbi, address: data.collection.address, functionName: 'userMintedCount', args: [account] }
  })

  if (chainId === ChainId.POLYGON) {
    const autoWhitelistedCalls = dataToFetch.map((data) => {
      return { abi: candyPolygonNftAbi, address: data.collection.address, functionName: 'isAutoWhitelisted', args: [account] }
    })
    // @ts-ignore
    const results = await client.multicall({ contracts: balanceCalls.concat(autoWhitelistedCalls), allowFailure: false })
    const [rawBalances, autoWhitelistRes] = results.length > 0 ? chunk(results, dataToFetch.length) : [[], []]
    
    let freeMerkle
    let wlMerkle
    // Fetch whitelist merkle data from the candycity api
    const response = await fetch(`${BCC_API}/fetch-whitelist-data?account=${account}`)
    const merkleRes = await response.json()
    if (merkleRes.status === 'success' && merkleRes.data.proof.length > 0) {
      wlMerkle = merkleRes.data
    }
      
    return rawBalances.map((balance, index) => {
      return {
        id: dataToFetch[index].id,
        wlCount: Number(balance[0]),
        pubCount: Number(balance[1]),
        freeCount: Number(balance[2]), // legendary mint count
        isEbisusBayMember: autoWhitelistRes[index] as boolean, // auto whitelisted flag
        freeMerkle,
        wlMerkle,
      }
    })
  }

  const ebisusBayCalls = dataToFetch.map((data) => {
    return { abi: candyNftAbi, address: data.collection.address, functionName: 'isEbisusBayMember', args: [account] }
  })
  // @ts-ignore
  const results = await client.multicall({ contracts: balanceCalls.concat(ebisusBayCalls), allowFailure: false })
  const [rawBalances, ebisusBayRes] = results.length > 0 ? chunk(results, dataToFetch.length) : [[], []]

  let freeMerkle
  let wlMerkle
  if (chainId === ChainId.CRONOS) {
    if (FreeMerkleTree.claims?.[account]) {
      freeMerkle = FreeMerkleTree.claims?.[account]
    }
    if (WlMerkleTree.claims?.[account]) {
      wlMerkle = WlMerkleTree.claims?.[account]
    }
  } else if (chainId === ChainId.CRONOS_TESTNET) {
    if (FreeMerkleTreeTestnet.claims?.[account]) {
      freeMerkle = FreeMerkleTreeTestnet.claims?.[account]
    }
    if (WlMerkleTreeTestnet.claims?.[account]) {
      wlMerkle = WlMerkleTreeTestnet.claims?.[account]
    }
  }

  return rawBalances.map((balance, index) => {
    return {
      id: dataToFetch[index].id,
      freeCount: Number(balance[0]),
      wlCount: Number(balance[1]),
      pubCount: Number(balance[2]),
      isEbisusBayMember: ebisusBayRes[index] as boolean,
      freeMerkle,
      wlMerkle,
    }
  })
}

const fetchMultiPayNftMintsUserData = async (account: string, chainId: ChainId, dataToFetch: SerializedNftMintConfig[]) => {
  const client = publicClient({ chainId })
  const balanceCalls = dataToFetch.map((data) => {
    return { abi: teendaccNftAbi, address: data.collection.address, functionName: 'userMintedCount', args: [account] }
  })
  const whitelistedCalls = dataToFetch.map((data) => {
    return { abi: teendaccNftAbi, address: data.collection.address, functionName: 'whitelisted', args: [account] }
  })
  const allowanceCalls = dataToFetch.map((data) => {
    return { abi: erc20Abi, address: data.payingToken.address, functionName: 'allowance', args: [account, data.collection.address] }
  })
  const results = await client.multicall({
    // @ts-ignore
    contracts: balanceCalls.concat(whitelistedCalls, allowanceCalls),
    allowFailure: false
  })
  const [rawBalances, whitelistedRes, rawAllowances]
    = results.length > 0 ? chunk(results, dataToFetch.length) : [[], [], []]

  let freeMerkle
  if (chainId === ChainId.CRONOS) {
    if (TeenDACCFreeMerkleTree.claims?.[account]) {
      freeMerkle = TeenDACCFreeMerkleTree.claims?.[account]
    }
  }

  return rawBalances.map((balance, index) => {
    return {
      id: dataToFetch[index].id,
      freeCount: Number(balance[0]),
      wlCount: Number(balance[1]),
      pubCount: Number(balance[2]),
      isWhitelisted: whitelistedRes[index] as boolean,
      freeMerkle,
      allowance: new BigNumber(rawAllowances[index].toString()).toJSON()
    }
  })
}

const fetchCommonNftMintsUserData = async (account: string, chainId: ChainId, dataToFetch: SerializedNftMintConfig[]) => {
  const client = publicClient({ chainId })
  const balanceCalls = dataToFetch.map((data) => {
    return { abi: bingoNftAbi, address: data.collection.address, functionName: 'balanceOf', args: [account] }
  })
  const allowanceCalls = dataToFetch.map((data) => {
    return { abi: erc20Abi, address: data.payingToken.address, functionName: 'allowance', args: [account, data.collection.address] }
  })
  // @ts-ignore
  const results = await client.multicall({ contracts: balanceCalls.concat(allowanceCalls), allowFailure: false })
  const [rawBalances, rawAllowances] = results.length > 0 ? chunk(results, dataToFetch.length) : [[], []]

  return rawBalances.map((balance, index) => {
    return {
      id: dataToFetch[index].id,
      pubCount: Number(balance),
      allowance: new BigNumber(rawAllowances[index].toString()).toJSON()
    }
  })
}

export const fetchNftMintsUserData = async (account: string, chainId: ChainId, dataToFetch: SerializedNftMintConfig[]) => {
  const specialNfts = dataToFetch.filter((nft) => nft.type === NftMintType.SPECIAL)
  const multiPayNfts = dataToFetch.filter((nft) => nft.type === NftMintType.MULTIPAY)
  const commonNfts = dataToFetch.filter((nft) => nft.type === NftMintType.COMMON)

  const specialNftsUserData = await fetchSpecialNftMintsUserData(account, chainId, specialNfts)
  const multiPayNftsUserData = await fetchMultiPayNftMintsUserData(account, chainId, multiPayNfts)
  const commonNftsUserData = await fetchCommonNftMintsUserData(account, chainId, commonNfts)

  // @ts-ignore
  return specialNftsUserData.concat(multiPayNftsUserData, commonNftsUserData)
}
