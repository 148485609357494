import styled from 'styled-components'
import { useBalance } from 'wagmi'
import { BigNumber } from '@ethersproject/bignumber'
import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
  Box, Button, Flex, InjectedModalProps, Link, LinkExternal,
  LogoRoundIcon, Message, Skeleton, Text
} from '@pancakeswap/uikit'
import { FetchStatus } from 'config/constants/types'
import { TRANSAK_API_KEY } from 'config/constants/endpoints'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useGetCakeBalance } from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { formatBigNumber } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & > * {
    flex: 1;
  }
`

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { data, isFetched } = useBalance({ address: account, chainId })
  const native = useNativeCurrency()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useGetCakeBalance()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress account={account} mb="24px" />
      {hasLowNativeBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold">
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </Text>
            <Text as="p">
              {t('You need %currency% for transaction fees.', {
                currency: native.symbol,
              })}
            </Text>
          </Box>
        </Message>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Flex>
          <ChainLogo chainId={native.chainId} />
          <Text ml='8px' color="textSubtle">
            {native.symbol} {t('Balance')}
          </Text>
        </Flex>
        {!isFetched ? <Skeleton height="22px" width="60px" /> : <Text>{formatBigNumber(BigNumber.from(data.value ?? 0), 6)}</Text>}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="24px" mt="12px">
        <Flex alignItems="center">
          <LogoRoundIcon />
          <Text ml='8px' color="textSubtle">
            {t('CANDY Balance')}
          </Text>
        </Flex>
        {cakeFetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{formatBigNumber(cakeBalance, 3)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal href={getBlockExploreLink(account, 'address', chainId)}>
          {t('View on %site%', {
            site: getBlockExploreName(chainId),
          })}
        </LinkExternal>
      </Flex>
      <Footer>
        <Button size='md' variant="secondary" width="100%"
          as={Link} external
          href={`https://global.transak.com/?apiKey=${TRANSAK_API_KEY}&cryptoCurrencyCode=${chainId === ChainId.POLYGON ? 'MATIC' : 'CRO'}&isAutoFillUserData=true&walletAddress=${account}`}>
          {chainId === ChainId.POLYGON ? t('Buy MATIC') : t('Buy CRO')}
        </Button>
        <Button size='md' variant="secondary" width="100%" onClick={handleLogout}>
          {t('Disconnect')}
        </Button>
      </Footer>
    </>
  )
}

export default WalletInfo
