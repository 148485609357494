import { ChainId, Token, WMATIC } from '@pancakeswap/sdk'
import { CANDY_POLYGON, USDC_POLYGON, USDT_POLYGON } from './common'

export const polygonTokens = {
  wmatic: WMATIC[ChainId.POLYGON],
  // matic here points to the wmatic contract. Wherever the currency MATIC is required, conditional checks for the symbol 'MATIC' can be used
  matic: new Token(
    ChainId.POLYGON,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'MATIC',
    'MATIC',
    'https://polygon.technology/',
  ),
  candy: CANDY_POLYGON,
  usdc: USDC_POLYGON,
  usdcpos: new Token(
    ChainId.POLYGON,
    '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    6,
    'USDC',
    'USD Coin',
    'https://www.centre.io/usdc',
  ),
  usdt: USDT_POLYGON,
  weth: new Token(
    ChainId.POLYGON,
    '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    18,
    'WETH',
    'Wrapped Ether',
    'https://ethereum.org/en/',
  ),
  link: new Token(
    ChainId.POLYGON,
    '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
    18,
    'LINK',
    'ChainLink Token',
    'https://cronos.org/',
  ),
  wbtc: new Token(
    ChainId.POLYGON,
    '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    8,
    'WBTC',
    'Wrapped BTC',
    'https://bitcoin.org/',
  ),
  dai: new Token(
    ChainId.POLYGON,
    '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    18,
    'DAI',
    'Dai Stablecoin',
    'https://makerdao.com/',
  ),
  shib: new Token(
    ChainId.POLYGON,
    '0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec',
    18,
    'SHIB',
    'SHIBA INU',
    'https://shibatoken.com/',
  ),
  $hit: new Token(
    ChainId.POLYGON,
    '0x4B28fa4b5941768078Fd471802e74607341d3B7C',
    18,
    '$HIT',
    'HITCOIN',
    'https://shibatoken.com/',
  ),
  hit: new Token(
    ChainId.POLYGON,
    '0x5033C6960001809F5F93db9b4d5F9bCbfF76b524',
    18,
    'HIT',
    'HITCOIN',
    'https://shibatoken.com/',
  ),
  hitmaticlp: new Token(
    ChainId.POLYGON,
    "0xD278f9597A4330FA1ef0ecfF33240190b7E99e97",
    18,
    "HIT-MATIC LP",
    "HIT-MATIC LP"
  ),
}
