import BigNumber from 'bignumber.js'
import { Address } from 'wagmi'
import nftVaultAbi from 'config/abi/nftVault.json'
import erc721ABI from 'config/abi/erc721collection.json'
import RarityMerkleTree from 'config/constants/nfts/merkle/rarity-merkle.json'
import { publicClient } from 'utils/wagmi'
import getBoredCandyNftImageUrl from 'utils/getNftImageUrl'
import { getNftVaultAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { BCC_NFT_IMAGE_EXT, BOOST_PRECISION } from './constants'

export const fetchUserStakedNftsAndBoosts = async (account: string, farmIds: number[], chainId: number) => {
  const nftVaultAddress = getNftVaultAddress(chainId)
  const client = publicClient({ chainId })

  const calls = farmIds.map((farmId) => {
    return {
      abi: nftVaultAbi,
      address: nftVaultAddress,
      functionName: 'viewUserInfo',
      args: [farmId, account],
    }
  })
  // @ts-ignore
  const callsResult = await client.multicall({ contracts: calls, allowFailure: false })
  return callsResult.map((result) => {
    const boosts = getBalanceNumber(new BigNumber(result[0].toString), BOOST_PRECISION)
    const stakedTokens = result[1].map((token) => {
      const tokenId = Number(token)
      // @ts-ignore
      const rarity = RarityMerkleTree.claims?.[tokenId]?.rarity
      // @ts-ignore
      const proof = RarityMerkleTree.claims?.[tokenId]?.proof
      const image = getBoredCandyNftImageUrl(tokenId, BCC_NFT_IMAGE_EXT)

      return { id: tokenId, rarity, image, proof }
    })
    return { boosts, stakedTokens }
  })
}

export const fetchNftVaultApproved = async (account: string, nftAddress: string, chainId: number) => {
  const nftVaultAddress = getNftVaultAddress(chainId)
  const client = publicClient({ chainId })
  const callResult = await client.readContract({
    abi: erc721ABI,
    address: nftAddress as Address,
    functionName: 'isApprovedForAll',
    args: [account, nftVaultAddress]
  })

  return callResult
}
