import {
  cronosTokens,
  cronosTestnetTokens,
  polygonTokens,
  serializeTokens
} from '@pancakeswap/tokens'
import { ChainId } from '@pancakeswap/sdk'
import collections from './collections'
import { NftMintType, SerializedNftMintConfig } from '../types'

const serializedCronosTokens = serializeTokens(cronosTokens)
const serializedCronosTestnetTokens = serializeTokens(cronosTestnetTokens)
const serializedPolygonTokens = serializeTokens(polygonTokens)

const nftMints: SerializedNftMintConfig[] = [
  {
    id: 0,
    chainId: ChainId.CRONOS_TESTNET,
    collection: collections.bcctest,
    payingToken: serializedCronosTestnetTokens.cro,
    type: NftMintType.SPECIAL
  },
  // {
  //   id: 1,
  //   chainId: ChainId.CRONOS,
  //   collection: collections.bcc,
  //   payingToken: serializedCronosTokens.cro,
  //   type: NftMintType.SPECIAL
  // },
  // {
  //   id: 2,
  //   chainId: ChainId.CRONOS,
  //   collection: collections.bingo,
  //   payingToken: serializedCronosTokens.candy,
  //   type: NftMintType.COMMON
  // },
  // {
  //   id: 3,
  //   chainId: ChainId.CRONOS,
  //   collection: collections.teendacc,
  //   payingToken: serializedCronosTokens.candy,
  //   type: NftMintType.MULTIPAY
  // },
  // {
  //   id: 4,
  //   chainId: ChainId.POLYGON,
  //   collection: collections.bccpolygon,
  //   payingToken: serializedPolygonTokens.matic,
  //   type: NftMintType.SPECIAL
  // },
  // {
  //   id: 1,
  //   chainId: ChainId.CRONOS,
  //   collection: collections.duels,
  //   payingToken: serializedCronosTokens.cro,
  //   type: NftMintType.DUELMINT
  // },
]

export default nftMints
