import { polygonTokens } from '@pancakeswap/tokens'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedFarmConfig } from '../../types'

const priceHelperLps: SerializedFarmConfig[] = [
    /**
     * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
     * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
     * The absence of a PID means the masterchef contract calls are skipped for this farm.
     * Prices are then fetched for all farms (masterchef + priceHelperLps).
     * Before storing to redux, farms without a PID are filtered out.
     */
    {
      pid: null,
      lpSymbol: 'HIT-MATIC LP',
      lpAddress: '0xD278f9597A4330FA1ef0ecfF33240190b7E99e97',
      token: polygonTokens.hit,
      quoteToken: polygonTokens.wmatic,
    },
    {
      pid: null,
      lpSymbol: 'MATIC-USDC LP',
      lpAddress: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
      token: polygonTokens.usdcpos,
      quoteToken: polygonTokens.wmatic,
    },
  ].map((p) => ({ ...p, token: serializeToken(p.token), quoteToken: serializeToken(p.quoteToken) }))

export default priceHelperLps
