import { useMemo } from 'react'
import { Abi, Address } from 'viem'
import { erc20ABI, useWalletClient } from 'wagmi'
import { CANDY } from '@pancakeswap/tokens'
import { ChainId, WNATIVE } from '@pancakeswap/sdk'

import zapAbi from 'config/abi/zap.json'

import { 
  getBCakeFarmBoosterAddressMap, 
  getBCakeFarmBoosterProxyFactoryAddressMap, 
  getCandyLockAddressMap, 
  getCandyLockV2AddressMap, 
  getCandyLockV3AddressMap, 
  getKingAddressMap, 
  getMasterChefAddressMap, 
  getMerkleVestingAddressMap, 
  getMulticallAddress, 
  getNftVaultAddressMap, 
  getPartnerLockAddressMap, 
  getPartnerVestingAddressMap, 
  getReferralAddressMap, 
  getTokenFactoryAddressMap, 
  getZapAddress 
} from 'utils/addressHelpers'

import {
  getCakeFlexibleSideVaultV2Contract,
  getCakeVaultV2Contract,
  getContract,
} from 'utils/contractHelpers'

// Imports below migrated from Exchange useContract.ts
import bCakeFarmBoosterAbi from 'config/abi/bCakeFarmBooster.json'
import bCakeFarmBoosterProxyFactoryAbi from 'config/abi/bCakeFarmBoosterProxyFactory.json'
import bCakeProxyAbi from 'config/abi/bCakeProxy.json'
import bingoNftAbi from 'config/abi/bingoNft.json'
import candyLockAbi from 'config/abi/candyLock.json'
import candyLockV2Abi from 'config/abi/candyLockV2.json'
import candyNftAbi from 'config/abi/candyNft.json'
import candyPolygonNftAbi from 'config/abi/candyPolygonNft.json'
import duelNftAbi from 'config/abi/duelNft.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'
import ifoV1Abi from 'config/abi/ifo_v1.json'
import ifoV2Abi from 'config/abi/ifo_v2.json'
import ifoV3Abi from 'config/abi/ifo_v3.json'
import ifoV4Abi from 'config/abi/ifo_v4.json'
import kingAbi from 'config/abi/king.json'
import masterChefAbi from 'config/abi/masterchef.json'
import merkleVestingAbi from 'config/abi/merkleVesting.json'
import nftStakingAbi from 'config/abi/nftStaking.json'
import nftStakingV2Abi from 'config/abi/nftStakingV2.json'
import nftStakingV3Abi from 'config/abi/nftStakingV3.json'
import nftVaultAbi from 'config/abi/nftVault.json'
import partnerLockAbi from 'config/abi/partnerLock.json'
import partnerFarmV2Abi from 'config/abi/partnerFarmV2.json'
import partnerVestingAbi from 'config/abi/partnerVesting.json'
import referralAbi from 'config/abi/referral.json'
import sousChefAbi from 'config/abi/sousChef.json'
import sousChefBnbAbi from 'config/abi/sousChefBnb.json'
import teendaccNftAbi from 'config/abi/teendaccNft.json'
import tokenFactoryAbi from 'config/abi/tokenFactory.json'
import erc20TemplateAbi from 'config/abi/erc20Template.json'

import collections from 'config/constants/nfts/collections'

import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import IPancakePairABI from '../config/abi/IPancakePair.json'
import multiCallAbi from '../config/abi/Multicall.json'
import WETH_ABI from '../config/abi/weth.json'
import { NFT_STAKING_TYPE } from '../config/constants/types'

import { VaultKey } from '../state/types'
import { useActiveChainId } from './useActiveChainId'

/**
 * Helper hooks to get specific contracts (by ABI)
 */
export const useIfoContract = (address: string, version: number) => {
  const abi = version === 1 ? ifoV1Abi 
  : version === 2 ? ifoV2Abi 
  : version === 4 ? ifoV4Abi 
  : ifoV3Abi
  return useContract(address, abi)
}

export const usePartnerLockContract = () => { return useContract(getPartnerLockAddressMap(), partnerLockAbi) }

export const useMerkleVestingContract = () => { return useContract(getMerkleVestingAddressMap(), merkleVestingAbi) }

export const usePartnerVestingContract = () => { return useContract(getPartnerVestingAddressMap(), partnerVestingAbi) }

export const useCandyLockContract = () => { return useContract(getCandyLockAddressMap(), candyLockAbi) }

export const useCandyLockV2Contract = () => { return useContract(getCandyLockV2AddressMap(), candyLockV2Abi) }

export const useCandyLockV3Contract = () => { return useContract(getCandyLockV3AddressMap(), candyLockV2Abi) }

export const useTokenFactoryContract = () => { return useContract(getTokenFactoryAddressMap(), tokenFactoryAbi) }

export const useERC20Template = (address: string) => { return useContract(address, erc20TemplateAbi) }

export const useERC20 = (address: string) => { return useContract(address, erc20ABI) }

export const useErc721 = (address: string) => { return useContract(address, erc721CollectionAbi) }

export const useCandy = () => {
  const { chainId } = useActiveChainId()
  return useContract(CANDY[chainId].address ?? CANDY[ChainId.CRONOS].address, erc20ABI)
}

export const useKingContract = () => { return useContract(getKingAddressMap(), kingAbi) }

export const useMasterchef = () => { return useContract(getMasterChefAddressMap(), masterChefAbi) }

export const useSousChef = (address: string, isBnbPool: boolean) => {
  const abi = isBnbPool ? sousChefBnbAbi : sousChefAbi
  return useContract(address, abi)
}

export const usePartnerFarmContract = (address: string, version: number) => {
  const abi = version === 1 ? sousChefAbi : partnerFarmV2Abi
  return useContract(address, abi)
}

export const useBingoNftContract = (address: string) => {
  return useContract(address, bingoNftAbi)
}

export const useCandyNftContract = (address: string) => {
  return useContract(address, candyNftAbi)
}

export const usePolygonCandyNftContract = (address: string) => {
  return useContract(address, candyPolygonNftAbi)
}

export const useDuelNftContract = () => {
  return useContract(collections.duels.address, duelNftAbi)
}

export const useTeenDaccNftContract = (address: string) => {
  return useContract(address, teendaccNftAbi)
}

export const useCandyNftStakingContract = (address: string, type: NFT_STAKING_TYPE) => {
  const abi = type === NFT_STAKING_TYPE.FIXED
    ? nftStakingAbi
    : type === NFT_STAKING_TYPE.SHARED
      ? nftStakingV2Abi
      : nftStakingV3Abi
  return useContract(address, abi)
}

export const useCandyNftVaultContract = () => {
  return useContract(getNftVaultAddressMap(), nftVaultAbi)
}

export const useVaultPoolContract = (vaultKey: VaultKey) => {
  const { data: signer } = useWalletClient()
  const { chainId } = useActiveChainId()

  return useMemo(() => {
    if (vaultKey === VaultKey.CakeVault) {
      return getCakeVaultV2Contract(chainId, signer)
    }
    if (vaultKey === VaultKey.CakeFlexibleSideVault) {
      return getCakeFlexibleSideVaultV2Contract(chainId, signer)
    }
    return null
  }, [chainId, signer, vaultKey])
}

export const useCakeVaultContract = () => {
  const { data: signer } = useWalletClient()
  const { chainId } = useActiveChainId()
  return useMemo(() => getCakeVaultV2Contract(chainId, signer), [chainId, signer])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
// returns null on errors
export function useContract<TAbi extends Abi | unknown[]>(
  addressOrAddressMap: string | { [chainId: number]: Address } | undefined,
  abi?: TAbi,
) {
  const { chainId } = useActiveChainId()
  const { data: walletClient } = useWalletClient()

  return useMemo(() => {
    if (!addressOrAddressMap || !abi || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract({
        abi,
        address,
        chainId,
        signer: walletClient,
      })
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, abi, chainId, walletClient])
}

export function useWNativeContract() {
  const { chainId } = useActiveChainId()
  return useContract(chainId ? WNATIVE[chainId]?.address : undefined, WETH_ABI)
}

export function useBytes32TokenContract(tokenAddress?: string) {
  return useContract(tokenAddress, ERC20_BYTES32_ABI)
}

export function usePairContract(pairAddress?: string) {
  return useContract(pairAddress, IPancakePairABI)
}

export function useMulticallContract() {
  const { chainId } = useActiveChainId()
  return useContract(getMulticallAddress(chainId), multiCallAbi)
}

export function useZapContract() {
  const { chainId } = useActiveChainId()
  return useContract(getZapAddress(chainId), zapAbi)
}

export function useReferralContract() {
  return useContract(getReferralAddressMap(), referralAbi)
}

export function useBCakeFarmBoosterContract() {
  return useContract(getBCakeFarmBoosterAddressMap(), bCakeFarmBoosterAbi)
}

export function useBCakeFarmBoosterProxyFactoryContract() {
  return useContract(
    getBCakeFarmBoosterProxyFactoryAddressMap(),
    bCakeFarmBoosterProxyFactoryAbi
  )
}

export function useBCakeProxyContract(proxyContractAddress: string) {
  return useContract(proxyContractAddress, bCakeProxyAbi)
}
