import { Abi, PublicClient, WalletClient, getContract as viemGetContract } from 'viem'
import { Address } from 'wagmi'
import { CANDY } from '@pancakeswap/tokens'
import { ChainId } from '@pancakeswap/sdk'

// Addresses
import {
  getCakeVaultAddress,
  getMulticallAddress,
  getCakeFlexibleSideVaultAddress,
  getLockVaultAddress,
} from 'utils/addressHelpers'
import { viemClients } from 'utils/viem'

// ABI
import cakeAbi from 'config/abi/cake.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import cakeVaultV2Abi from 'config/abi/cakeVaultV2.json'
import cakeFlexibleSideVaultV2Abi from 'config/abi/cakeFlexibleSideVaultV2.json'
import lockvaultAbi from "config/abi/lockvault.json"
import MultiCallAbi from 'config/abi/Multicall.json'

export const getContract = <TAbi extends Abi | unknown[], TWalletClient extends WalletClient>({
  abi,
  address,
  chainId = ChainId.CRONOS,
  publicClient,
  signer,
}: {
  abi: TAbi
  address: string
  chainId?: ChainId
  signer?: TWalletClient
  publicClient?: PublicClient
}) => {
  const c = viemGetContract({
    abi,
    address: (address as Address),
    publicClient: publicClient ?? viemClients[chainId],
    walletClient: signer,
  })
  return {
    ...c,
    account: signer?.account,
    chain: signer?.chain,
  }
}

export const getSouschefV2Contract = (address: string, chainId: number, signer?: WalletClient) => {
  return getContract({ abi: sousChefV2, address, chainId, signer })
}

export const getCakeContract = (chainId: number, signer?: WalletClient) => {
  return getContract({
    abi: cakeAbi,
    address: chainId ? CANDY[chainId].address : CANDY[ChainId.CRONOS].address,
    signer,
    chainId
  })
}

export const getCakeVaultV2Contract = (chainId: number, signer?: WalletClient) => {
  return getContract({ abi: cakeVaultV2Abi, address: getCakeVaultAddress(chainId), chainId, signer })
}

export const getLockVaultContract = (chainId: number, signer?: WalletClient) => {
  return getContract({ abi: lockvaultAbi, address: getLockVaultAddress(chainId), chainId, signer })
}

export const getCakeFlexibleSideVaultV2Contract = (chainId: number, signer?: WalletClient) => {
  return getContract({
    abi: cakeFlexibleSideVaultV2Abi,
    address: getCakeFlexibleSideVaultAddress(chainId),
    chainId,
    signer,
  })
}

export const getMulticallContract = (chainId: ChainId) => {
  return getContract({ abi: MultiCallAbi, address: getMulticallAddress(chainId), chainId })
}
