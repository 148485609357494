import BigNumber from 'bignumber.js'
import chunk from 'lodash/chunk'
import { getPartnerFarms } from 'config/constants/partnerFarms'
import { publicClient } from 'utils/wagmi'
import erc20ABI from 'config/abi/erc20.json'
import partnerFarmV1ABI from 'config/abi/sousChef.json'
import partnerFarmV2ABI from 'config/abi/partnerFarmV2.json'

export const fetchPartnerFarmsBlockLimits = async (chainId: number) => {
  const partnerFarmsConfig = await getPartnerFarms(chainId)
  const v1PartnerFarms = partnerFarmsConfig.filter((partnerFarmConfig) => partnerFarmConfig.version === 1)
  const v2PartnerFarms = partnerFarmsConfig.filter((partnerFarmConfig) => partnerFarmConfig.version === 2)
  const client = publicClient({ chainId })

  const v1BlockCalls = v1PartnerFarms.map((partnerFarmConfig) => ({
    abi: partnerFarmV1ABI,
    address: partnerFarmConfig.contractAddress,
    functionName: 'startBlock',
  })).concat(v1PartnerFarms.map((partnerFarmConfig) => ({
    abi: partnerFarmV1ABI,
    address: partnerFarmConfig.contractAddress,
    functionName: 'bonusEndBlock',
  })))

  const v2BlockCalls = v2PartnerFarms.map((partnerFarmConfig) => ({
    abi: partnerFarmV2ABI,
    address: partnerFarmConfig.contractAddress,
    functionName: 'startBlock',
  })).concat(v2PartnerFarms.map((partnerFarmConfig) => ({
    abi: partnerFarmV2ABI,
    address: partnerFarmConfig.contractAddress,
    functionName: 'endBlock',
  })))

  // @ts-ignore
  const results = await client.multicall({ contracts: v1BlockCalls.concat(v2BlockCalls), allowFailure: false })
  const v1BlockResultRaw = results.slice(0, v1PartnerFarms.length * 2)
  const v2BlockResultRaw = results.slice(v1PartnerFarms.length * 2)

  const [v1StartBlocks, v1EndBlocks] = v1BlockResultRaw.length > 0
    ? chunk(v1BlockResultRaw, v1PartnerFarms.length) : [[], []]
  const [v2StartBlocks, v2EndBlocks] = v2BlockResultRaw.length > 0
    ? chunk(v2BlockResultRaw, v2PartnerFarms.length) : [[], []]

  return v1PartnerFarms.map((partnerFarmConfig, index) => {
    return {
      sousId: partnerFarmConfig.sousId,
      startBlock: Number(v1StartBlocks[index]),
      endBlock: Number(v1EndBlocks[index]),
    }
  }).concat(
    v2PartnerFarms.map((partnerFarmConfig, index) => {
      return {
        sousId: partnerFarmConfig.sousId,
        startBlock: Number(v2StartBlocks[index]),
        endBlock: Number(v2EndBlocks[index]),
      }
    })
  )
}

export const fetchPartnerFarmsTotalStakedToken = async (chainId: number) => {
  const partnerFarmsConfig = await getPartnerFarms(chainId)
  const v1PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 1)
  const v2PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 2)
  const client = publicClient({ chainId })

  const v1Calls = v1PartnerFarms.map((p) => {
    return {
      abi: erc20ABI,
      address: p.stakingToken.address,
      functionName: 'balanceOf',
      args: [p.contractAddress]
    }
  })

  const v2Calls = v2PartnerFarms.map((p) => {
    return {
      abi: partnerFarmV2ABI,
      address: p.contractAddress,
      functionName: 'totalStakedToken'
    }
  })
  // @ts-ignore
  const results = await client.multicall({ contracts: v1Calls.concat(v2Calls), allowFailure: false })

  const v1TotalStaked = results.slice(0, v1PartnerFarms.length)
  const v2TotalStaked = results.slice(v1PartnerFarms.length)

  return v1PartnerFarms.map((p, index) => ({
    sousId: p.sousId,
    totalStakedToken: new BigNumber(v1TotalStaked[index].toString()).toJSON(),
  })).concat(v2PartnerFarms.map((p, index) => ({
    sousId: p.sousId,
    totalStakedToken: new BigNumber(v2TotalStaked[index].toString()).toJSON(),
  })))
}

export const fetchPartnerFarmsTotalBoostedShare = async (chainId: number) => {
  const partnerFarmsConfig = await getPartnerFarms(chainId)
  const v1PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 1)
  const v2PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 2)
  const client = publicClient({ chainId })

  const v1Calls = v1PartnerFarms.map((p) => {
    return {
      abi: erc20ABI,
      address: p.stakingToken.address,
      functionName: 'balanceOf',
      args: [p.contractAddress]
    }
  })

  const v2Calls = v2PartnerFarms.map((p) => {
    return {
      abi: partnerFarmV2ABI,
      address: p.contractAddress,
      functionName: 'totalBoostedShare'
    }
  })

  // @ts-ignore
  const results = await client.multicall({ contracts: v1Calls.concat(v2Calls), allowFailure: false })
  const v1TotalStaked = results.slice(0, v1PartnerFarms.length)
  const v2TotalStaked = results.slice(v1PartnerFarms.length)

  return v1PartnerFarms.map((p, index) => ({
    sousId: p.sousId,
    totalBoostedShare: new BigNumber(v1TotalStaked[index].toString()).toJSON(),
  })).concat(v2PartnerFarms.map((p, index) => ({
    sousId: p.sousId,
    totalBoostedShare: new BigNumber(v2TotalStaked[index].toString()).toJSON(),
  })))
}

export const fetchPartnerFarmsTotalStakedNft = async (chainId: number) => {
  const partnerFarmsConfig = await getPartnerFarms(chainId)
  const v1PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 1)
  const v2PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 2)
  const client = publicClient({ chainId })
  const v2Calls = v2PartnerFarms.map((p) => {
    return {
      abi: partnerFarmV2ABI,
      address: p.contractAddress,
      functionName: 'totalStakedNft'
    }
  })
  // @ts-ignore
  const v2TotalStaked = await client.multicall({ contracts: v2Calls, allowFailure: false })

  return v1PartnerFarms.map((p, index) => ({
    sousId: p.sousId,
    totalStakedNft: 0,
  })).concat(v2PartnerFarms.map((p, index) => ({
    sousId: p.sousId,
    totalStakedNft: Number(v2TotalStaked[index]),
  })))
}

export const fetchPartnerFarmsTokenStakingLimits = async (chainId: number) => {
  const partnerFarmsConfig = await getPartnerFarms(chainId)
  const v1PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 1)
  const v2PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 2)
  const client = publicClient({ chainId })

  const v1Calls = v1PartnerFarms.map((p) => {
    return {
      abi: partnerFarmV1ABI,
      address: p.contractAddress,
      functionName: 'poolLimitPerUser'
    }
  })
  const v2Calls = v2PartnerFarms.map((p) => {
    return {
      abi: partnerFarmV2ABI,
      address: p.contractAddress,
      functionName: 'tokenLimitPerUser'
    }
  })
  // @ts-ignore
  const results = await client.multicall({ contracts: v1Calls.concat(v2Calls), allowFailure: false })
  const v1ResultRaw = results.slice(0, v1PartnerFarms.length)
  const v2ResultRaw = results.slice(v1PartnerFarms.length)

  return v1PartnerFarms.map((p, index) => ({
    sousId: p.sousId,
    tokenStakingLimit: new BigNumber(v1ResultRaw[index].toString()).toJSON(),
  })).concat(
    v2PartnerFarms.map((p, index) => ({
      sousId: p.sousId,
      tokenStakingLimit: new BigNumber(v2ResultRaw[index].toString()).toJSON(),
    }))
  )
}

export const fetchPartnerFarmsNftStakingLimits = async (chainId: number) => {
  const partnerFarmsConfig = await getPartnerFarms(chainId)
  const v1PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 1)
  const v2PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 2)
  const client = publicClient({ chainId })

  const v2Calls = v2PartnerFarms.map((p) => {
    return {
      abi: partnerFarmV2ABI,
      address: p.contractAddress,
      functionName: 'nftLimitPerUser'
    }
  })

  // @ts-ignore
  const v2ResultRaw = await client.multicall({ contracts: v2Calls, allowFailure: false })

  return v1PartnerFarms.map((p, index) => ({
    sousId: p.sousId,
    nftStakingLimit: 0,
  })).concat(
    v2PartnerFarms.map((p, index) => ({
      sousId: p.sousId,
      nftStakingLimit: Number(v2ResultRaw[index])
    }))
  )
}

export const fetchPartnerFarmsFeeConf = async (chainId: number) => {
  const partnerFarmsConfig = await getPartnerFarms(chainId)
  const v2PartnerFarms = partnerFarmsConfig.filter((p) => p.version === 2)
  const client = publicClient({ chainId })

  const v2Calls = v2PartnerFarms.map((p) => ({
    abi: partnerFarmV2ABI,
    address: p.contractAddress,
    functionName: 'depositFee'
  })).concat(v2PartnerFarms.map((p) => ({
    abi: partnerFarmV2ABI,
    address: p.contractAddress,
    functionName: 'boostRate'
  })))
  // @ts-ignore
  const v2ResultRaw = await client.multicall({ contracts: v2Calls, allowFailure: false })
  const [depositFees, boostRates] = v2ResultRaw.length > 0 ? chunk(v2ResultRaw, v2PartnerFarms.length) : [[], []]

  return v2PartnerFarms.map((p, index) => {
    return {
      sousId: p.sousId,
      depositFee: Number(depositFees[index]),
      boostingRate: Number(boostRates[index])
    }
  })
}

