import BigNumber from 'bignumber.js'
import chunk from 'lodash/chunk'
import { Address, erc20ABI } from 'wagmi'
import { getPools } from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import sousChefV2ABI from 'config/abi/sousChefV2.json'
import { publicClient } from 'utils/wagmi'

export const fetchPoolsBlockLimits = async (chainId: number) => {
  const poolsConfig = await getPools(chainId)
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0)
  const client = publicClient({ chainId })

  const calls = poolsWithEnd.map((p) => ({
    address: p.contractAddress,
    abi: sousChefABI,
    functionName: 'startBlock',
  })).concat(poolsWithEnd.map((p) => ({
    address: p.contractAddress,
    abi: sousChefABI,
    functionName: 'bonusEndBlock',
  })))

  const blockResults = await client.multicall({
    // @ts-ignore
    contracts: calls,
    allowFailure: false
  })

  const [startBlocks, endBlocks] = blockResults.length > 0 ? chunk(blockResults, poolsWithEnd.length) : [[], []]

  return poolsWithEnd.map((cakePoolConfig, index) => {
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: Number(startBlocks[index]),
      endBlock: Number(endBlocks[index]),
    }
  })
}

export const fetchPoolsTotalStaking = async (chainId: number) => {
  const poolsConfig = await getPools(chainId)
  const oldPools = poolsConfig.filter((p) => p.sousId === 0)
  const upgradedPools = poolsConfig.filter((p) => p.sousId !== 0)

  const client = publicClient({ chainId })

  const calls = oldPools.map((poolConfig) => {
    return {
      abi: erc20ABI,
      address: poolConfig.stakingToken.address as Address,
      functionName: 'balanceOf',
      args: [poolConfig.contractAddress]
    }
  }).concat(
    // @ts-ignore
    upgradedPools.map((poolConfig) => {
      return {
        abi: sousChefV2ABI,
        address: poolConfig.contractAddress as Address,
        functionName: 'stakedSupply'
      }
    }))

  const results = await client.multicall({
    contracts: calls,
    allowFailure: false
  })

  const oldPoolsTotalStaked = results.slice(0, oldPools.length)
  const upgradedPoolsTotalStaked = results.slice(oldPools.length)

  return oldPools.map((p, index) => ({
    sousId: p.sousId,
    totalStaked: new BigNumber(oldPoolsTotalStaked[index].toString()).toJSON(),
  })).concat(upgradedPools.map((p, index) => ({
    sousId: p.sousId,
    totalStaked: new BigNumber(upgradedPoolsTotalStaked[index].toString()).toJSON(),
  })))
}

export const fetchPoolsFeeConf = async (chainId: number) => {
  const poolsConfig = await getPools(chainId)
  const upgradedPools = poolsConfig.filter((p) => p.sousId !== 0)
  const client = publicClient({ chainId })

  const calls = upgradedPools.map((p) => ({
    abi: sousChefV2ABI,
    address: p.contractAddress as Address,
    functionName: 'depositFee'
  })).concat(upgradedPools.map((p) => ({
    abi: sousChefV2ABI,
    address: p.contractAddress as Address,
    functionName: 'unlockFee'
  })), upgradedPools.map((p) => ({
    abi: sousChefV2ABI,
    address: p.contractAddress as Address,
    functionName: 'lockPeriod'
  })))

  // @ts-ignore
  const results = await client.multicall({ contracts: calls, allowFailure: false })
  const [depositFees, unlockFees, lockPeriods] = results.length > 0
    ? chunk(results, upgradedPools.length) : [[], [], []]

  return upgradedPools.map((poolConfig, index) => {
    return {
      sousId: poolConfig.sousId,
      depositFee: Number(depositFees[index]),
      unlockFee: Number(unlockFees[index]),
      lockPeriod: Number(lockPeriods[index])
    }
  })
}

export const fetchPoolsStakingLimits = async (chainId: number) => {
  const poolsConfig = await getPools(chainId)
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0)
  const client = publicClient({ chainId })
  const poolsLimitResultRaw = await client.multicall({
    // @ts-ignore
    contracts: poolsWithEnd.map((poolConfig) => {
      return {
        address: poolConfig.contractAddress,
        abi: sousChefV2ABI,
        functionName: 'poolLimitPerUser'
      }
    }),
    allowFailure: true
  })

  return poolsWithEnd.map((p, index) => ({
    sousId: p.sousId,
    stakingLimit: new BigNumber(poolsLimitResultRaw[index].toString()).toJSON(),
  }))

  // const validPools = poolsConfig
  //   .filter((p) => p.stakingToken.symbol !== NATIVE[chainId].symbol && !p.isFinished)
  //   .filter((p) => !poolsWithStakingLimit.includes(p.sousId))

  // // Get the staking limit for each valid pool
  // const poolStakingCalls = validPools
  //   .map((validPool) => {
  //     return ['poolLimitPerUser'].map((method) => ({
  //       address: validPool.contractAddress,
  //       name: method,
  //     }))
  //   })
  //   .flat()

  // const poolStakingResultRaw = await multicall v2({
  //   abi: sousChefV2ABI,
  //   calls: poolStakingCalls,
  //   chainId,
  //   options: { requireSuccess: false },
  // })
  // const chunkSize = poolStakingCalls.length / validPools.length
  // const poolStakingChunkedResultRaw = chunk(poolStakingResultRaw.flat(), chunkSize)
  // return fromPairs(
  //   poolStakingChunkedResultRaw.map((stakingLimitRaw, index) => {
  //     const hasUserLimit = stakingLimitRaw[0]
  //     const stakingLimit = hasUserLimit && stakingLimitRaw[1] ? new BigNumber(stakingLimitRaw[1].toString()) : BIG_ZERO
  //     const numberBlocksForUserLimit = stakingLimitRaw[2] ? (stakingLimitRaw[2] as EthersBigNumber).toNumber() : 0
  //     return [validPools[index].sousId, { stakingLimit, numberBlocksForUserLimit }]
  //   }),
  // )
}
