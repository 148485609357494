import { ChainId } from '@pancakeswap/sdk'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getFarmConfig } from 'config/constants/farms'
import { NftToken, Rarity } from 'config/constants/types'
import fetchOwnedNfts from 'state/nftholds/fetchOwnedNfts'
import { NftVaultState, NftVaultProps } from '../types'
import fetchNftVaultsPublicData, { fetchMaxPerPool, fetchRarityBoosts } from './fetchNftVaultsPublicData'
import { fetchNftVaultApproved, fetchUserStakedNftsAndBoosts } from './fetchNftVaultsUserData'
import { NFT_VAULT_COLLECTION } from './constants'

const initialState: NftVaultState = {
  nft: NFT_VAULT_COLLECTION,
  maxPerPool: 0,
  rarityBoosts: {
    [Rarity.GOD]: 0,
    [Rarity.KING]: 0,
    [Rarity.PRINCE]: 0,
    [Rarity.MINISTER]: 0,
    [Rarity.CITIZEN]: 0
  },
  vaults: [],
  userDataLoaded: false
}

export const fetchInitialNftVaultsData = createAsyncThunk<
  { rarityBoosts: { [rarity in Rarity]: number }; maxPerPool: number; vaults: NftVaultProps[] },
  { chainId: number }
>(
  'nftVaults/fetchInitialNftVaultsData',
  // @ts-ignore
  async ({ chainId }) => {
    const farmDataList = await getFarmConfig(chainId)
    const boostedFarmPids = farmDataList.filter((farm) => farm.boosted).map((farm) => farm.pid)
    const rarityBoosts = await fetchRarityBoosts(chainId)
    const maxPerPool = await fetchMaxPerPool(chainId)
    return {
      rarityBoosts,
      maxPerPool,
      vaults: boostedFarmPids.map((pid) => ({
        farmPid: pid,
        supplies: {
          [Rarity.GOD]: 0,
          [Rarity.KING]: 0,
          [Rarity.PRINCE]: 0,
          [Rarity.MINISTER]: 0,
          [Rarity.CITIZEN]: 0
        }
      }))
    }
  },
)

export const fetchNftVaultsPublicDataAsync = createAsyncThunk<
  { id: number; supplies: { [rarity in Rarity]: number } }[],
  { farmPids: number[]; chainId: number }
>(
  'nftVaults/fetchNftVaultsPublicDataAsync',
  async ({ farmPids, chainId }) => {
    const vaultsSupplyData = await fetchNftVaultsPublicData(chainId, farmPids)
    return farmPids.map((farmId, index) => ({
      id: farmId,
      supplies: vaultsSupplyData[index]
    }))
  },
)

interface VaultsUserDataResponse {
  id: number
  approved: boolean
  stakedTokens: NftToken[]
  ownedTokens: NftToken[]
  boosts: number
}

export const fetchNftVaultsUserDataAsync = createAsyncThunk<
  VaultsUserDataResponse[],
  { account: string; chainId: ChainId; farmPids: number[] }
>('nftVaults/fetchNftVaultsUserDataAsync',
  // @ts-ignore
  async ({ account, chainId, farmPids }) => {

    if (account) {
      const fixedStakedNftsAndBoosts = await fetchUserStakedNftsAndBoosts(account, farmPids, chainId)
      const ownedNfts = await fetchOwnedNfts(account, [NFT_VAULT_COLLECTION])
      const approved = await fetchNftVaultApproved(account, NFT_VAULT_COLLECTION.address, chainId)

      return fixedStakedNftsAndBoosts.map((data, index) => ({
        id: farmPids[index],
        ...data,
        ownedTokens: ownedNfts[0].tokens,
        approved
      })
      )
    }
    return farmPids.map((farmId) => {
      return {
        id: farmId,
        stakedTokens: [],
        ownedTokens: [],
        boosts: 0,
        approved: false
      }
    })
  })

export const nftVaultsSlice = createSlice({
  name: 'NftVaults',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInitialNftVaultsData.fulfilled, (state, action) => {
      state.maxPerPool = action.payload.maxPerPool
      state.rarityBoosts = action.payload.rarityBoosts
      state.vaults = action.payload.vaults
    })

    builder.addCase(fetchNftVaultsPublicDataAsync.fulfilled, (state, action) => {
      state.vaults = state.vaults.map((vault) => {
        const fetchedData = action.payload.find((dt) => dt.id === vault.farmPid)
        return { ...vault, ...fetchedData }
      })
    })

    builder.addCase(fetchNftVaultsUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { id } = userDataEl
        const index = state.vaults.findIndex((dt) => dt.farmPid === id)
        state.vaults[index] = { ...state.vaults[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  },
})

export default nftVaultsSlice.reducer
