/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { Chain, ConnectorNotFoundError } from 'wagmi'
import {
  ResourceUnavailableRpcError,
  RpcError,
  UserRejectedRequestError
} from 'viem'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { DeFiConnectProvider } from '@deficonnect/provider'
import { NetworkConfig } from '@deficonnect/types'
import { getAddress } from '@ethersproject/address'

declare global {
  interface Window {
    deficonnectProvider?: any
  }
}

export class DefiWalletConnector extends InjectedConnector {
  readonly id = 'defiWallet'
  readonly name = 'Defi Wallet'

  readonly ready = typeof window != 'undefined' && !!window.deficonnectProvider

  provider?: Window['deficonnectProvider']

  constructor({ chains, networkConfig }: { chains?: Chain[]; networkConfig: NetworkConfig }) {
    const options = {
      name: 'Defi Wallet',
      shimDisconnect: false,
      shimChainChangedDisconnect: false,
    }
    super({
      chains,
      options,
    })

    if (typeof window !== 'undefined')
      this.provider = new DeFiConnectProvider(networkConfig)
  }

  async connect({ chainId }: { chainId?: number } = {}) {
    try {
      const provider = await this.getProvider()
      if (!provider) throw new ConnectorNotFoundError()

      if (provider.on) {
        provider.on('accountsChanged', this.onAccountsChanged)
        provider.on('chainChanged', this.onChainChanged)
        provider.on('disconnect', this.onDisconnect)
      }

      this.emit('message', { type: 'connecting' })

      const account = await this.getAccount()
      // Switch to chain if provided
      let id = await this.getChainId()
      let unsupported = this.isChainUnsupported(id)
      if (chainId && id !== chainId) {
        const chain = await this.switchChain(chainId)
        id = chain.id
        unsupported = this.isChainUnsupported(id)
      }

      return { account, chain: { id, unsupported }, provider }
    } catch (error) {
      // @ts-ignore
      if (this.isUserRejectedRequestError(error)) throw new UserRejectedRequestError(error)
      // @ts-ignore
      if ((<RpcError>error).code === -32002) throw new ResourceUnavailableRpcError(error)
      throw error
    }
  }

  async disconnect() {
    const provider = await this.getProvider()
    if (!provider?.removeListener) return

    provider.removeListener('accountsChanged', this.onAccountsChanged)
    provider.removeListener('chainChanged', this.onChainChanged)
    provider.removeListener('disconnect', this.onDisconnect)
  }

  async getAccount() {
    const provider = await this.getProvider()
    if (!provider) throw new ConnectorNotFoundError()
    const accounts = await provider.request({
      method: 'eth_requestAccounts',
    })
    // return checksum address
    return getAddress(<string>accounts[0]) as `0x${string}`
  }

  async getProvider() {
    if (typeof window !== 'undefined' && window.deficonnectProvider) {
      // TODO: Fallback to `ethereum#initialized` event for async injection
      // https://github.com/MetaMask/detect-provider#synchronous-and-asynchronous-injection=
      this.provider = window.deficonnectProvider
    }
    return this.provider
  }
}
