import { cronosTokens, cronosTestnetTokens, serializeTokens } from '@pancakeswap/tokens'
import { ChainId } from '@pancakeswap/sdk'
import collections from './collections'
import { NFT_STAKING_TYPE, SerializedNftStakingConfig } from '../types'

const serializedCronosTokens = serializeTokens(cronosTokens)
const serializedCronosTestnetTokens = serializeTokens(cronosTestnetTokens)

const nftStakings: SerializedNftStakingConfig[] = [
  // {
  //   id: 0,
  //   chainId: ChainId.CRONOS_TESTNET,
  //   collection: collections.bcctest,
  //   earningToken: serializedCronosTestnetTokens.candy,
  //   address: '0xa3ef6002aC6Dc3069bE11671DbA5699eCc84330e'
  // },
  {
    id: 1,
    chainId: ChainId.CRONOS,
    collection: collections.bcc,
    earningToken: serializedCronosTokens.candy,
    address: '0xA46C4a3428a5E9B5C84A4457215D98BC8DC17AbB',
    type: NFT_STAKING_TYPE.FIXED
  },
  {
    id: 2,
    chainId: ChainId.CRONOS,
    collection: collections.bcc,
    earningToken: serializedCronosTokens.candy,
    address: '0xCa207941946218126BD7BBe44C5d457753490b4A',
    type: NFT_STAKING_TYPE.SHARED
  },
  {
    id: 3,
    chainId: ChainId.CRONOS,
    collection: collections.rbl,
    earningToken: serializedCronosTokens.warz,
    address: '0x0d15BF6010e0Bf4406c0c14F567801DCdD2D3BFc',
    type: NFT_STAKING_TYPE.PARTNER,
    finished: true
  },
  {
    id: 4,
    chainId: ChainId.CRONOS,
    collection: collections.croladiesvip,
    earningToken: serializedCronosTokens.wcro,
    address: '0x306500486549c8bBa28dd2cC3F789E5d431e25Dc',
    type: NFT_STAKING_TYPE.PARTNER,
    finished: true
  },
  {
    id: 5,
    chainId: ChainId.CRONOS,
    collection: collections.croladiesvip,
    earningToken: serializedCronosTokens.wcro,
    address: '0x0B55454D6cAf053E78a274FB79E14E071D20Ce73',
    type: NFT_STAKING_TYPE.PARTNER,
    finished: true
  },
  {
    id: 6,
    chainId: ChainId.CRONOS,
    collection: collections.tdog,
    earningToken: serializedCronosTokens.candy,
    address: '0x888b74A7f9C253FA6daD4f46ECafF49e2C9B524b',
    type: NFT_STAKING_TYPE.PARTNER,
    finished: true
  },
  {
    id: 7,
    chainId: ChainId.CRONOS,
    collection: collections.rbl,
    earningToken: serializedCronosTokens.candy,
    address: '0x1B32eEf640C496b001223C3a99e2bdfAacb8C829',
    type: NFT_STAKING_TYPE.PARTNER,
    finished: true
  },
  {
    id: 8,
    chainId: ChainId.CRONOS,
    collection: collections.scpepe,
    earningToken: serializedCronosTokens.wcro,
    address: '0x2b28b2fBb595f6Ec770a4C4Fef96B016EFBEE202',
    type: NFT_STAKING_TYPE.PARTNER,
    finished: true
  },
  {
    id: 9,
    chainId: ChainId.CRONOS,
    collection: collections.wapz,
    earningToken: serializedCronosTokens.candy,
    address: '0xC53D5E600E044616bCaDca3ef61C25532bF8D8f7',
    type: NFT_STAKING_TYPE.PARTNER
  },
  {
    id: 10,
    chainId: ChainId.CRONOS,
    collection: collections.cropepe,
    earningToken: serializedCronosTokens.star,
    address: '0xb962f37fA4d80d123d6220b9Db2d680d90b5cbA5',
    type: NFT_STAKING_TYPE.PARTNER
  },
  {
    id: 11,
    chainId: ChainId.CRONOS,
    collection: collections.crodoge,
    earningToken: serializedCronosTokens.star,
    address: '0xE70b3cCC52A405425de3BaB2Ae1F6577AbdAfa43',
    type: NFT_STAKING_TYPE.PARTNER
  },
  {
    id: 12,
    chainId: ChainId.CRONOS,
    collection: collections.duels,
    earningToken: serializedCronosTokens.candy,
    address: '0x24340d59Eed96246E130A6f7feda508304B14CAe',
    type: NFT_STAKING_TYPE.FIXED
  },
]

export default nftStakings
