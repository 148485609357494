import masterchefABI from 'config/abi/masterchef.json'
import chunk from 'lodash/chunk'
import { publicClient } from 'utils/wagmi'
import { BIG_ZERO } from 'utils/bigNumber'
import { verifyCronosNetwork } from 'utils/verifyNetwork'
import { SerializedFarmConfig } from '../../config/constants/types'
import { SerializedFarm } from '../types'
import { getMasterChefAddress } from '../../utils/addressHelpers'

export const fetchMasterChefFarmPoolLength = async (chainId: number) => {
  try {
    const client = publicClient({ chainId })
    const poolLength = await client.readContract({
      abi: masterchefABI,
      address: getMasterChefAddress(chainId),
      functionName: 'poolLength',
    })
    return Number(poolLength)
  } catch (error) {
    console.error('Fetch MasterChef Farm Pool Length Error: ', error)
    return BIG_ZERO.toNumber()
  }
}

const masterChefFarmCalls = async (farm: SerializedFarm) => {
  const { pid, bscPid, quoteToken } = farm
  const isBscNetwork = verifyCronosNetwork(quoteToken.chainId)
  // const multiCallChainId = isBscNetwork ? quoteToken.chainId : await getBscChainId(quoteToken.chainId)
  const multiCallChainId = quoteToken.chainId
  const masterChefAddress = getMasterChefAddress(multiCallChainId)
  const masterChefPid = isBscNetwork ? pid : bscPid

  return masterChefPid || masterChefPid === 0
    ? [
      {
        abi: masterchefABI,
        address: masterChefAddress,
        functionName: 'poolInfo',
        args: [masterChefPid],
      },
      {
        abi: masterchefABI,
        address: masterChefAddress,
        functionName: 'totalRegularAllocPoint',
      },
    ]
    : [null, null]
}

export const fetchMasterChefData = async (farms: SerializedFarmConfig[], chainId: number): Promise<any[]> => {
  const masterChefCalls = await Promise.all(farms.map((farm) => masterChefFarmCalls(farm)))
  const chunkSize = masterChefCalls.flat().length / farms.length
  const masterChefAggregatedCalls = masterChefCalls
    .filter((masterChefCall) => masterChefCall[0] !== null && masterChefCall[1] !== null)
    .flat()

  const isBscNetwork = verifyCronosNetwork(chainId)
  // const multiCallChainId = isBscNetwork ? chainId : await getBscChainId(chainId)
  const multiCallChainId = chainId
  const client = publicClient({ chainId })
  const masterChefMultiCallResult = await client.multicall({
    // @ts-ignore
    contracts: masterChefAggregatedCalls,
    allowFailure: false
  })
  const masterChefChunkedResultRaw = chunk(masterChefMultiCallResult, chunkSize)

  let masterChefChunkedResultCounter = 0
  return masterChefCalls.map((masterChefCall) => {
    if (masterChefCall[0] === null && masterChefCall[1] === null) {
      return [null, null]
    }
    const data = masterChefChunkedResultRaw[masterChefChunkedResultCounter]
    masterChefChunkedResultCounter++
    return data
  })
}

export const fetchCandyStakedInMasterChef = async (chainId: number): Promise<any> => {
  const masterChefAddress = getMasterChefAddress(chainId)
  const client = publicClient({ chainId })
  const result = await client.readContract({
    abi: masterchefABI,
    address: masterChefAddress,
    functionName: 'totalCandyStaked'
  })
  
  return result
}
