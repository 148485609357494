import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import fromPairs from 'lodash/fromPairs'
import { Address, erc20ABI, erc721ABI } from 'wagmi'
import { getPartnerFarms } from 'config/constants/partnerFarms'
import partnerFarmV1ABI from 'config/abi/sousChef.json'
import partnerFarmV2ABI from 'config/abi/partnerFarmV2.json'
import erc721EnumerableABI from 'config/abi/erc721collection.json'
import erc721MABI from 'config/abi/erc721M.json'
import { getNftImageUrlFromEbisusBay } from 'utils/getNftImageUrl'
import { NftCollection, NftToken } from 'config/constants/types'
import collections from 'config/constants/nfts/collections'
import { publicClient } from 'utils/wagmi'

export const fetchTokenAllowances = async (account, ids, chainId) => {
  let partnerFarms = await getPartnerFarms(chainId)
  partnerFarms = ids.length > 0
    ? partnerFarms.filter((p) => ids.indexOf(p.sousId) > -1) : partnerFarms

  const client = publicClient({ chainId })
  const allowances = await client.multicall({
    contracts: partnerFarms.map((p) => ({
      abi: erc20ABI,
      address: p.stakingToken.address as Address,
      functionName: 'allowance',
      args: [account, p.contractAddress],
    })),
    allowFailure: false
  })

  return fromPairs(partnerFarms.map((p, index) =>
    [p.sousId, new BigNumber(allowances[index].toString()).toJSON()]))
}

export const fetchNftAllowances = async (account, ids, chainId) => {
  let partnerFarms = await getPartnerFarms(chainId)
  partnerFarms = ids.length > 0
    ? partnerFarms.filter((p) => ids.indexOf(p.sousId) > -1) : partnerFarms
  const v2PartnerFarms = partnerFarms.filter((p) => p.version === 2)
  const client = publicClient({ chainId })
  const allowances = await client.multicall({
    contracts: v2PartnerFarms.map((p) => ({
      abi: erc721ABI,
      address: p.nft.address as Address,
      functionName: 'isApprovedForAll',
      args: [account, p.contractAddress],
    })),
    allowFailure: false
  })

  return fromPairs(v2PartnerFarms.map((pool, index) => [pool.sousId, allowances[index]]))
}

export const fetchHoldingTokens = async (account, ids, chainId) => {
  let partnerFarms = await getPartnerFarms(chainId)
  partnerFarms = ids.length > 0
    ? partnerFarms.filter((p) => ids.indexOf(p.sousId) > -1) : partnerFarms

  const tokens = uniq(partnerFarms.map((pool) => pool.stakingToken.address))
  const client = publicClient({ chainId })
  const tokenBalancesRaw = await client.multicall({
    contracts: tokens.map((token) => ({
      abi: erc20ABI,
      address: token as Address,
      functionName: 'balanceOf',
      args: [account],
    })),
    allowFailure: false
  })
  const tokenBalances = fromPairs(tokens.map((token, index) => [token, tokenBalancesRaw[index]]))

  const balances = fromPairs(
    partnerFarms
      .map((pool) => {
        if (!tokenBalances[pool.stakingToken.address]) return null
        return [pool.sousId, new BigNumber(tokenBalances[pool.stakingToken.address].toString()).toJSON()]
      })
      .filter(Boolean),
  )

  return balances
}

export const fetchStakedTokens = async (account, ids, chainId) => {
  let partnerFarms = await getPartnerFarms(chainId)
  partnerFarms = ids.length > 0
    ? partnerFarms.filter((p) => ids.indexOf(p.sousId) > -1) : partnerFarms
  const v1PartnerFarms = partnerFarms.filter((p) => p.version === 1)
  const v2PartnerFarms = partnerFarms.filter((p) => p.version === 2)

  const client = publicClient({ chainId })
  const callResults = await client.multicall({
    // @ts-ignore
    contracts:
      v1PartnerFarms.map((p) => ({
        abi: partnerFarmV1ABI,
        address: p.contractAddress,
        functionName: 'userInfo',
        args: [account],
      })).concat(
        // @ts-ignore
        v2PartnerFarms.map((p) => ({
          abi: partnerFarmV2ABI,
          address: p.contractAddress,
          functionName: 'viewUserInfo',
          args: [account],
        }))
      ),
    allowFailure: false
  })

  return fromPairs(
    v1PartnerFarms.map((p, index) => [p.sousId, new BigNumber(callResults[index][0].toString()).toJSON()
    ]).concat(
      v2PartnerFarms.map((p, index) =>
        [p.sousId, new BigNumber(callResults[v1PartnerFarms.length + index][0].toString()).toJSON()
        ])),
  )
}

export const fetchStakedNfts = async (account, ids, chainId) => {
  let partnerFarms = await getPartnerFarms(chainId)
  partnerFarms = ids.length > 0
    ? partnerFarms.filter((p) => ids.indexOf(p.sousId) > -1) : partnerFarms
  const v1PartnerFarms = partnerFarms.filter((p) => p.version === 1)
  const v2PartnerFarms = partnerFarms.filter((p) => p.version === 2)
  const client = publicClient({ chainId })
  const rawStakedNfts = await client.multicall({
    // @ts-ignore
    contracts: v2PartnerFarms.map((p) => ({
      abi: partnerFarmV2ABI,
      address: p.contractAddress,
      functionName: 'viewUserNfts',
      args: [account, 0, 100 /** we assume that max nft staking limit is 100 */]
    })),
    allowFailure: false
  })

  const stakedNftsData = await Promise.all(rawStakedNfts.map((nfts, index) =>
    new Promise((resolve) => {
      // @ts-ignore
      Promise.all(nfts.map((nft) => new Promise((resolvee) => {
        const tokenId = Number(nft)
        getNftImageUrlFromEbisusBay(v2PartnerFarms[index].nft.address, tokenId).then((image) => {
          resolvee({ id: tokenId, rarity: 0, image, proof: [] })
        })
      }))).then((data) => {
        resolve(data)
      })
    })))

  return fromPairs(
    v2PartnerFarms.map((p, index) => [p.sousId, stakedNftsData[index]])
      .concat((v1PartnerFarms.map((p, index) => [p.sousId, []])))
  )
}

const fetchSingleNftData = async (account: string, dataToFetch: NftCollection)
  : Promise<NftToken[]> => {
    
  const { address, chainId, imgExt } = dataToFetch
  const client = publicClient({ chainId })
  let tokenIds = []

  // Banshees nft on the polygon chain did not implement ERC721Enumerable interface
  if (chainId === 137 && address === collections.banshees.address) {
    // @ts-ignore
    tokenIds = await client.readContract({
      abi: erc721MABI,
      address: address as Address,
      functionName: 'tokensOfOwner',
      args: [account as Address]
    })
  } else {
    const nftBalance = await client.readContract({
      abi: erc721ABI,
      address: address as Address,
      functionName: 'balanceOf',
      args: [account as Address]
    })
  
    const userBalance = Number(nftBalance)
  
    tokenIds = await client.multicall({
      // @ts-ignore
      contracts: new Array<number>(userBalance).fill(0).map((value, index) => ({
        abi: erc721EnumerableABI,
        address,
        functionName: 'tokenOfOwnerByIndex',
        args: [account, index.toString()]
      })),
      allowFailure: false
    })
  }
  
  const tokens = await Promise.all(tokenIds.map((value) => new Promise((resolve) => {
    const tokenId = Number(value)
    getNftImageUrlFromEbisusBay(address, tokenId).then((image) => {
      resolve({ id: tokenId, rarity: 0, image, proof: [] })
    })
  })))

  // @ts-ignore
  return tokens
}

export const fetchHoldingNfts = async (account, ids, chainId) => {
  let partnerFarms = await getPartnerFarms(chainId)
  partnerFarms = ids.length > 0
    ? partnerFarms.filter((p) => ids.indexOf(p.sousId) > -1) : partnerFarms
  const v2PartnerFarms = partnerFarms.filter((p) => p.version === 2)

  const nftsHoldData = await Promise.all(
    v2PartnerFarms.map(async (p) => {
      const singleData = await fetchSingleNftData(account, p.nft)
      return singleData
    }),
  )

  return fromPairs(v2PartnerFarms.map((p, index) => [p.sousId, nftsHoldData[index]]))
}


export const fetchPendingRewards = async (account, ids, chainId) => {
  let partnerFarms = await getPartnerFarms(chainId)
  partnerFarms = ids.length > 0
    ? partnerFarms.filter((p) => ids.indexOf(p.sousId) > -1) : partnerFarms

  const client = publicClient({ chainId })
  const rewards = await client.multicall({
    // @ts-ignore
    contracts: partnerFarms.map((p) => ({
      abi: partnerFarmV1ABI,
      address: p.contractAddress,
      functionName: 'pendingReward',
      args: [account],
    })),
    allowFailure: false
  })

  return fromPairs(partnerFarms.map((p, index) =>
    [p.sousId, new BigNumber(rewards[index].toString()).toJSON()]))
}
