import { ChainId } from '@pancakeswap/sdk'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import nftStakingsConfig from 'config/constants/nfts/nftStakings'
import { NftToken, NFT_STAKING_TYPE, Rarity } from 'config/constants/types'
import fetchOwnedNfts from 'state/nftholds/fetchOwnedNfts'
import { NftStakingState, SerializedNftStakingProps } from '../types'
import fetchNftStakingsPublicData from './fetchNftStakingsPublicData'
import { fetchNftStakingsApproved, fetchNftStakingsRewards, fetchStakedNfts } from './fetchNftStakingsUserData'

const initialState: NftStakingState = {
  data: nftStakingsConfig.map((staking) => ({
    ...staking,
    supplies: {
      [Rarity.GOD]: 0,
      [Rarity.KING]: 0,
      [Rarity.PRINCE]: 0,
      [Rarity.MINISTER]: 0,
      [Rarity.CITIZEN]: 0
    },
    emissions: {
      [Rarity.GOD]: '0',
      [Rarity.KING]: '0',
      [Rarity.PRINCE]: '0',
      [Rarity.MINISTER]: '0',
      [Rarity.CITIZEN]: '0'
    },
    weights: {
      [Rarity.GOD]: 0,
      [Rarity.KING]: 0,
      [Rarity.PRINCE]: 0,
      [Rarity.MINISTER]: 0,
      [Rarity.CITIZEN]: 0
    },
    supply: 0,
    rewardPerDay: '0'
  })), userDataLoaded: false
}

export const fetchNftStakingsPublicDataAsync = createAsyncThunk<SerializedNftStakingProps[], number[]>(
  'nftStakings/fetchNftStakingsPublicDataAsync',
  async (ids) => {
    const dataToFetch = nftStakingsConfig.filter((config) => ids.includes(config.id))
    const dataFetched = await fetchNftStakingsPublicData(dataToFetch)

    return dataFetched
  },
)

interface StakingsUserDataResponse {
  id: number
  approved: boolean
  stakedTokens: NftToken[]
  ownedTokens: NftToken[]
  rewards: SerializedBigNumber
}

export const fetchNftStakingsUserDataAsync = createAsyncThunk<
  StakingsUserDataResponse[],
  { account: string; chainId: ChainId; type: NFT_STAKING_TYPE; ids: number[] }
>('nftMints/fetchNftStakingsUserDataAsync',
  // @ts-ignore
  async ({ account, chainId, type, ids }) => {
    const dataToFetch = nftStakingsConfig
      .filter((config) => ids.includes(config.id) && config.type === type)
      .sort((a, b) => a.id - b.id)
      
    if (account) {
      const stakedNfts = await fetchStakedNfts(account, dataToFetch, type, chainId)
      const rewardsData = await fetchNftStakingsRewards(account, dataToFetch, type, chainId)
      const ownedNfts = await fetchOwnedNfts(account, dataToFetch.map((item) => item.collection))
      const approvedData = await fetchNftStakingsApproved(account, dataToFetch, chainId)

      return stakedNfts.map((data, index) => {
        return {
          id: dataToFetch[index].id,
          stakedTokens: data,
          ownedTokens: ownedNfts[index].tokens,
          rewards: rewardsData[index],
          approved: approvedData[index]
        }
      })
    }
    return dataToFetch.map((data, index) => {
      return {
        id: dataToFetch[index].id,
        stakedTokens: [],
        ownedTokens: [],
        rewards: '0',
        approved: false
      }
    })
  })

export const fetchNftStakingsUserRewardsDataAsync = createAsyncThunk<
  { id: number; rewards: SerializedBigNumber }[],
  { account: string; chainId: ChainId; ids: number[] }
>('nftMints/fetchNftStakingsUserRewardsDataAsync', async ({ account, chainId, ids }) => {
  const dataToFetch = nftStakingsConfig.filter((config) => ids.includes(config.id))
  const fixedStakingData = dataToFetch.filter((item) => item.type === NFT_STAKING_TYPE.FIXED)
  const sharedStakingData = dataToFetch.filter((item) => item.type !== NFT_STAKING_TYPE.FIXED)
  const fixedRewardsData = await fetchNftStakingsRewards(account, fixedStakingData, NFT_STAKING_TYPE.FIXED, chainId)
  const sharedRewardsData = await fetchNftStakingsRewards(account, sharedStakingData, NFT_STAKING_TYPE.SHARED, chainId)

  return fixedRewardsData.map((rewards, index) => {
    return { id: fixedStakingData[index].id, rewards }
  }).concat(sharedRewardsData.map((rewards, index) => {
    return { id: sharedStakingData[index].id, rewards }
  }))
})

export const fetchNftStakingsUserApproveDataAsync = createAsyncThunk<
  { id: number; approved: boolean }[],
  { account: string; chainId: ChainId; ids: number[] }
>('nftMints/fetchNftStakingsUserApproveDataAsync',
  // @ts-ignore
  async ({ account, chainId, ids }) => {
    const dataToFetch = nftStakingsConfig.filter((config) => ids.includes(config.id))
    const approvedData = await fetchNftStakingsApproved(account, dataToFetch, chainId)

    return approvedData.map((approved, index) => {
      return { id: dataToFetch[index].id, approved }
    })
  })

export const nftStakingsSlice = createSlice({
  name: 'NftStakings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update collections with live data
    builder.addCase(fetchNftStakingsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((stateData) => {
        const fetchedData = action.payload.find((dt) => dt.id === stateData.id)
        return { ...stateData, ...fetchedData }
      })
    })

    // Update nft stakings with user data
    builder.addCase(fetchNftStakingsUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { id } = userDataEl
        const index = state.data.findIndex((dt) => dt.id === id)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
    // Update nft stakings with user approval data
    builder.addCase(fetchNftStakingsUserApproveDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { id, approved } = userDataEl
        const index = state.data.findIndex((dt) => dt.id === id)
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, approved } }
      })
      state.userDataLoaded = true
    })
    // Update nft stakings with user rewards data
    builder.addCase(fetchNftStakingsUserRewardsDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { id, rewards } = userDataEl
        const index = state.data.findIndex((dt) => dt.id === id)
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, rewards } }
      })
      state.userDataLoaded = true
    })
  },
})

export default nftStakingsSlice.reducer
