import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { PUBLIC_NODES } from 'config/nodes'
import { cronos, polygon } from 'wagmi/chains'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://bsc.nodereal.io'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)

export const cronosTestnetRpcProvider = new StaticJsonRpcProvider("https://evm-t3.cronos.org")

// export const cronosRpcProvider = new StaticJsonRpcProvider(cronos.rpcUrls.default.http[0])
export const cronosRpcProvider = new StaticJsonRpcProvider(PUBLIC_NODES[cronos.id][0])

export const polygonRpcProvider = new StaticJsonRpcProvider(polygon.rpcUrls.default.http[0])

export default null
