import { BigNumber } from '@ethersproject/bignumber'
import { ChainId } from '@pancakeswap/sdk'
import {  SerializedPartnerFarmConfig } from '../types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('50000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const getLivePartnerFarms = async (chainId: ChainId) => {
  try {
    return (await import(`/${chainId}.tsx`)).livePartnerFarms as SerializedPartnerFarmConfig[]
  } catch (error) {
    console.error('Cannot get live partner farms', error, chainId)
    return []
  }
}

export const getPartnerFarms = async (chainId: ChainId) => {
  try {
    
    return (await import(`/${chainId}.tsx`)).default as SerializedPartnerFarmConfig[]
  } catch (error) {
    console.error('Cannot get partner farms', error, chainId)
    return []
  }
}
