import { DefiWalletConnector } from '@pancakeswap/wagmi/connectors/defiWallet'
import { configureChains, createConfig, createStorage } from 'wagmi'
import { polygon, cronos, cronosTestnet } from 'wagmi/chains'
import memoize from 'lodash/memoize'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
// import { SafeConnector } from '@gnosis.pm/safe-apps-wagmi'
import { PUBLIC_NODES } from 'config/nodes'

const CHAINS = [
  polygon,
  cronos,
  cronosTestnet,
]

// get most configs chain nodes length
const mostNodesConfig = Object.values(PUBLIC_NODES).reduce((prev, cur) => {
  return cur.length > prev ? cur.length : prev
}, 0)

export const { publicClient, chains } = configureChains(
  CHAINS,
  Array.from({ length: mostNodesConfig })
    .map((_, i) => i)
    .map((i) => {
      return jsonRpcProvider({
        rpc: (chain) => {
          // if (process.env.NODE_ENV === 'test' && chain.id === mainnet.id && i === 0) {
          //   return { http: 'https://cloudflare-eth.com' }
          // }
          return PUBLIC_NODES[chain.id]?.[i]
            ? {
              http: PUBLIC_NODES[chain.id][i],
            }
            : null
        },
      })
    }),
  {
    batch: {
      multicall: {
        batchSize: 1024 * 200,
      },
    },
  },
)

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) =>
  (CHAIN_IDS as number[]).includes(chainId))
export const isChainTestnet = memoize((chainId: number) => {
  const found = chains.find((c) => c.id === chainId)
  return found ? 'testnet' in found : false
})

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'Candy Finance',
    appLogoUrl: 'https://candycity.finance/logo.png',
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    showQrModal: true,
    projectId: 'a5a62405826060eb2b12116b40fcc570'
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false
  },
})

export const defiWalletConnector = new DefiWalletConnector({
  chains,
  networkConfig: {
    appName: 'Candy Finance',
    chainType: 'eth', // only support 'eth' for DeFiWeb3Connector
    chainId: '25', // for eth is 1
    rpcUrls: {
      // 1: 'https://mainnet.infura.io/v3/INFURA_API_KEY',
      // 25: 'https://evm-cronos.crypto.org/',
      25: 'https://evm.cronos.org',
      338: 'https://evm-t3.cronos.org',
    },
  },
})

export const noopStorage = {
  getItem: (_key) => '',
  setItem: (_key, _value) => null,
  removeItem: (_key) => null,
}

export const wagmiConfig = createConfig({
  storage: createStorage({
    storage: typeof window !== 'undefined' ? window.localStorage : noopStorage,
    key: 'wagmi_v1.1',
  }),
  autoConnect: false,
  publicClient,
  connectors: [
    // new SafeConnector({ chains }),
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    defiWalletConnector,
  ],
})
