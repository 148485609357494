export var ConnectorNames;
(function(ConnectorNames) {
    ConnectorNames["MetaMask"] = "metaMask";
    ConnectorNames["Injected"] = "injected";
    ConnectorNames["WalletConnect"] = "walletConnect";
    ConnectorNames["DefiWallet"] = "defiWallet";
    ConnectorNames["BSC"] = "bsc";
    ConnectorNames["Blocto"] = "blocto";
    ConnectorNames["WalletLink"] = "coinbaseWallet";
})(ConnectorNames || (ConnectorNames = {}));
