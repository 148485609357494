import { ChainId } from '@pancakeswap/sdk'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NftCollection, NftToken } from 'config/constants/types'
import nftCollections from 'config/constants/nfts/collections'
import { NftHoldState } from '../types'
import fetchOwnedNfts from './fetchOwnedNfts'

const initialState: NftHoldState = {
  data: [],
  userDataLoaded: false
}

// Async thunks
export const fetchOwnedNftsAsync = createAsyncThunk<
  { collection: NftCollection; tokens: NftToken[] }[],
  { account: string; chainId: ChainId; }
>(
  'nftHold/fetchOwnedNftsAsync',
  async ({ account, chainId }) => {
    const collections = Object.keys(nftCollections)
      .map((nftId) => nftCollections[nftId])
      .filter((nft) => nft.chainId === chainId)
    const dataFetched = await fetchOwnedNfts(account, collections)
    return dataFetched
  },
)

export const nftHoldSlice = createSlice({
  name: 'NftHold',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update collections with live data
    builder.addCase(fetchOwnedNftsAsync.fulfilled, (state, action) => {
      state.data = action.payload
      state.userDataLoaded = true
    })
  },
})

export default nftHoldSlice.reducer
