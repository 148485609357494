import Metamask from "../../components/Svg/Icons/Metamask";
import WalletConnect from "../../components/Svg/Icons/WalletConnect";
import TrustWallet from "../../components/Svg/Icons/TrustWallet";
import DefiWallet from "../../components/Svg/Icons/DefiWallet";
import CoinbaseWallet from "../../components/Svg/Icons/CoinbaseWallet";
import { ConnectorNames } from "./types";
var connectors = [
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.MetaMask,
        priority: 1,
        href: "https://metamask.app.link/dapp/candycity.finance/"
    },
    // {
    //   title: "Binance Wallet",
    //   icon: BinanceChain,
    //   connectorId: ConnectorNames.BSC,
    //   priority: 2,
    // },
    {
        title: "Defi Wallet",
        icon: DefiWallet,
        connectorId: ConnectorNames.DefiWallet,
        priority: 2
    },
    {
        title: "Trust Wallet",
        icon: TrustWallet,
        connectorId: ConnectorNames.Injected,
        priority: 3,
        href: "https://link.trustwallet.com/open_url?coin_id=20000714&url=https://candycity.finance/"
    },
    {
        title: "WalletConnect",
        icon: WalletConnect,
        connectorId: ConnectorNames.WalletConnect,
        priority: 4
    },
    {
        title: "Coinbase Wallet",
        icon: CoinbaseWallet,
        connectorId: ConnectorNames.WalletLink,
        priority: 5
    }, 
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var walletConnectConfig = connectors.find(function(c) {
    return c.title === "WalletConnect";
});
