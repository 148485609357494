import BigNumber from 'bignumber.js'
import { Address, erc20ABI } from 'wagmi'
import masterchefABI from 'config/abi/masterchef.json'
import NoBscVaultAbi from 'config/abi/NoBscVaultAbi.json'
import { getMasterChefAddress } from 'utils/addressHelpers'
import { SerializedFarmConfig } from 'config/constants/types'
import { verifyCronosNetwork } from 'utils/verifyNetwork'
import { publicClient } from 'utils/wagmi'
import { ContractFunctionResult } from 'viem'

export const fetchFarmUserAllowances = async (
  account: string,
  farmsToFetch: SerializedFarmConfig[],
  chainId: number,
  proxyAddress?: string,
) => {
  const masterChefAddress = getMasterChefAddress(chainId)

  const lpAllowances = await publicClient({ chainId }).multicall({
    // @ts-ignore
    contracts: farmsToFetch.map((farm) => {
      const lpContractAddress = farm.lpAddress
      return {
        abi: erc20ABI,
        address: lpContractAddress as `0x${string}`,
        functionName: 'allowance',
        args: [account, proxyAddress || masterChefAddress] as const,
      } as const
    }),
    allowFailure: false,
  })

  const parsedLpAllowances = lpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance.toString()).toJSON()
  })

  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (
  account: string,
  farmsToFetch: SerializedFarmConfig[],
  chainId: number,
) => {
  const rawTokenBalances = await publicClient({ chainId }).multicall({
    // @ts-ignore
    contracts: farmsToFetch.map((farm) => {
      const lpContractAddress = farm.lpAddress
      return {
        abi: erc20ABI,
        address: lpContractAddress,
        functionName: 'balanceOf',
        args: [account as Address] as const,
      } as const
    }),
    allowFailure: false,
  })

  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance.toString()).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (
  account: string,
  farmsToFetch: SerializedFarmConfig[],
  chainId: number,
) => {
  const masterChefAddress = getMasterChefAddress(chainId)
  const isBscNetwork = verifyCronosNetwork(chainId)

  const rawStakedBalances = (await publicClient({ chainId }).multicall({
    // @ts-ignore
    contracts: farmsToFetch.map((farm) => {
      return {
        abi: isBscNetwork ? masterchefABI : NoBscVaultAbi,
        address: masterChefAddress,
        functionName: 'userInfo',
        args: [BigInt(farm.pid), account as Address] as const,
      } as const
    }),
    allowFailure: false,
  })) as ContractFunctionResult<typeof masterchefABI, 'userInfo'>[]

  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0].toString()).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: SerializedFarmConfig[], chainId: number) => {
  const isBscNetwork = verifyCronosNetwork(chainId)
  // const multiCallChainId = isBscNetwork ? chainId : await getBscChainId(chainId)
  const multiCallChainId = chainId
  const masterChefAddress = getMasterChefAddress(multiCallChainId)

  const rawEarnings = await publicClient({ chainId: multiCallChainId }).multicall({
    // @ts-ignore
    contracts: farmsToFetch.map((farm) => {
      const pid = isBscNetwork ? farm.pid : farm.bscPid
      return {
        abi: masterchefABI,
        address: masterChefAddress,
        functionName: 'pendingCandy',
        args: [BigInt(pid), account as Address] as const,
      } as const
    }),
    allowFailure: false,
  })

  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings.toString()).toJSON()
  })
  return parsedEarnings
}
