import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  // background: ",
        ";\n  background: ",
        ";\n  border-top: 1px solid ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ';\n  font-size: 30px;\n  font-weight: 500;\n  margin-bottom: 10px;\n  font-family: "Space Grotesk", sans-serif;\n  @media screen and (max-width: 450px) and (orientation: portrait) {\n    margin-left: 20px;\n  }\n'
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  color: #007aff;\n  margin-bottom: 10px;\n  @media screen and (max-width: 450px) and (orientation: portrait) {\n    margin-left: 20px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  width: 50%;\n  margin-bottom: 10px;\n  @media screen and (max-width: 450px) and (orientation: portrait) {\n    font-size: 12px;\n    width: 90%;\n    margin-left: 20px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  margin-bottom: 40px;\n\n  ",
        " {\n    margin-bottom: 0px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-size: 16px;\n  margin-bottom: 8px;\n  text-transform: capitalize;\n\n  &:first-child {\n    color: ",
        ";\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 24px;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-direction: row-reverse;\n  @media screen and (max-width: 450px) and (orientation: portrait) {\n    flex-direction: column;\n    justify-content: center;\n  }\n  img {\n    margin-bottom: 8px;\n  }\n  ",
        " {\n    border-top-width: 0;\n    border-bottom-width: 0;\n    padding: 0 0;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  border-bottom: 1px solid ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
export var StyledFooter = styled(Flex).withConfig({
    componentId: "sc-7631304e-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.isDark ? "#d3d3d3" : "#d3d3d3";
}, function(param) {
    var theme = param.theme;
    return theme.nav.background;
}, function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
});
export var H3 = styled.h3.withConfig({
    componentId: "sc-7631304e-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.colors.text;
});
export var A = styled.a.withConfig({
    componentId: "sc-7631304e-2"
})(_templateObject2());
export var P = styled.p.withConfig({
    componentId: "sc-7631304e-3"
})(_templateObject3(), function(param) {
    var theme = param.theme;
    return theme.colors.textSubtle;
});
export var StyledList = styled.ul.withConfig({
    componentId: "sc-7631304e-4"
})(_templateObject4(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledListItem = styled.li.withConfig({
    componentId: "sc-7631304e-5"
})(_templateObject5(), darkColors.secondary);
export var StyledIconMobileContainer = styled(Box).withConfig({
    componentId: "sc-7631304e-6"
})(_templateObject6());
export var StyledToolsContainer = styled.div.withConfig({
    componentId: "sc-7631304e-7"
})(_templateObject7(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.sm;
});
export var StyledSocialLinks = styled(SocialLinks).withConfig({
    componentId: "sc-7631304e-8"
})(_templateObject8(), darkColors.cardBorder);
export var StyledText = styled.span.withConfig({
    componentId: "sc-7631304e-9"
})(_templateObject9(), darkColors.text);
