import { NATIVE } from '@pancakeswap/sdk'
import { Address, erc20ABI } from 'wagmi'
import { getPools } from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import sousChefV2ABI from 'config/abi/sousChefV2.json'
import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import fromPairs from 'lodash/fromPairs'
import { publicClient } from 'utils/wagmi'
import { getMulticallAddress } from 'utils/addressHelpers'

export const fetchPoolsAllowance = async (account, chainId) => {
  const poolsConfig = await getPools(chainId)
  const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== NATIVE[chainId].symbol)

  const client = publicClient({ chainId })
  const allowances = await client.multicall({
    contracts: nonBnbPools.map((pool) => ({
      abi: erc20ABI,
      address: pool.stakingToken.address as Address,
      functionName: 'allowance',
      args: [account, pool.contractAddress],
    })),
    allowFailure: false
  })

  return fromPairs(nonBnbPools.map((pool, index) =>
    [pool.sousId, new BigNumber(allowances[index].toString()).toJSON()]))
}

export const fetchUserBalances = async (account, chainId) => {
  // Non BNB pools
  const poolsConfig = await getPools(chainId)
  const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== NATIVE[chainId].symbol)
  const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === NATIVE[chainId].symbol)
  const tokens = uniq(nonBnbPools.map((pool) => pool.stakingToken.address))

  const client = publicClient({ chainId })
  const tokenBalance = await client.multicall({
    contracts: [
      {
        abi: [
          {
            inputs: [{ internalType: 'address', name: 'addr', type: 'address' }],
            name: 'getEthBalance',
            outputs: [{ internalType: 'uint256', name: 'balance', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
          },
        ],
        address: getMulticallAddress(chainId),
        functionName: 'getEthBalance',
        args: [account],
      },
      ...tokens.map(
        (token) =>
        ({
          abi: erc20ABI,
          address: token as Address,
          functionName: 'balanceOf',
          args: [account],
        })
      )
    ]
  })
  const [bnbBalance, ...tokenBalancesResults] = tokenBalance

  const tokenBalances = fromPairs(tokens.map((token, index) => [token, tokenBalancesResults[index].result as bigint]))

  const poolTokenBalances = fromPairs(
    nonBnbPools
      .map((pool) => {
        if (!tokenBalances[pool.stakingToken.address]) return null
        return [pool.sousId, new BigNumber(tokenBalances[pool.stakingToken.address].toString()).toJSON()]
      })
      .filter((p): p is [number, string] => Boolean(p)),
  )

  // BNB pools
  const bnbBalanceJson = new BigNumber((bnbBalance.result as bigint).toString()).toJSON()
  const bnbBalances = fromPairs(bnbPools.map((pool) => [pool.sousId, bnbBalanceJson]))

  return { ...poolTokenBalances, ...bnbBalances }
}

export const fetchUserStakeDatas = async (account, chainId) => {
  const poolsConfig = await getPools(chainId)
  const upgradedPools = poolsConfig.filter((pool) => pool.sousId !== 0)

  const client = publicClient({ chainId })
  const upgradedPoolsUserInfo = await client.multicall({
    // @ts-ignore
    contracts: upgradedPools.map((p) => ({
      abi: sousChefV2ABI,
      address: p.contractAddress as Address,
      functionName: 'userInfo',
      args: [account],
    })),
    allowFailure: false
  })

  return fromPairs(
    upgradedPools.map((pool, index) => [pool.sousId, {
      stakedBalance: new BigNumber(upgradedPoolsUserInfo[index][0].toString()).toJSON(),
      lastDepositAt: Number(upgradedPoolsUserInfo[index][3])
    }]),
  )
}

export const fetchUserPendingRewards = async (account, chainId) => {
  const poolsConfig = await getPools(chainId)
  const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)
  const client = publicClient({ chainId })
  const pendingRewards = await client.multicall({
    // @ts-ignore
    contracts: nonMasterPools.map((p) => ({
      abi: sousChefABI,
      address: p.contractAddress as Address,
      functionName: 'pendingReward',
      args: [account],
    })),
    allowFailure: false
  })

  return fromPairs(nonMasterPools.map((pool, index) =>
    [pool.sousId, new BigNumber(pendingRewards[index].toString()).toJSON()]))
}
