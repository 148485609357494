import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE_MAINNET = new Token(
  ChainId.BSC,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CANDY_CRONOS = new Token(
  ChainId.CRONOS,
  '0x06C04B0AD236e7Ca3B3189b1d049FE80109C7977',
  18,
  'CANDY',
  'Candy Token',
  'https://candycity.finance/',
)

export const CANDY_CRONOSTEST = new Token(
  ChainId.CRONOS_TESTNET,
  '0x38C755b7477c4a4B4323866451e409AC7E6751cc',
  18,
  'CANDY',
  'Candy Token',
  'https://candycity.finance/',
)

export const CANDY_POLYGON = new Token(
  ChainId.POLYGON,
  '0x54E53ed24C12c1d3Df7D653587E2f27d3FbaE3d2',
  18,
  'CANDY',
  'Candy Token',
  'https://candycity.finance/',
)

export const USDC_CRONOS = new Token(
  ChainId.CRONOS,
  '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
  6,
  'USDC',
  'USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_CRONOSTEST = new Token(
  ChainId.CRONOS_TESTNET,
  '0x8c2918250dbdec2280F48EC908a8fb3bF37f42E4',
  6,
  'USDC',
  'USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_POLYGON = new Token(
  ChainId.POLYGON,
  '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
  6,
  'USDC',
  'USD Coin',
  'https://www.centre.io/usdc',
)

export const THUNDR_CRONOSTESTNET = new Token(
  ChainId.CRONOS_TESTNET,
  "0x9db2F73157dDf0E2b6dDb4e87B4C7dADcB5F57Fd",
  18,
  "THUNDR",
  "THUNDER",
  "https://islandthunder.io/"
)
export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin')

export const USDC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_CRONOS = new Token(
  ChainId.CRONOS,
  '0x66e428c3f67a68878562e79A0234c1F83c208770',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_CRONOSTEST = new Token(
  ChainId.CRONOS_TESTNET,
  '0x5033C6960001809F5F93db9b4d5F9bCbfF76b524',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_POLYGON = new Token(
  ChainId.POLYGON,
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_CRONOS = new Token(
  ChainId.BSC,
  '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
  18,
  'BUSD',
  'Binance-Peg BUSD Token',
  'https://www.paxos.com/busd/',
)

export const BUSD_BSC = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x8516Fc284AEEaa0374E66037BD2309349FF728eA',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_POLYGON = new Token(
  ChainId.POLYGON,
  '0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.CRONOS]: BUSD_CRONOS,
  [ChainId.CRONOS_TESTNET]: BUSD_CRONOS,
  [ChainId.POLYGON]: BUSD_POLYGON,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
}

export const CANDY = {
  [ChainId.CRONOS]: CANDY_CRONOS,
  [ChainId.CRONOS_TESTNET]: CANDY_CRONOSTEST,
  [ChainId.POLYGON]: CANDY_POLYGON,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.CRONOS]: USDC_CRONOS,
  [ChainId.CRONOS_TESTNET]: USDC_CRONOSTEST,
  [ChainId.POLYGON]: USDC_POLYGON,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.CRONOS]: USDT_CRONOS,
  [ChainId.CRONOS_TESTNET]: USDT_CRONOSTEST,
  [ChainId.POLYGON]: USDT_POLYGON,
  [ChainId.ETHEREUM]: USDT_ETH,
}
