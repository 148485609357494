import { cronosTokens, cronosTestnetTokens, serializeTokens, polygonTokens } from '@pancakeswap/tokens'
import { ChainId } from '@pancakeswap/sdk'
import { Ifo } from './types'

const serializedCronosTokens = serializeTokens(cronosTokens)
const serializedCronosTestnetTokens = serializeTokens(cronosTestnetTokens)
const serializedPolygonTokens = serializeTokens(polygonTokens)

const ifos: Ifo[] = [
  {
    id: 'candy-private-sale',
    address: '0x322Fd34908f9C1ad4fF0070CB7Af0D4F681Ba155',
    chainId: ChainId.CRONOS_TESTNET,
    isActive: false,
    name: 'Bored Candy City',
    subTitle: 'First Come First Served',
    description:
      'Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn in everyway possible in Cronos and every other chain out there.',
    projectSiteUrl: 'https://www.candycity.finance/',
    releaseAt: 1662405060,
    icoToken: serializedCronosTestnetTokens.candy,
    version: 1
  },
  {
    id: 'candy-public-sale',
    address: '0x8FEf43b1f3046F8f58A76c64aD01Bc8d82ff0ad1',
    chainId: ChainId.CRONOS_TESTNET,
    isActive: false,
    name: 'Bored Candy City',
    subTitle: 'First Come First Served',
    description:
      'Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn in everyway possible in Cronos and every other chain out there.',
    projectSiteUrl: 'https://www.candycity.finance/',
    releaseAt: 1662405060,
    icoToken: serializedCronosTestnetTokens.candy,
    version: 1
  },
  // {
  //   id: 'candy-public-sale',
  //   address: '0x1fB2151f7cB40805905f9b731c373C5Cc682AA57',
  //   chainId: ChainId.CRONOS,
  //   isActive: true,
  //   name: 'Bored Candy City',
  //   subTitle: 'First Come First Served',
  //   description:
  //     'Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn in everyway possible in Cronos and every other chain out there.',
  //   projectSiteUrl: 'https://www.candycity.finance/',
  //   releaseAt: 1662405060,
  //   icoToken: serializedCronosTokens.candy,
  // },
  {
    id: 'candy-public-sale',
    address: '0x3989eC327e8E96623DeBf7002983A2B3861516Cd',
    chainId: ChainId.CRONOS,
    isActive: false,
    name: 'Bored Candy City',
    subTitle: 'First Come First Served',
    description:
      'Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn in everyway possible in Cronos and every other chain out there.',
    projectSiteUrl: 'https://www.candycity.finance/',
    releaseAt: 1664306642,
    icoToken: serializedCronosTokens.candy,
    version: 1
  },
  // {
  //   id: 'warz-public-sale',
  //   address: '0x5F5dE2384c348a439FEE8DA59CC20B6d4134EC9b',
  //   chainId: ChainId.CRONOS,
  //   isActive: true,
  //   name: 'Metawarz Shooter',
  //   subTitle: 'First Come First Served',
  //   description:
  //     'Immersive Cyber-Punk Themed First-Person Shooter #P2E game. Built on #UE5, Delivering a high quality game that is truly supreme & fun.',
  //   projectSiteUrl: 'https://www.candycity.finance/',
  //   releaseAt: 1670811316,
  //   icoToken: serializedCronosTokens.warz,
  //   version: 2
  // },
  {
    id: 'warz-public-sale',
    address: '0xc4f33172a3AB92a8664532714e27122B9c1b0CCC',
    chainId: ChainId.CRONOS,
    isActive: false,
    name: 'Metawarz Shooter',
    subTitle: 'First Come First Served',
    description:
      'Immersive Cyber-Punk Themed First-Person Shooter #P2E game. Built on #UE5, Delivering a high quality game that is truly supreme & fun.',
    projectSiteUrl: 'https://metawarz.io/',
    releaseAt: 1670811316,
    icoToken: serializedCronosTokens.warz,
    version: 2
  },
  {
    id: 'imperivm-public-sale',
    address: '0x3Ebdf3b9897149Fd1ADcCcc08d1BB0de700f5CFe',
    chainId: ChainId.CRONOS,
    isActive: false,
    name: 'Imperivm',
    subTitle: 'First Come First Served',
    description:
      'IMPERIVM is a strategic MMO fantasy game, developed on the Cronos and Polygon blockchains. The game is set in the medieval age, with fictional creatures and a strong influence from the Greek/Roman mythology. Every player will be in charge of his own fortress and will have to expand it and recruit his own army in order to face battles and conquer enemies',
    projectSiteUrl: 'https://imperivm.io/',
    releaseAt: 1679616000,
    icoToken: serializedCronosTokens.imp,
    version: 3
  },
  {
    id: 'imperivm-public-sale-2',
    address: '0x9068c34A2e80Eee6d0745c851a5c250ADffeE671',
    chainId: ChainId.CRONOS,
    isActive: false,
    name: 'Imperivm 2',
    subTitle: 'WL open for all',
    description:
      'IMPERIVM is a strategic MMO fantasy game, developed on the Cronos and Polygon blockchains. The game is set in the medieval age, with fictional creatures and a strong influence from the Greek/Roman mythology. Every player will be in charge of his own fortress and will have to expand it and recruit his own army in order to face battles and conquer enemies',
    projectSiteUrl: 'https://imperivm.io/',
    releaseAt: 1680310800,
    icoToken: serializedCronosTokens.imp,
    version: 3
  },
  {
    id: 'imperivm-public-sale',
    address: '0x84e9e445B0215e6bdfDa7A7f277f59f04a8b62B3',
    chainId: ChainId.CRONOS_TESTNET,
    isActive: false,
    name: 'Imperivm',
    subTitle: 'First Come First Served',
    description:
      'IMPERIVM is a strategic MMO fantasy game, developed on the Cronos and Polygon blockchains. The game is set in the medieval age, with fictional creatures and a strong influence from the Greek/Roman mythology. Every player will be in charge of his own fortress and will have to expand it and recruit his own army in order to face battles and conquer enemies',
    projectSiteUrl: 'https://imperivm.io/',
    releaseAt: 1679616000,
    icoToken: serializedCronosTestnetTokens.imp,
    version: 3
  },
  {
    id: 'bluespade-public-sale',
    address: '0x0378173bDc184537354013B25Be718866f89B4F1',
    chainId: ChainId.CRONOS,
    isActive: false,
    name: 'BlueSpade',
    subTitle: 'Decentralized perpetual exchange',
    description:
      'Bluespade is a decentralised perpetual exchange that allows users to trade leveraged positions with low fees and zero price impact, whilst having the peace of mind that all trades and collateral are stored transparently on-chain.',
    projectSiteUrl: 'https://www.bluespade.xyz/',
    releaseAt: 1683504000,
    icoToken: serializedCronosTokens.blu,
    version: 1
  },
  // {
  //   id: 'candy-polygon-public-sale',
  //   address: '0xda3aac0A30d6C5B88881e649B87AF93022B41d76',
  //   chainId: ChainId.POLYGON,
  //   isActive: true,
  //   name: 'Bored Candy City',
  //   subTitle: 'First Come First Served',
  //   description:
  //     'Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn in everyway possible in Polygon and every other chain out there.',
  //   projectSiteUrl: 'https://www.candycity.finance/',
  //   releaseAt: 1690858800,
  //   icoToken: serializedPolygonTokens.candy,
  //   version: 1
  // },
  // {
  //   id: 'candy-polygon-public-sale',
  //   address: '0x013dE7AE076A1258C447984B371829b24d65dA69',
  //   chainId: ChainId.POLYGON,
  //   isActive: true,
  //   name: 'Bored Candy City',
  //   subTitle: 'First Come First Served',
  //   description:
  //     'Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn in everyway possible in Polygon and every other chain out there.',
  //   projectSiteUrl: 'https://www.candycity.finance/',
  //   releaseAt: 1690858800,
  //   icoToken: serializedPolygonTokens.candy,
  //   version: 1
  // },
  // {
  //   id: 'candy-polygon-public-sale',
  //   address: '0x595bD9F9150Fe5616Ea424C82635E714A1765999',
  //   chainId: ChainId.POLYGON,
  //   isActive: true,
  //   name: 'Bored Candy City',
  //   subTitle: 'First Come First Served',
  //   description:
  //     'Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn in everyway possible in Polygon and every other chain out there.',
  //   projectSiteUrl: 'https://www.candycity.finance/',
  //   releaseAt: 1690858800,
  //   icoToken: serializedPolygonTokens.candy,
  //   version: 4
  // },
  {
    id: 'banshees-public-sale',
    address: '0xFa8461bE5fd504DCEf9530f52D0A380B9b801264',
    chainId: ChainId.POLYGON,
    isActive: true,
    name: 'Banshees',
    subTitle: 'First Come First Served',
    description:
      'Users who participate more than 1k MATIC will receive 50% extra HITCOIN vested for 6 months. For 500 MATIC or more it will be 25% extra HITCOIN vested for 6 months. And for 100 MATIC or more you will receive a free WL to mint PaperhandZ.',
    projectSiteUrl: 'https://blckmrkt.io/',
    releaseAt: 1690858800,
    icoToken: serializedPolygonTokens.$hit,
    version: 4
  },
  // {
  //   id: 'candy-referral-private-sale',
  //   address: '0x4010FF9e06332cCBC8aFaEA74fd7Bd446765b232',
  //   chainId: ChainId.CRONOS_TESTNET,
  //   isActive: true,
  //   name: 'Bored Candy City',
  //   subTitle: 'First Come First Served',
  //   description:
  //     'Bored Candy City is not just a DEX. We are working on a complete Defi ecosystem around the $CANDY token. Our ultimate goal is to create an entire ecosystem around $CANDY for all to trade, play, have fun, earn in everyway possible in Cronos and every other chain out there.',
  //   projectSiteUrl: 'https://www.candycity.finance/',
  //   releaseAt: 1694443351,
  //   icoToken: serializedCronosTestnetTokens.candy,
  //   version: 4
  // },
]

export default ifos
