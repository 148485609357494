import { ChainId, Token, WCRO } from '@pancakeswap/sdk'
import { CANDY_CRONOSTEST, THUNDR_CRONOSTESTNET, USDC_CRONOSTEST, USDT_CRONOSTEST } from './common'

export const cronosTestnetTokens = {
  thundr: THUNDR_CRONOSTESTNET,
  cro: new Token(
    ChainId.CRONOS_TESTNET,
    '0x6a3173618859C7cd40fAF6921b5E9eB6A76f1fD4',
    18,
    'TCRO',
    'CRO',
    'https://chain.crypto.com/',
  ),
  wcro: WCRO[ChainId.CRONOS_TESTNET],
  candy: CANDY_CRONOSTEST,
  usdc: USDC_CRONOSTEST,
  usdt: USDT_CRONOSTEST,
  imp: new Token(
    ChainId.CRONOS,
    '0x2444fa57E7f78f93a330e81eD58dA7cb3ad753b8',
    18,
    'IMP',
    'IMPERIVM',
    'https://imperivm.io/',
  ),
}
