import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'
import addresses from 'config/constants/contracts'
import { VaultKey } from 'state/types'

export const getAddress = (addressMap, chainId?: number): `0x${string}` => {
  // @ts-ignore
  return addressMap[chainId] ? addressMap[chainId] : addressMap[ChainId.CRONOS] // It needs to be changed to CRONOS after deploy
}

export const getMasterChefAddress = (chainId?: number) => {
  return getAddress(addresses.masterChef, chainId)
}
export const getMasterChefV1Address = () => {
  return getAddress(addresses.masterChefV1)
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId)
}
export const getKingAddress = (chainId?: number) => {
  return getAddress(addresses.king, chainId)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeBunniesAddress = () => {
  return getAddress(addresses.pancakeBunnies)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getPredictionsV1Address = () => {
  return getAddress(addresses.predictionsV1)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddressEaster = () => {
  return getAddress(addresses.tradingCompetitionEaster)
}
export const getTradingCompetitionAddressFanToken = () => {
  return getAddress(addresses.tradingCompetitionFanToken)
}

export const getTradingCompetitionAddressMobox = () => {
  return getAddress(addresses.tradingCompetitionMobox)
}

export const getTradingCompetitionAddressMoD = () => {
  return getAddress(addresses.tradingCompetitionMoD)
}

export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}

export const getVaultPoolAddress = (vaultKey: VaultKey, chainId: number) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey], chainId)
}

export const getCakeVaultAddress = (chainId: number) => {
  return getAddress(addresses.cakeVault, chainId)
}

export const getLockVaultAddress = (chainId: number) => {
  return getAddress(addresses.lockVaults, chainId)
}

export const getNftVaultAddress = (chainId: number) => {
  return getAddress(addresses.nftVault, chainId)
}

export const getCakeFlexibleSideVaultAddress = (chainId: number) => {
  return getAddress(addresses.cakeFlexibleSideVault, chainId)
}

export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getBunnySpecialXmasAddress = () => {
  return getAddress(addresses.bunnySpecialXmas)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}

export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
}
export const getPotteryDrawAddress = () => {
  return getAddress(addresses.potteryDraw)
}

export const getZapAddress = (chainId: number) => {
  return getAddress(addresses.zap, chainId)
}
export const getICakeAddress = () => {
  return getAddress(addresses.iCake)
}

export const getReferralAddress = (chainId: number) => {
  return getAddress(addresses.referral, chainId)
}

export const getBCakeFarmBoosterAddress = (chainId: number) => {
  return getAddress(addresses.bCakeFarmBooster, chainId)
}

export const getBCakeFarmBoosterProxyFactoryAddress = (chainId: number) => {
  return getAddress(addresses.bCakeFarmBoosterProxyFactory, chainId)
}

export const getCandyLockAddress = (chainId: number) => {
  return getAddress(addresses.candyLock, chainId)
}

export const getCandyLockV2Address = (chainId: number) => {
  return getAddress(addresses.candyLockV2, chainId)
}

export const getCandyLockV3Address = (chainId: number) => {
  return getAddress(addresses.candyLockV3, chainId)
}

export const getTokenFactoryAddress = (chainId: number) => {
  return getAddress(addresses.tokenFactory, chainId)
}

export const getPartnerLockAddress = (chainId: number) => {
  return getAddress(addresses.partnerLock, chainId)
}

export const getMerkleVestingAddress = (chainId: number) => {
  return getAddress(addresses.merkleVesting, chainId)
}

export const getPartnerVestingAddress = (chainId: number) => {
  return getAddress(addresses.partnerVesting, chainId)
}

/// Get address amp ///

export const getBCakeFarmBoosterAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.bCakeFarmBooster
}

export const getBCakeFarmBoosterProxyFactoryAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.bCakeFarmBoosterProxyFactory
}

export const getCandyLockAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.candyLock
}

export const getCandyLockV2AddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.candyLockV2
}

export const getCandyLockV3AddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.candyLockV3
}

export const getTokenFactoryAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.tokenFactory
}

export const getKingAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.king
}

export const getMasterChefAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.masterChef
}

export const getMerkleVestingAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.merkleVesting
}

export const getNftVaultAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.nftVault
}

export const getPartnerLockAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.partnerLock
}

export const getPartnerVestingAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.partnerVesting
}

export const getReferralAddressMap = (): { [chainId: number]: Address } => {
  // @ts-ignore
  return addresses.referral
}
