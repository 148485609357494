import { ChainId } from '@pancakeswap/sdk'
import { polygon, cronos, cronosTestnet } from 'wagmi/chains'
import { getNodeRealUrlV2 } from 'utils/nodeReal'

export const PUBLIC_NODES: Record<ChainId, readonly string[]> = {
  [ChainId.BSC]: [
    process.env.NEXT_PUBLIC_NODE_PRODUCTION,
    getNodeRealUrlV2(ChainId.BSC, process.env.NEXT_PUBLIC_NODE_REAL_API_ETH),
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.binance.org',
  ].filter(Boolean),
  [ChainId.BSC_TESTNET]: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  [ChainId.ETHEREUM]: [
    getNodeRealUrlV2(ChainId.ETHEREUM, '1659dfb40aa24bbb8153a677b98064d7'),
    'https://eth.llamarpc.com',
    'https://cloudflare-eth.com',
  ].filter(Boolean),
  [ChainId.GOERLI]: [
    getNodeRealUrlV2(ChainId.GOERLI, '8a4432e42df94dcca2814fde8aea2a2e'),
    'https://eth-goerli.public.blastapi.io',
    'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
  ].filter(Boolean),
  // [ChainId.CRONOS]: [...cronos.rpcUrls.public.http, "https://rpc.vvs.finance/", "https://evm.cronos.org/"].filter(Boolean),
  [ChainId.CRONOS]: ["https://cronos-evm-rpc.publicnode.com" /* , "https://evm.cronos.org/" */].filter(Boolean),
  [ChainId.CRONOS_TESTNET]: [...cronosTestnet.rpcUrls.public.http].filter(Boolean),
  [ChainId.POLYGON]: ["https://rpc.ankr.com/polygon" /* , ...polygon.rpcUrls.public.http */].filter(Boolean),
}
