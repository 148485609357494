import { parseUnits } from '@ethersproject/units'
import BigNumber from 'bignumber.js'
import {
  CampaignType,
  DeserializedFarmConfig,
  DeSerializedNftMintConfig,
  DeSerializedNftStakingConfig,
  DeserializedPartnerFarmConfig,
  DeserializedPoolConfig,
  MerkleItem,
  NftCollection,
  NftToken,
  Rarity,
  SerializedFarmConfig,
  SerializedNftMintConfig,
  SerializedNftStakingConfig,
  SerializedPartnerFarmConfig,
  SerializedPoolConfig,
  Team,
  TranslatableText,
} from 'config/constants/types'

export enum GAS_PRICE {
  default = '5',
  fast = '6',
  instant = '7',
  testnet = '10',
}

export const GAS_PRICE_CRONOS = parseUnits('20000', 'gwei').toString()

export const GAS_PRICE_GWEI = {
  default: parseUnits(GAS_PRICE.default, 'gwei').toString(),
  fast: parseUnits(GAS_PRICE.fast, 'gwei').toString(),
  instant: parseUnits(GAS_PRICE.instant, 'gwei').toString(),
  testnet: parseUnits(GAS_PRICE.testnet, 'gwei').toString(),
}

export type DeserializedPoolVault = DeserializedPool & DeserializedCakeVault
export type DeserializedPoolLockedVault = DeserializedPool & DeserializedLockedCakeVault

export interface BigNumberToJson {
  type: 'BigNumber'
  hex: string
}

export type SerializedBigNumber = string

interface SerializedFarmUserData {
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
  proxy?: {
    allowance: string
    tokenBalance: string
    stakedBalance: string
    earnings: string
  }
}

export interface DeserializedFarmUserData {
  allowance: BigNumber
  tokenBalance: BigNumber
  stakedBalance: BigNumber
  earnings: BigNumber
  proxy?: {
    allowance: BigNumber
    tokenBalance: BigNumber
    stakedBalance: BigNumber
    earnings: BigNumber
  }
}

export interface SerializedFarm extends SerializedFarmConfig {
  tokenPriceBusd?: string
  quoteTokenPriceBusd?: string
  tokenAmountTotal?: SerializedBigNumber
  quoteTokenAmountTotal?: SerializedBigNumber
  lpTotalInQuoteToken?: SerializedBigNumber
  lpTotalSupply?: SerializedBigNumber
  lpMCSupply?: SerializedBigNumber
  tokenPriceVsQuote?: SerializedBigNumber
  poolWeight?: SerializedBigNumber
  userData?: SerializedFarmUserData
  boosted?: boolean
}

export interface DeserializedFarm extends DeserializedFarmConfig {
  tokenPriceBusd?: string
  quoteTokenPriceBusd?: string
  tokenAmountTotal?: BigNumber
  quoteTokenAmountTotal?: BigNumber
  lpTotalInQuoteToken?: BigNumber
  lpTotalSupply?: BigNumber
  lpMCSupply?: BigNumber
  tokenPriceVsQuote?: BigNumber
  poolWeight?: BigNumber
  userData?: DeserializedFarmUserData
  boosted?: boolean
}

interface CorePartnerFarmProps {
  startBlock?: number
  endBlock?: number
  apr?: number
  stakingTokenPrice?: number
  earningTokenPrice?: number
  depositFee?: number
  boostingRate?: number
  nftStakingLimit?: number
  totalStakedNft?: number
}

export interface DeserializedPartnerFarm extends DeserializedPartnerFarmConfig, CorePartnerFarmProps {
  totalBoostedShare?: BigNumber
  totalStakedToken?: BigNumber
  tokenStakingLimit?: BigNumber
  userDataLoaded?: boolean
  userData?: {
    tokenAllowance: BigNumber
    nftAllowed: boolean
    holdingTokens: BigNumber
    stakedTokens: BigNumber
    holdingNfts: NftToken[]
    stakedNfts: NftToken[]
    pendingReward: BigNumber
  }
}

export interface SerializedPartnerFarm extends SerializedPartnerFarmConfig, CorePartnerFarmProps {
  totalBoostedShare?: SerializedBigNumber
  totalStakedToken?: SerializedBigNumber
  tokenStakingLimit?: SerializedBigNumber
  userData?: {
    tokenAllowance: SerializedBigNumber
    nftAllowed: boolean
    holdingTokens: SerializedBigNumber
    stakedTokens: SerializedBigNumber
    holdingNfts: NftToken[]
    stakedNfts: NftToken[]
    pendingReward: SerializedBigNumber
  }
}

export enum VaultKey {
  CakeVault = 'cakeVault',
  CakeFlexibleSideVault = 'cakeFlexibleSideVault'
}

interface CorePoolProps {
  startBlock?: number
  endBlock?: number
  apr?: number
  rawApr?: number
  stakingTokenPrice?: number
  earningTokenPrice?: number
  vaultKey?: VaultKey
  depositFee?: number
  unlockFee?: number
  lockPeriod?: number
}

export interface DeserializedPool extends DeserializedPoolConfig, CorePoolProps {
  totalStaked?: BigNumber
  stakingLimit?: BigNumber
  stakingLimitEndBlock?: number
  profileRequirement?: {
    required: boolean
    thresholdPoints: BigNumber
  }
  userDataLoaded?: boolean
  userData?: {
    allowance: BigNumber
    stakingTokenBalance: BigNumber
    stakedBalance: BigNumber
    lastDepositAt: number
    pendingReward: BigNumber
  }
}

export interface SerializedPool extends SerializedPoolConfig, CorePoolProps {
  totalStaked?: SerializedBigNumber
  stakingLimit?: SerializedBigNumber
  numberBlocksForUserLimit?: number
  profileRequirement?: {
    required: boolean
    thresholdPoints: SerializedBigNumber
  }
  userData?: {
    allowance: SerializedBigNumber
    stakingTokenBalance: SerializedBigNumber
    stakedBalance: SerializedBigNumber
    lastDepositAt: number
    pendingReward: SerializedBigNumber
  }
}

// Slices states

export interface SerializedFarmsState {
  data: SerializedFarm[]
  loadArchivedFarmsData: boolean
  userDataLoaded: boolean
  loadingKeys: Record<string, boolean>
  poolLength?: number
  regularCakePerBlock?: number
}

export interface DeserializedFarmsState {
  data: DeserializedFarm[]
  loadArchivedFarmsData: boolean
  userDataLoaded: boolean
  poolLength?: number
  regularCakePerBlock?: number
}

export interface SerializedVaultFees {
  performanceFee: number
  withdrawalFee: number
  withdrawalFeePeriod: number
}

export interface DeserializedVaultFees extends SerializedVaultFees {
  performanceFeeAsDecimal: number
}

export interface SerializedVaultUser {
  isLoading: boolean
  userShares: SerializedBigNumber
  cakeAtLastUserAction: SerializedBigNumber
  lastDepositedTime: string
  lastUserActionTime: string
}

export interface SerializedLockedVaultUser extends SerializedVaultUser {
  lockStartTime: string
  lockEndTime: string
  userBoostedShare: SerializedBigNumber
  locked: boolean
  lockedAmount: SerializedBigNumber
  currentPerformanceFee: SerializedBigNumber
  currentOverdueFee: SerializedBigNumber
}

export interface DeserializedVaultUser {
  isLoading: boolean
  userShares: BigNumber
  cakeAtLastUserAction: BigNumber
  lastDepositedTime: string
  lastUserActionTime: string
  balance: {
    cakeAsNumberBalance: number
    cakeAsBigNumber: BigNumber
    cakeAsDisplayBalance: string
  }
}

export interface DeserializedLockedVaultUser extends DeserializedVaultUser {
  lastDepositedTime: string
  lastUserActionTime: string
  lockStartTime: string
  lockEndTime: string
  burnStartTime: string
  userBoostedShare: BigNumber
  locked: boolean
  lockedAmount: BigNumber
  currentPerformanceFee: BigNumber
  currentOverdueFee: BigNumber
}

export interface DeserializedCakeVault {
  totalShares?: BigNumber
  totalLockedAmount?: BigNumber
  pricePerFullShare?: BigNumber
  totalCakeInVault?: BigNumber
  fees?: DeserializedVaultFees
  userData?: DeserializedVaultUser
}

export interface DeserializedLockedCakeVault extends Omit<DeserializedCakeVault, 'userData'> {
  totalLockedAmount?: BigNumber
  userData?: DeserializedLockedVaultUser
}

export interface SerializedLockedCakeVault extends Omit<SerializedCakeVault, 'userData'> {
  totalLockedAmount?: SerializedBigNumber
  userData?: SerializedLockedVaultUser
}

export interface SerializedCakeVault {
  totalShares?: SerializedBigNumber
  pricePerFullShare?: SerializedBigNumber
  totalCakeInVault?: SerializedBigNumber
  fees?: SerializedVaultFees
  userData?: SerializedVaultUser
}

export interface PoolsState {
  data: SerializedPool[]
  cakeVault: SerializedLockedCakeVault
  cakeFlexibleSideVault: SerializedCakeVault
  userDataLoaded: boolean
}

export interface PartnerFarmsState {
  data: SerializedPartnerFarm[]
  userDataLoaded: boolean
}

export type TeamsById = {
  [key: string]: Team
}

export interface Achievement {
  id: string
  type: CampaignType
  address: string
  title: TranslatableText
  description?: TranslatableText
  badge: string
  points: number
}

// NFTs
interface NftMintBaseProps {
  maxSupply?: number
  totalSupply?: number
  totalFreeCount?: number
  totalWlCount?: number
  totalPubCount?: number
  freeStartAt?: number
  sellStartAt?: number
  mintLimit?: number
}

export interface SerializedNftMintProps extends SerializedNftMintConfig, NftMintBaseProps {
  wlPrice?: SerializedBigNumber
  pubPrice?: SerializedBigNumber
  wlNativePrice?: SerializedBigNumber // This will be used in multi pay mint
  pubNativePrice?: SerializedBigNumber // This will be used in multi pay mint
  userData?: {
    freeCount: number // Free minted count
    wlCount: number // Whitelist minted count
    pubCount: number // Public minted count
    isEbisusBayMember: boolean // Is this account EbisusBay marketplace member
    isWhitelisted: boolean // This is used for the TeenDACC mint
    freeMerkle?: MerkleItem // Merkle tree node for the free mint
    wlMerkle?: MerkleItem // Merkle tree node for the whitelisted mint
    allowance?: SerializedBigNumber
  }
}

export interface DeserializedNftMintProps extends DeSerializedNftMintConfig, NftMintBaseProps {
  wlPrice?: BigNumber
  pubPrice?: BigNumber
  wlNativePrice?: BigNumber // This will be used in multi pay mint
  pubNativePrice?: BigNumber // This will be used in multi pay mint
  userData?: {
    freeCount: number // Free minted count
    wlCount: number // Whitelist minted count
    pubCount: number // Public minted count
    isEbisusBayMember: boolean // Is this account EbisusBay marketplace member
    isWhitelisted: boolean // This is used for the TeenDACC mint
    freeMerkle?: MerkleItem // Merkle tree node for the free mint
    wlMerkle?: MerkleItem // Merkle tree node for the whitelisted mint
    allowance?: BigNumber
  }
}

export interface NftMintsState {
  data: SerializedNftMintProps[]
  userDataLoaded: boolean
}

export interface NftHoldState {
  data: { collection: NftCollection; tokens: NftToken[] }[]
  userDataLoaded: boolean
}

export interface SerializedNftStakingProps extends SerializedNftStakingConfig {
  emissions: { [rarity in Rarity]: SerializedBigNumber }
  weights: { [rarity in Rarity]: number }
  supplies: { [rarity in Rarity]: number }
  supply: number
  rewardPerDay: SerializedBigNumber
  userData?: {
    approved: boolean
    stakedTokens: NftToken[]
    ownedTokens: NftToken[]
    rewards: SerializedBigNumber
  }
}

export interface DeserializedNftStakingProps extends DeSerializedNftStakingConfig {
  emissions: { [rarity in Rarity]: BigNumber }
  weights: { [rarity in Rarity]: number }
  supplies: { [rarity in Rarity]: number }
  supply: number
  rewardPerDay: BigNumber
  userData?: {
    approved: boolean
    stakedTokens: NftToken[]
    ownedTokens: NftToken[]
    rewards: BigNumber
  }
}

export interface NftStakingState {
  data: SerializedNftStakingProps[]
  userDataLoaded: boolean
}

export interface NftVaultProps {
  farmPid: number
  supplies: { [rarity in Rarity]: number }
  userData?: {
    approved: boolean
    ownedTokens: NftToken[]
    stakedTokens: NftToken[]
    boosts: number
  }
}

export interface NftVaultState {
  nft: NftCollection
  rarityBoosts: { [rarity in Rarity]: number }
  maxPerPool: number
  vaults: NftVaultProps[]
  userDataLoaded: boolean
}

// Global state

export interface State {
  farms: SerializedFarmsState
  pools: PoolsState
  partnerFarms: PartnerFarmsState
  nftMints: NftMintsState
  nftHold: NftHoldState
  nftStaking: NftStakingState
  nftVault: NftVaultState
}
