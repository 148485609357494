import { ChainId } from '@pancakeswap/sdk'
import FarmsBscPriceHelper from './farms/56'
import FarmsBscTestnetPriceHelper from './farms/97'
import FarmsEthereumPriceHelper from './farms/1'
import FarmsGoerliPriceHelper from './farms/5'
import FarmsCronosPriceHelper from './farms/25'
import PoolsBscPriceHelper from './pools/56'
import PoolsBscTestnetPriceHelper from './pools/97'
import PoolsEthereumPriceHelper from './pools/1'
import PoolsGoerliPriceHelper from './pools/5'
import PoolsCronosPriceHelper from './pools/25'
import PoolsPolygonPriceHelper from './pools/137'
import PartnerFarmsBscPriceHelper from './partnerFarms/56'
import PartnerFarmsBscTestnetPriceHelper from './partnerFarms/97'
import PartnerFarmsEthereumPriceHelper from './partnerFarms/1'
import PartnerFarmsGoerliPriceHelper from './partnerFarms/5'
import PartnerFarmsCronosPriceHelper from './partnerFarms/25'
import PartnerFarmsPolygonPriceHelper from './partnerFarms/137'

export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.BSC:
      return FarmsBscPriceHelper
    case ChainId.BSC_TESTNET:
      return FarmsBscTestnetPriceHelper
    case ChainId.ETHEREUM:
      return FarmsEthereumPriceHelper
    case ChainId.GOERLI:
      return FarmsGoerliPriceHelper
    case ChainId.CRONOS:
      return FarmsCronosPriceHelper
    default:
      return []
  }
}

export const getPoolsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.BSC:
      return PoolsBscPriceHelper
    case ChainId.BSC_TESTNET:
      return PoolsBscTestnetPriceHelper
    case ChainId.ETHEREUM:
      return PoolsEthereumPriceHelper
    case ChainId.GOERLI:
      return PoolsGoerliPriceHelper
    case ChainId.CRONOS:
      return PoolsCronosPriceHelper
    case ChainId.POLYGON:
      return PoolsPolygonPriceHelper
    default:
      return []
  }
}

export const getPartnerFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.BSC:
      return PartnerFarmsBscPriceHelper
    case ChainId.BSC_TESTNET:
      return PartnerFarmsBscTestnetPriceHelper
    case ChainId.ETHEREUM:
      return PartnerFarmsEthereumPriceHelper
    case ChainId.GOERLI:
      return PartnerFarmsGoerliPriceHelper
    case ChainId.CRONOS:
      return PartnerFarmsCronosPriceHelper
    case ChainId.POLYGON:
      return PartnerFarmsPolygonPriceHelper
    default:
      return []
  }
}
