import BigNumber from 'bignumber.js'
import { Token, ChainId } from '@pancakeswap/sdk'
import { SerializedToken } from '@pancakeswap/tokens'

// a list of tokens by chain
export type ChainMap<T> = {
  readonly [chainId in ChainId]: T
}

export type ChainTokenList = ChainMap<Token[]>

export type TranslatableText =
  | string
  | {
    key: string
    data?: {
      [key: string]: string | number
    }
  }
export interface Address {
  338?: string
  25: string
  [chainId: number]: string
}

export enum PoolIds {
  poolBasic = 'poolBasic',
  poolUnlimited = 'poolUnlimited',
}

export type IfoStatus = 'idle' | 'coming_soon' | 'live' | 'finished' | 'claimable'
export type IfoV3Status = 'idle' | 'coming_soon' | 'wl_sale' | 'pub_sale' | 'finished' | 'claimable'

export interface Ifo {
  id: string
  chainId: ChainId
  isActive: boolean
  address: string
  name: string
  subTitle?: string
  description?: string
  projectSiteUrl: string
  releaseAt: number
  icoToken: SerializedToken
  version: number
}

export enum Rarity {
  GOD,
  KING,
  PRINCE,
  MINISTER,
  CITIZEN
}

export const RARITY_STRING = {
  [Rarity.GOD]: 'CANDY GOD',
  [Rarity.KING]: 'CANDY KING',
  [Rarity.PRINCE]: 'CANDY PRINCE',
  [Rarity.MINISTER]: 'CANDY MINISTER',
  [Rarity.CITIZEN]: 'CANDY CITIZEN',
}

export const DUELS_RARITY_STRING = {
  [Rarity.GOD]: 'ONSLAUGHT',
  [Rarity.KING]: 'WARLORD',
  [Rarity.PRINCE]: 'BATTLEBORN',
  [Rarity.MINISTER]: 'DUELIST',
  [Rarity.CITIZEN]: 'BRASS',
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
  'AUTO' = 'Auto',
}

export enum NftMintType {
  'COMMON' = 'Common',
  'SPECIAL' = 'Special',
  'MULTIPAY' = 'MultiPay',
  'DUELMINT' = 'DuelMint'
}

interface FarmConfigBaseProps {
  isStable?: boolean
  pid: number
  v1pid?: number
  bscPid?: number
  lpSymbol: string
  lpAddress: string
  multiplier?: string
  isCommunity?: boolean
  auctionHostingStartSeconds?: number
  auctionHostingEndDate?: string
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
  isStaking?: boolean
  boosted?: boolean
}

export interface SerializedFarmConfig extends FarmConfigBaseProps {
  token: SerializedToken
  quoteToken: SerializedToken
}

export interface DeserializedFarmConfig extends FarmConfigBaseProps {
  token: Token
  quoteToken: Token
}

interface PartnerFarmConfigBaseProps {
  sousId: number
  contractAddress: string
  tokenPerBlock: string
  isFinished?: boolean
  nft?: NftCollection
  enableEmergencyWithdraw?: boolean
  version: number // Version 1: Simple partner farm, 2: NFT boosted partner farm
}

export interface SerializedPartnerFarmConfig extends PartnerFarmConfigBaseProps {
  earningToken: SerializedToken
  stakingToken: SerializedToken
}

export interface DeserializedPartnerFarmConfig extends PartnerFarmConfigBaseProps {
  earningToken: Token
  stakingToken: Token
}

interface PoolConfigBaseProps {
  sousId: number
  contractAddress: string
  poolCategory: PoolCategory
  tokenPerBlock: string
  isFinished?: boolean
  enableEmergencyWithdraw?: boolean
  version?: number
}

export interface SerializedPoolConfig extends PoolConfigBaseProps {
  earningToken: SerializedToken
  stakingToken: SerializedToken
}

export interface DeserializedPoolConfig extends PoolConfigBaseProps {
  earningToken: Token
  stakingToken: Token
}

export interface MerkleItem {
  index: number
  amount: number
  proof: string[]
}

export interface NftCollection {
  name: string
  symbol: string
  description: string
  address: string
  chainId: ChainId
  imgExt: string
}

export interface NftToken {
  id: number
  image: string
  rarity: Rarity
  proof: string[]
}

interface NftMintBaseConfig {
  id: number
  chainId: ChainId
  collection: NftCollection
  isFinished?: boolean
  type: NftMintType
}

export interface SerializedNftMintConfig extends NftMintBaseConfig {
  payingToken: SerializedToken
}

export interface DeSerializedNftMintConfig extends NftMintBaseConfig {
  payingToken: Token
}

export enum NFT_STAKING_TYPE {
  FIXED,
  SHARED,
  PARTNER
}

interface NftStakingBaseConfig {
  id: number
  chainId: ChainId
  address: string
  collection: NftCollection
  type: NFT_STAKING_TYPE
  finished?: boolean
}

export interface SerializedNftStakingConfig extends NftStakingBaseConfig {
  earningToken: SerializedToken
}

export interface DeSerializedNftStakingConfig extends NftStakingBaseConfig {
  earningToken: Token
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type TeamImages = {
  alt: string
} & Images

export type Team = {
  id: number
  name: string
  description: string
  isJoinable?: boolean
  users: number
  points: number
  images: TeamImages
  background: string
  textColor: string
}

export type CampaignType = 'ifo' | 'teambattle' | 'participation'

export type Campaign = {
  id: string
  type: CampaignType
  title?: TranslatableText
  description?: TranslatableText
  badge?: string
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}

export enum LotteryStatus {
  PENDING = 'pending',
  OPEN = 'open',
  CLOSE = 'close',
  CLAIMABLE = 'claimable',
}

export interface LotteryTicket {
  id: string
  number: string
  status: boolean
  rewardBracket?: number
  roundId?: string
  cakeReward?: string
}

export interface LotteryTicketClaimData {
  ticketsWithUnclaimedRewards: LotteryTicket[]
  allWinningTickets: LotteryTicket[]
  cakeTotal: BigNumber
  roundId: string
}

// Farm Auction
export interface FarmAuctionBidderConfig {
  account: string
  farmName: string
  tokenAddress: string
  quoteToken: Token
  tokenName: string
  projectSite?: string
  lpAddress?: string
}

// Note: this status is slightly different compared to 'status' config
// from Farm Auction smart contract
export enum AuctionStatus {
  ToBeAnnounced, // No specific dates/blocks to display
  Pending, // Auction is scheduled but not live yet (i.e. waiting for startBlock)
  Open, // Auction is open for bids
  Finished, // Auction end block is reached, bidding is not possible
  Closed, // Auction was closed in smart contract
}

export interface Auction {
  id: number
  status: AuctionStatus
  startBlock: number
  startDate: Date
  endBlock: number
  endDate: Date
  auctionDuration: number
  initialBidAmount: number
  topLeaderboard: number
  leaderboardThreshold: BigNumber
}

export interface BidderAuction {
  id: number
  amount: BigNumber
  claimed: boolean
}

export interface Bidder extends FarmAuctionBidderConfig {
  position?: number
  isTopPosition: boolean
  samePositionAsAbove: boolean
  amount: BigNumber
}

export interface ConnectedBidder {
  account: string
  isWhitelisted: boolean
  bidderData?: Bidder
}

export enum FetchStatus {
  Idle = 'idle',
  Fetching = 'loading',
  Fetched = 'success',
  Failed = 'error',
}
