import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import { ChainId } from '@pancakeswap/sdk'
import candyNftAbi from 'config/abi/candyNft.json'
import candyPolygonNftAbi from 'config/abi/candyPolygonNft.json'
import teendaccNftAbi from 'config/abi/teendaccNft.json'
import bingoNftAbi from 'config/abi/bingoNft.json'
import collections from 'config/constants/nfts/collections'
import { NftMintType, SerializedNftMintConfig } from 'config/constants/types'
import { publicClient } from 'utils/wagmi'
import { SerializedNftMintProps } from '../types'

const fetchSingleData = async (dataToFetch: SerializedNftMintConfig): Promise<SerializedNftMintProps> => {
  const { collection, chainId, isFinished, type, payingToken } = dataToFetch
  const client = publicClient({ chainId })

  if (chainId === ChainId.POLYGON) {
    const calls = [
      // Maximum Supply
      {
        abi: candyPolygonNftAbi,
        address: collection.address,
        functionName: 'maxSupply',
      },
      // Total minted
      {
        abi: candyPolygonNftAbi,
        address: collection.address,
        functionName: 'totalSupply',
      },
      // Minted count per cases
      {
        abi: candyPolygonNftAbi,
        address: collection.address,
        functionName: 'caseMintedCount',
      },
      // Mint Prices
      {
        abi: candyPolygonNftAbi,
        address: collection.address,
        functionName: 'mintPrices',
      },
      // Free & Sell mint start dates
      {
        abi: candyPolygonNftAbi,
        address: collection.address,
        functionName: 'mintStartDate',
      },
    ]

    const [maxSupply, totalSupply, caseMintedCounts, mintPrices, mintStartDate]
      // @ts-ignore
      = await client.multicall({ contracts: calls, allowFailure: false })

    return {
      ...dataToFetch,
      maxSupply: collection.address === collections.bccpolygon.address ? 5000 : Number(maxSupply),
      totalSupply: Number(totalSupply),
      totalWlCount: Number(caseMintedCounts[0]),
      totalPubCount: Number(caseMintedCounts[1]),
      totalFreeCount: Number(caseMintedCounts[2]), // Legendary mint count
      wlPrice: new BigNumber(mintPrices[0].toString()).toJSON(),
      pubPrice: new BigNumber(mintPrices[1].toString()).toJSON(),
      freeStartAt: 0,
      sellStartAt: Number(mintStartDate),
      mintLimit: 0,
      // isFinished: new BigNumber(maxSupply).isEqualTo(new BigNumber(totalSupply)) || isFinished,
      isFinished,
    }
  }

  if (type === NftMintType.SPECIAL) {
    const calls = [
      // Maximum Supply
      {
        abi: candyNftAbi,
        address: collection.address,
        functionName: 'maxSupply',
      },
      // Total minted
      {
        abi: candyNftAbi,
        address: collection.address,
        functionName: 'totalSupply',
      },
      // Minted count per cases
      {
        abi: candyNftAbi,
        address: collection.address,
        functionName: 'caseMintedCount',
      },
      // Mint Prices
      {
        abi: candyNftAbi,
        address: collection.address,
        functionName: 'mintPrices',
      },
      // Free & Sell mint start dates
      {
        abi: candyNftAbi,
        address: collection.address,
        functionName: 'mintDates',
      },
    ]

    const [maxSupply, totalSupply, caseMintedCounts, mintPrices, mintDates]
      // @ts-ignore
      = await client.multicall({ contracts: calls, allowFailure: false })

    return {
      ...dataToFetch,
      maxSupply: Number(maxSupply),
      totalSupply: Number(totalSupply),
      totalFreeCount: Number(caseMintedCounts[0]),
      totalWlCount: Number(caseMintedCounts[1]),
      totalPubCount: Number(caseMintedCounts[2]),
      wlPrice: new BigNumber(mintPrices[0].toString()).toJSON(),
      pubPrice: new BigNumber(mintPrices[1].toString()).toJSON(),
      freeStartAt: Number(mintDates[0]),
      sellStartAt: Number(mintDates[1]),
      mintLimit: 0,
      // isFinished: new BigNumber(maxSupply).isEqualTo(new BigNumber(totalSupply)) || isFinished,
      isFinished,
    }
  }
  if (type === NftMintType.MULTIPAY) {
    const calls = [
      // Maximum Supply
      {
        abi: teendaccNftAbi,
        address: collection.address,
        functionName: 'maxSupply',
      },
      // Total minted
      {
        abi: teendaccNftAbi,
        address: collection.address,
        functionName: 'totalSupply',
      },
      // Minted count per cases
      {
        abi: teendaccNftAbi,
        address: collection.address,
        functionName: 'caseMintedCount',
      },
      // Mint Prices
      {
        abi: teendaccNftAbi,
        address: collection.address,
        functionName: 'mintPrice',
        args: [payingToken.address, 1] // whitelisted price
      },
      {
        abi: teendaccNftAbi,
        address: collection.address,
        functionName: 'mintPrice',
        args: [ethers.constants.AddressZero, 1] // whitelisted CRO price
      },
      {
        abi: teendaccNftAbi,
        address: collection.address,
        functionName: 'mintPrice',
        args: [payingToken.address, 2] // public price
      },
      {
        abi: teendaccNftAbi,
        address: collection.address,
        functionName: 'mintPrice',
        args: [ethers.constants.AddressZero, 2] // whitelisted CRO price
      },
      // Free & Sell mint start dates
      {
        abi: teendaccNftAbi,
        address: collection.address,
        functionName: 'mintDates',
      },
    ]

    const [maxSupply, totalSupply, caseMintedCounts, wlPrice, wlNativePrice, pubPrice, pubNativePrice, mintDates]
      // @ts-ignore
      = await client.multicall({ contracts: calls, allowFailure: false })

    return {
      ...dataToFetch,
      maxSupply: Number(maxSupply),
      totalSupply: Number(totalSupply),
      totalFreeCount: Number(caseMintedCounts[0]),
      totalWlCount: Number(caseMintedCounts[1]),
      totalPubCount: Number(caseMintedCounts[2]),
      wlPrice: new BigNumber(wlPrice.toString()).toJSON(),
      pubPrice: new BigNumber(pubPrice.toString()).toJSON(),
      wlNativePrice: new BigNumber(wlNativePrice.toString()).toJSON(),
      pubNativePrice: new BigNumber(pubNativePrice.toString()).toJSON(),
      freeStartAt: Number(mintDates[0]),
      sellStartAt: Number(mintDates[1]),
      mintLimit: 0,
      // isFinished: new BigNumber(maxSupply).isEqualTo(new BigNumber(totalSupply)) || isFinished,
      isFinished,
    }
  }
  const calls = [
    // Maximum Supply
    {
      abi: bingoNftAbi,
      address: collection.address,
      functionName: 'maxSupply',
    },
    // Total minted
    {
      abi: bingoNftAbi,
      address: collection.address,
      functionName: 'totalSupply',
    },
    // Mint Price
    {
      abi: bingoNftAbi,
      address: collection.address,
      functionName: 'cost',
    },
    // Mint Limit
    {
      abi: bingoNftAbi,
      address: collection.address,
      functionName: 'maxMintAmount',
    },
  ]

  const [maxSupply, totalSupply, mintPrice, mintLimit]
    // @ts-ignore
    = await client.multicall({ contracts: calls, allowFailure: false })

  return {
    ...dataToFetch,
    maxSupply: Number(maxSupply),
    totalSupply: Number(totalSupply),
    mintLimit: Number(mintLimit),
    totalFreeCount: 0,
    totalWlCount: 0,
    totalPubCount: 0,
    wlPrice: '0',
    pubPrice: new BigNumber(mintPrice.toString()).toJSON(),
    freeStartAt: 0,
    sellStartAt: 0,
    // isFinished: new BigNumber(maxSupply).isEqualTo(new BigNumber(totalSupply)) || isFinished,
    isFinished,
  }
}

const fetchNftMintsPublicData = async (batchDataToFetch: SerializedNftMintConfig[]) => {
  const nftMintsPublicData = await Promise.all(
    batchDataToFetch.map(async (singleDataToFetch) => {
      const singleData = await fetchSingleData(singleDataToFetch)
      return singleData
    }),
  )
  return nftMintsPublicData
}

export default fetchNftMintsPublicData
