import { useWeb3React } from '@pancakeswap/wagmi'
import BigNumber from 'bignumber.js'
import { CAKE, CANDY } from '@pancakeswap/tokens'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import { ChainId } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import { Address, erc20ABI, useAccount, useBalance, useContractRead } from 'wagmi'
import { useActiveChainId } from './useActiveChainId'

const useTokenBalance = (tokenAddress: string, forceBSC?: boolean) => {
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  const { data, status, ...rest } = useContractRead({
    chainId: forceBSC ? ChainId.CRONOS : chainId,
    abi: erc20ABI,
    address: tokenAddress as Address,
    functionName: 'balanceOf',
    args: [account],
    enabled: !!account,
    watch: true,
  })

  return {
    ...rest,
    fetchStatus: status,
    balance: useMemo(() => (typeof data !== 'undefined' ? new BigNumber(data.toString()) : BIG_ZERO), [data]),
  }
}

export const useGetBnbBalance = () => {
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  const { status, refetch, data } = useBalance({
    chainId: chainId ?? ChainId.CRONOS,
    address: account,
    watch: true,
    enabled: !!account,
  })

  return { balance: data?.value ? EthersBigNumber.from(data.value) : Zero, fetchStatus: status, refresh: refetch }
}

export const useGetCakeBalance = () => {
  const { chainId } = useWeb3React()
  const { balance, fetchStatus } = useTokenBalance(CANDY[chainId]?.address || CANDY[ChainId.CRONOS]?.address)

  // TODO: Remove ethers conversion once useTokenBalance is converted to ethers.BigNumber
  return { balance: EthersBigNumber.from(balance.toString()), fetchStatus }
}

export default useTokenBalance
