import { ChainId } from '@pancakeswap/sdk'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import nftMintsConfig from 'config/constants/nfts/nftMints'
import { MerkleItem } from 'config/constants/types'
import { NftMintsState, SerializedNftMintProps } from '../types'
import fetchNftMintsPublicData from './fetchNftMintsPublicData'
import { fetchNftMintsUserData } from './fetchNftMintsUserData'

const initialState: NftMintsState = { data: [], userDataLoaded: false }

// Async thunks
export const fetchNftMintsInitialDataAsync = createAsyncThunk<SerializedNftMintProps[], ChainId>(
  'nftMints/fetchNftMintsInitialDataAsync',
  async (chainId) => {
    const initialData = nftMintsConfig.filter((config) => config.chainId === chainId)
    return initialData
  },
)


export const fetchNftMintsPublicDataAsync = createAsyncThunk<SerializedNftMintProps[], number[]>(
  'nftMints/fetchNftMintsPublicDataAsync',
  async (ids) => {
    const dataToFetch = nftMintsConfig.filter((config) => ids.includes(config.id))
    const dataFetched = await fetchNftMintsPublicData(dataToFetch)
    return dataFetched
  },
)

interface MintsUserDataResponse {
  id: number
  freeCount: number
  wlCount: number
  pubCount: number
  isEbisusBayMember: boolean
  isWhitelisted: boolean
  freeMerkle: MerkleItem
  wlMerkle: MerkleItem
  allowance: SerializedBigNumber
}

export const fetchNftMintsUserDataAsync = createAsyncThunk<
  MintsUserDataResponse[],
  { account: string; chainId: ChainId; ids: number[] }
>('nftMints/fetchNftMintsUserDataAsync',
  // @ts-ignore
  async ({ account, chainId, ids }) => {
    const dataToFetch = nftMintsConfig.filter((config) => ids.includes(config.id))
    const dataFetched = await fetchNftMintsUserData(account, chainId, dataToFetch)
    return dataFetched
  })

export const nftMintsSlice = createSlice({
  name: 'NftMints',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update collections with live data
    builder.addCase(fetchNftMintsInitialDataAsync.fulfilled, (state, action) => {
      state.data = action.payload
    })
    // Update collections with live data
    builder.addCase(fetchNftMintsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((stateData) => {
        const fetchedData = action.payload.find((dt) => dt.id === stateData.id)
        return { ...stateData, ...fetchedData }
      })
    })

    // Update farms with user data
    builder.addCase(fetchNftMintsUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { id } = userDataEl
        const index = state.data.findIndex((dt) => dt.id === id)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  },
})

export default nftMintsSlice.reducer
