import useSWR from 'swr'
import { NULL_ADDRESS } from 'config/constants'
import { useBCakeFarmBoosterContract } from 'hooks/useContract'
import { FetchStatus } from 'config/constants/types'

export const useBCakeProxyContractAddress = (account?: string) => {
  const bCakeFarmBoosterContract = useBCakeFarmBoosterContract()
  const { data, status, mutate } = useSWR(account && ['proxyAddress', account], async () =>
    bCakeFarmBoosterContract.read.proxyContract([account]),
  )

  return {
    proxyAddress: data as `0x${string}`,
    isLoading: status !== FetchStatus.Fetched,
    proxyCreated: data && data !== NULL_ADDRESS,
    refreshProxyAddress: mutate,
  }
}
