import { BigNumber } from '@ethersproject/bignumber'
import { ChainId } from '@pancakeswap/sdk'
import {  SerializedPoolConfig } from '../types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('50000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const getLivePools = async (chainId: ChainId) => {
  try {
    return (await import(`/${chainId}.tsx`)).livePools as SerializedPoolConfig[]
  } catch (error) {
    console.error('Cannot get live pools', error, chainId)
    return []
  }
}

export const getPools = async (chainId: ChainId) => {
  try {
    
    return (await import(`/${chainId}.tsx`)).default as SerializedPoolConfig[]
  } catch (error) {
    console.error('Cannot get pools', error, chainId)
    return []
  }
}

export const getVaultPoolConfig = async (chainId: ChainId) => {
  try {
    return (await import(`/${chainId}.tsx`)).vaultPoolConfig
  } catch (error) {
    console.error('Cannot get vault pool config', error, chainId)
    return []
  }
}
