import BigNumber from 'bignumber.js'
import chunk from 'lodash/chunk'
import { Address } from 'wagmi'
import nftStakingAbi from 'config/abi/nftStaking.json'
import nftStakingV2Abi from 'config/abi/nftStakingV2.json'
import nftStakingV3Abi from 'config/abi/nftStakingV3.json'
import { NFT_STAKING_TYPE, Rarity, SerializedNftStakingConfig } from 'config/constants/types'
import { publicClient } from 'utils/wagmi'
import { SerializedNftStakingProps } from '../types'

const fetchSingleData = async (dataToFetch: SerializedNftStakingConfig): Promise<SerializedNftStakingProps> => {
  const { address, chainId, type } = dataToFetch

  const rarities = Object.keys(Rarity)
  const client = publicClient({ chainId })

  // Fetch fixed candy nft staking config data
  if (type === NFT_STAKING_TYPE.FIXED) {
    const calls = rarities.slice(0, rarities.length / 2).map((rarity) => ({
      abi: nftStakingAbi,
      address,
      functionName: 'supplies',
      args: [rarity]
    })).concat(rarities.slice(0, rarities.length / 2).map((rarity) => ({
      abi: nftStakingAbi,
      address,
      functionName: 'emissions',
      args: [rarity]
    })))

    // @ts-ignore
    const callResult = await client.multicall({ contracts: calls, allowFailure: false })
    const [suppliesResult, emissionsResult] = chunk(callResult, rarities.length / 2)

    return {
      ...dataToFetch,
      // @ts-ignore
      emissions: emissionsResult.map((emission) => new BigNumber(emission.toString()).toJSON())
        .reduce((acc, obj, index) => { return { ...acc, [rarities[index]]: obj } }, {}),
      // @ts-ignore
      supplies: suppliesResult.map((supply) => Number(supply))
        .reduce((acc, obj, index) => { return { ...acc, [rarities[index]]: obj } }, {}),
      weights: {
        [Rarity.GOD]: 0,
        [Rarity.KING]: 0,
        [Rarity.PRINCE]: 0,
        [Rarity.MINISTER]: 0,
        [Rarity.CITIZEN]: 0
      },
      supply: 0,
      rewardPerDay: '0'
    }
  }

  // Fetch shared candy nft staking config data
  if (type === NFT_STAKING_TYPE.SHARED) {
    const calls = rarities.slice(0, rarities.length / 2).map((rarity) => ({
      abi: nftStakingV2Abi,
      address,
      functionName: 'supplies',
      args: [rarity]
    })).concat(rarities.slice(0, rarities.length / 2).map((rarity) => ({
      abi: nftStakingV2Abi,
      address,
      functionName: 'weights',
      args: [rarity]
    })))

    // @ts-ignore
    const callResult = await client.multicall({ contracts: calls, allowFailure: false })
    const [suppliesResult, weightsResult] = chunk(callResult, rarities.length / 2)

    const emissionResult = await client.readContract({
      abi: nftStakingV2Abi,
      address: address as Address,
      functionName: 'candyPerDay'
    })

    return {
      ...dataToFetch,
      // @ts-ignore
      weights: weightsResult.map((emission) => Number(emission))
        .reduce((acc, obj, index) => { return { ...acc, [rarities[index]]: obj } }, {}),
      // @ts-ignore
      supplies: suppliesResult.map((supply) => Number(supply))
        .reduce((acc, obj, index) => { return { ...acc, [rarities[index]]: obj } }, {}),
      emissions: {
        [Rarity.GOD]: '0',
        [Rarity.KING]: '0',
        [Rarity.PRINCE]: '0',
        [Rarity.MINISTER]: '0',
        [Rarity.CITIZEN]: '0'
      },
      supply: 0,
      rewardPerDay: new BigNumber(emissionResult.toString()).toJSON()
    }
  }

  // Fetch simple partner nft staking config data
  const calls = [{
    abi: nftStakingV3Abi,
    address,
    functionName: 'totalStaked'
  }, {
    abi: nftStakingV3Abi,
    address,
    functionName: 'emission'
  }]

  // @ts-ignore
  const callResult = await client.multicall({ contracts: calls, allowFailure: false })

  return {
    ...dataToFetch,
    weights: {
      [Rarity.GOD]: 0,
      [Rarity.KING]: 0,
      [Rarity.PRINCE]: 0,
      [Rarity.MINISTER]: 0,
      [Rarity.CITIZEN]: 0
    },
    supplies: {
      [Rarity.GOD]: 0,
      [Rarity.KING]: 0,
      [Rarity.PRINCE]: 0,
      [Rarity.MINISTER]: 0,
      [Rarity.CITIZEN]: 0
    },
    emissions: {
      [Rarity.GOD]: '0',
      [Rarity.KING]: '0',
      [Rarity.PRINCE]: '0',
      [Rarity.MINISTER]: '0',
      [Rarity.CITIZEN]: '0'
    },
    supply: Number(callResult[0]),
    rewardPerDay: new BigNumber(callResult[1].toString()).toJSON()
  }
}

const fetchNftStakingsPublicData = async (batchDataToFetch: SerializedNftStakingConfig[]) => {
  const nftStakingsPublicData = await Promise.all(
    batchDataToFetch.map(async (singleDataToFetch) => {
      const singleData = await fetchSingleData(singleDataToFetch)
      return singleData
    }),
  )
  return nftStakingsPublicData
}

export default fetchNftStakingsPublicData
