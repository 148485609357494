import { Address } from 'wagmi'
import nftAbi from 'config/abi/candyNft.json'
import { NftToken, NftCollection } from 'config/constants/types'
import RarityMerkleTree from 'config/constants/nfts/merkle/rarity-merkle.json'
import DuelsRarityMerkleTree from 'config/constants/nfts/merkle/duel-rarity-merkle.json'
import { getNftImageUrlFromEbisusBay } from 'utils/getNftImageUrl'
import { publicClient } from 'utils/wagmi'
import collections from 'config/constants/nfts/collections'

const fetchSingleData = async (account: string, dataToFetch: NftCollection)
  : Promise<{ collection: NftCollection; tokens: NftToken[] }> => {
  const { address, chainId, imgExt } = dataToFetch
  const client = publicClient({ chainId })
  const balanceResult = await client.readContract({
    abi: nftAbi,
    address: address as Address,
    functionName: 'balanceOf',
    args: [account]
  })

  const userBalance = Number(balanceResult)

  const callResult = await client.multicall({
    // @ts-ignore
    contracts: new Array<number>(userBalance).fill(0).map((value, index) => ({
      abi: nftAbi,
      address,
      functionName: 'tokenOfOwnerByIndex',
      args: [account, index]
    })),
    allowFailure: false
  })

  const tokens = await Promise.all(callResult.map((value) => new Promise((resolve) => {
    const tokenId = Number(value)
    const isDuelNftStaking = address === collections.duels.address
    const rarity = isDuelNftStaking
      // @ts-ignore
      ? DuelsRarityMerkleTree.claims?.[tokenId]?.rarity
      // @ts-ignore
      : RarityMerkleTree.claims?.[tokenId]?.rarity

    const proof = isDuelNftStaking
      // @ts-ignore
      ? DuelsRarityMerkleTree.claims?.[tokenId]?.proof
      // @ts-ignore
      : RarityMerkleTree.claims?.[tokenId]?.proof
    getNftImageUrlFromEbisusBay(address, tokenId).then((image) => {
      resolve({ id: tokenId, rarity, image, proof })
    })
  })))

  // @ts-ignore
  return { collection: dataToFetch, tokens }

}

const fetchOwnedNfts = async (account: string, batchDataToFetch: NftCollection[]) => {
  const nftsHoldData = await Promise.all(
    batchDataToFetch.map(async (singleDataToFetch) => {
      const singleData = await fetchSingleData(account, singleDataToFetch)
      return singleData
    }),
  )
  return nftsHoldData
}

export default fetchOwnedNfts
