import BigNumber from 'bignumber.js'
import { SerializedLockedVaultUser, SerializedVaultUser } from 'state/types'
import { getCakeVaultAddress } from 'utils/addressHelpers'
import cakeVaultAbi from 'config/abi/cakeVaultV2.json'
import { publicClient } from 'utils/wagmi'
import { getCakeFlexibleSideVaultV2Contract } from '../../utils/contractHelpers'

export const fetchVaultUser = async (account: string, chainId: number): Promise<SerializedLockedVaultUser> => {
  const cakeVaultAddress = getCakeVaultAddress(chainId)
  const [userContractResponse, currentPerformanceFee, currentOverdueFee]
    = await publicClient({ chainId }).multicall({
      contracts: [
        {
          // @ts-ignore
          abi: cakeVaultAbi,
          address: cakeVaultAddress,
          functionName: 'userInfo',
          args: [account],
        },
        {
          // @ts-ignore
          abi: cakeVaultAbi,
          address: cakeVaultAddress,
          functionName: 'calculatePerformanceFee',
          args: [account],
        },
        {
          // @ts-ignore
          abi: cakeVaultAbi,
          address: cakeVaultAddress,
          functionName: 'calculateOverdueFee',
          args: [account],
        },
      ],
      allowFailure: false,
    })

  return {
    isLoading: false,
    userShares: new BigNumber(userContractResponse[0].toString()).toJSON(),
    lastDepositedTime: userContractResponse[1].toString(),
    lastUserActionTime: userContractResponse[3].toString(),
    cakeAtLastUserAction: new BigNumber(userContractResponse[2].toString()).toJSON(),
    userBoostedShare: new BigNumber(userContractResponse[6].toString()).toJSON(),
    locked: userContractResponse[7],
    lockEndTime: userContractResponse[5].toString(),
    lockStartTime: userContractResponse[4].toString(),
    lockedAmount: new BigNumber(userContractResponse[8].toString()).toJSON(),
    currentPerformanceFee: new BigNumber(currentPerformanceFee.toString()).toJSON(),
    currentOverdueFee: new BigNumber(currentOverdueFee.toString()).toJSON(),
  }
  // try {

  // } catch (error) {
  //   return {
  //     isLoading: true,
  //     userShares: null,
  //     lastDepositedTime: null,
  //     lastUserActionTime: null,
  //     cakeAtLastUserAction: null,
  //     userBoostedShare: null,
  //     lockEndTime: null,
  //     lockStartTime: null,
  //     locked: null,
  //     lockedAmount: null,
  //     currentPerformanceFee: null,
  //     currentOverdueFee: null,
  //   }
  // }
}

export const fetchFlexibleSideVaultUser = async (account: string, chainId: number): Promise<SerializedVaultUser> => {
  const flexibleSideVaultContract = getCakeFlexibleSideVaultV2Contract(chainId)
  const userContractResponse = await flexibleSideVaultContract.read.userInfo([account])
  return {
    isLoading: false,
    userShares: new BigNumber(userContractResponse[0].toString()).toJSON(),
    lastDepositedTime: userContractResponse[1].toString(),
    lastUserActionTime: userContractResponse[3].toString(),
    cakeAtLastUserAction: new BigNumber(userContractResponse[2].toString()).toJSON(),
  }
  // try {

  // } catch (error) {
  //   return {
  //     isLoading: true,
  //     userShares: null,
  //     lastDepositedTime: null,
  //     lastUserActionTime: null,
  //     cakeAtLastUserAction: null,
  //   }
  // }
}
