import { ChainId } from '@pancakeswap/sdk'

const collections = {
  bcctest: {
    name: 'Bored Candy',
    symbol: 'BCC',
    description: 'Bored Candy Citizens are bringing $CANDY to every wallet in Cronos Chain',
    address: '0xBB5C789Fe4f2093e83c3A5c9588D04FF4c55046d',
    chainId: ChainId.CRONOS_TESTNET,
    imgExt: '.png'
  },
  bcc: {
    name: 'Bored Candy',
    symbol: 'BCC',
    description: 'Bored Candy Citizens are bringing $CANDY to every wallet in Cronos Chain',
    address: '0xE1049178296ce004996AFb16B0816c5A95aC8482',
    chainId: ChainId.CRONOS,
    imgExt: '.png'
  },
  bccpolygon: {
    name: 'Bored Candy',
    symbol: 'BCC',
    description: '10k Bored Candy Citizens PFP NFTs coming from Cronos to Polygon packed with utilities.',
    address: '0x74Aaee9Dd32d4836534328A31331d64262791DF6',
    chainId: ChainId.POLYGON,
    imgExt: '.png'
  },
  rbl: {
    name: 'Rebel Punk',
    symbol: 'RBL',
    description: 'Rebel Punks is a collection of 5000 randomly generated and artistically hand drawn NFTs, created by Metawarz Shooter. Your Rebel serve as a membership card, and grants you access to members-only benefits. Including but not limited to: Metawarz private token sale, Mystery Box, NFT drops, in-game asset raffles and more. Remember some Punks are cool, some are novel but some are just legendary. So snatch them up, Play, Earn and Proudly lead the rebellious life forevermore.',
    address: '0xc7E77C602D549747AB33C2F0137Cbcb42eeF2bB8',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  croladiesvip: {
    name: 'CrofamLadiesVIP',
    symbol: 'CROLADIESVIP',
    description: 'Crofam Ladies VIP is a collection consisting of 1660NFT. VIP collection from the Crofam Ladies Brand.',
    address: '0x5286c663e03A2e4c155f6CFe595D61E07f136A34',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  tdog: {
    name: 'TiltedDabbersOG',
    symbol: 'TDOG',
    description: 'Blockchain Bingo Balls Tilted Dabbers OG',
    address: '0x8C7292ba9c36C1ad037Adddb257728578C5C8fc0',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  bingo: {
    name: 'BingoNFT',
    symbol: 'BNFT',
    description: 'Fun NFT game, big prizes',
    address: '0x51d0594d1e0975E2e631ea1d8b14da1371689E5c',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  teendacc: {
    name: 'TeenDACC',
    symbol: 'TeenDACC',
    description: 'Supply and Demand style investment using a buy-back-and-burn process.',
    address: '0x25EA0f16E3a52db95E31F8514917a0610270e7CD',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  cvln: {
    name: 'Crovilian',
    symbol: 'CVLN',
    description: '',
    address: '0x130a6c2884325d302830179C691D328dDAd9b78C',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  scpepe: {
    name: 'SpicyCroPepe',
    symbol: 'SCPEPE',
    description: 'Get your SpicyCroPepe king and start your CroPepeLand adventure!',
    address: '0xbCC5101564a626CE9FC7FF8287573d42bCe66f97',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  wapz: {
    name: 'Wicked Azz Pawnz',
    symbol: 'WAPZ',
    description: 'Anything but your average PAWNZ!',
    address: '0x0bA3D2BE7977075CbDAE40ccBBf73c4FEbfdcb4A',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  cropepe: {
    name: 'ShinyCroPepe',
    symbol: 'SHPEPE',
    description: 'The ShinyCroPepe collection has 350 pieces. Feel the power of shiny!',
    address: '0x3ac8d48DdF2C09460F435206BDC7BA04F2560114',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  crodoge: {
    name: 'CroDoges',
    symbol: 'CRODOGE',
    description: 'Cronos Rescue Mission',
    address: '0xe7f4b4c26912dbB5764353eBD8D6aCD56A5B7435',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  bzz: {
    name: 'Crosquitos',
    symbol: 'BZZ',
    description: '',
    address: '0xd653957EC84608fd9e74A3D6B1009EF1CE73313e',
    chainId: ChainId.CRONOS,
    imgExt: ''
  },
  banshees: {
    name: 'Banshees',
    symbol: 'banshees',
    description: '',
    address: '0xBb8Bb9712A63B0Df0C6Ba16Ed14E97AE5b85e03d',
    chainId: ChainId.POLYGON,
    imgExt: ''
  },
  // duels: {
  //   name: 'CronosDuels',
  //   symbol: 'DUELS',
  //   description: 'The Dynamic NFT Card Game that Allows the Integration of Cronos NFTs. Staking Rewards, a Dynamic Ranking System, Online Matchmaking against other Card Holders, and much more. Integrate your NFT with a CronosDuels Card, Build Your Deck and Level Up your NFT Experience.',
  //   address: '0x17047708f8882dD92Cad6dE9ae288Ea2d795B008',
  //   chainId: ChainId.CRONOS,
  //   imgExt: '.png'
  // },
  duels: {
    name: 'CronosDuels Barracks',
    symbol: 'CDB',
    description: 'The Dynamic NFT Card Game that Allows the Integration of Cronos NFTs. Staking Rewards, a Dynamic Ranking System, Online Matchmaking against other Card Holders, and much more. Integrate your NFT with a CronosDuels Card, Build Your Deck and Level Up your NFT Experience.',
    address: '0x3B74781C407541ac9837920b5c15e1CdeF98C46A',
    chainId: ChainId.CRONOS,
    imgExt: '.png'
  },
}

export default collections
