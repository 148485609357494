import { ChainId } from '@pancakeswap/sdk'
import { cronos as cronos_, cronosTestnet, polygon, pulsechain } from 'wagmi/chains'
import memoize from 'lodash/memoize'
import invert from 'lodash/invert'
import { PUBLIC_NODES } from './nodes'

export const CHAIN_QUERY_NAME: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
  [ChainId.CRONOS]: 'cronos',
  [ChainId.CRONOS_TESTNET]: 'cronosTestnet',
  [ChainId.POLYGON]: 'polygon',
}

const cronos = {
  ...cronos_,
  rpcUrls: {
    default: {
      http: [...PUBLIC_NODES[cronos_.id]]
    },
    public: {
      http: [...PUBLIC_NODES[cronos_.id]]
    }
  }
}

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName] ? +CHAIN_QUERY_NAME_TO_ID[chainName] : undefined
})

// const CHAINS = [bsc, mainnet, bscTestnet, goerli, pulse]
export const CHAINS = [cronos, polygon, cronosTestnet]
