import BigNumber from 'bignumber.js'
import nftVaultAbi from 'config/abi/nftVault.json'
import { Rarity } from 'config/constants/types'
import { getNftVaultAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { publicClient } from 'utils/wagmi'
import { BOOST_PRECISION } from './constants'

const fetchSingleData = async (chainId: number, farmId: number): Promise<{ [rarity in Rarity]: number }> => {
  const nftVaultAddress = getNftVaultAddress(chainId)
  const rarities = Object.keys(Rarity)
  const client = publicClient({ chainId })

  const calls = rarities.slice(0, rarities.length / 2).map((rarity) => ({
    abi: nftVaultAbi,
    address: nftVaultAddress,
    functionName: 'supplies',
    args: [farmId, rarity]
  }))

  // @ts-ignore
  const callResult = await client.multicall({ contracts: calls, allowFailure: false })
  // @ts-ignore
  return callResult.map((supply) => Number(supply))
    .reduce((acc, obj, index) => { return { ...acc, [rarities[index]]: obj } }, {})
}

const fetchNftVaultsPublicData = async (chainId: number, farmIds: number[]) => {
  const nftVaultsPublicData = await Promise.all(
    farmIds.map(async (farmId) => {
      const singleData = await fetchSingleData(chainId, farmId)
      return singleData
    }),
  )
  return nftVaultsPublicData
}

export const fetchRarityBoosts = async (chainId: number) => {
  const rarities = Object.keys(Rarity)
  const nftVaultAddress = getNftVaultAddress(chainId)
  const client = publicClient({ chainId })

  const calls = rarities.slice(0, rarities.length / 2).map((rarity) => ({
    abi: nftVaultAbi,
    address: nftVaultAddress,
    functionName: 'rarityBoosts',
    args: [rarity]
  }))

  // @ts-ignore
  const callsResult = await client.multicall({ contracts: calls, allowFailure: false })

  return callsResult
    .map((boost) => new BigNumber(boost.toString()))
    .reduce((acc, obj, index) => { return { ...acc, [rarities[index]]: getBalanceNumber(obj, BOOST_PRECISION) } }, {})
}

export const fetchMaxPerPool = async (chainId: number) => {
  const nftVaultAddress = getNftVaultAddress(chainId)
  const client = publicClient({ chainId })
  const callResult = await client.readContract({
    abi: nftVaultAbi,
    address: nftVaultAddress,
    functionName: 'maxPerPool'
  })

  return Number(callResult)
}

export default fetchNftVaultsPublicData
