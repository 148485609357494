import BigNumber from 'bignumber.js'
import { NftToken } from 'config/constants/types'
import { SerializedFarm, SerializedPartnerFarm, DeserializedPartnerFarm } from 'state/types'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'

type UserData =
  | DeserializedPartnerFarm['userData']
  | {
    tokenAllowance: number | string
    nftAllowed: boolean
    holdingTokens: number | string
    stakedTokens: number | string
    holdingNfts: NftToken[]
    stakedNfts: NftToken[]
    pendingReward: number | string
  }

export const transformUserData = (userData: UserData) => {
  return {
    tokenAllowance: userData ? new BigNumber(userData.tokenAllowance) : BIG_ZERO,
    nftAllowed: userData ? userData.nftAllowed : false,
    holdingTokens: userData ? new BigNumber(userData.holdingTokens) : BIG_ZERO,
    stakedTokens: userData ? new BigNumber(userData.stakedTokens) : BIG_ZERO,
    holdingNfts: userData ? userData.holdingNfts : [],
    stakedNfts: userData ? userData.stakedNfts : [],
    pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
  }
}

export const transformPartnerFarm = (partnerFarm: SerializedPartnerFarm): DeserializedPartnerFarm => {
  const {
    totalBoostedShare,
    totalStakedToken,
    tokenStakingLimit,
    userData,
    stakingToken,
    earningToken,
    ...rest
  } = partnerFarm

  return {
    ...rest,
    stakingToken: deserializeToken(stakingToken),
    earningToken: deserializeToken(earningToken),
    userData: transformUserData(userData),
    totalBoostedShare: new BigNumber(totalBoostedShare),
    totalStakedToken: new BigNumber(totalStakedToken),
    tokenStakingLimit: new BigNumber(tokenStakingLimit),
  }
}

export const getTokenPricesFromFarm = (farms: SerializedFarm[]) => {
  return farms.reduce((prices, farm) => {
    const quoteTokenAddress = farm.quoteToken.address.toLowerCase()
    const tokenAddress = farm.token.address.toLowerCase()
    /* eslint-disable no-param-reassign */
    if (!prices[quoteTokenAddress]) {
      prices[quoteTokenAddress] = new BigNumber(farm.quoteTokenPriceBusd).toNumber()
    }
    if (!prices[tokenAddress]) {
      prices[tokenAddress] = new BigNumber(farm.tokenPriceBusd).toNumber()
    }
    /* eslint-enable no-param-reassign */
    return prices
  }, {})
}

export const getLPTokenPrice = (farm) => {
  let lpTokenPrice = BIG_ZERO
  if (farm) {
    // const lpTotalInQuoteToken = farm.lpTotalInQuoteToken ? new BigNumber(farm.lpTotalInQuoteToken) : BIG_ZERO
    const lpTotalSupply = farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO

    // if (lpTotalSupply.gt(0) && lpTotalInQuoteToken.gt(0)) {
    if (lpTotalSupply.gt(0)) {
      const farmTokenPriceInUsd = new BigNumber(farm.tokenPriceBusd)
      const tokenAmountTotal = farm.tokenAmountTotal ? new BigNumber(farm.tokenAmountTotal) : BIG_ZERO
      // Total value of base token in LP
      const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(tokenAmountTotal)
      // Double it to get overall value in LP
      const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
      // Divide total value of all tokens, by the number of LP tokens
      const totalLpTokens = getBalanceAmount(lpTotalSupply)
      lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
    }
  }

  return lpTokenPrice
}