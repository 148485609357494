export default {
  masterChef: {
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    5: '0x8CB958bBdb45597cc918147469eb650A9397aBDA',
    25: '0xDAf7c0e2882818b46c36AdBCe95399821Eca08F8',
    338: '0xb71B5466511C9b188928F2f0FF59811E9efe0315',
    137: '0x731540530fE44161d4180DA483B245cD0AB25b1c'
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    25: ''
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
    25: ''
  },
  king: {
    97: '',
    56: '',
    // 25: '0x009Cb5c689242Cc1BF303a5370177C59CbFEf988'
    25: '0xD456B92e273742daa24b50401C2708999f4EB7f7'
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
    25: ''
  },
  multiCall: {
    1: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    4: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    5: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    25: '0x5e954f5972EC6BFc7dECd75779F10d848230345F',
    338: '0xeFd6fF0C15609cA547C57e604AD068E1CAaf6F81',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    137: '0x4499487181455E46Fc4592a59a93508bd8dB8A6e',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    25: ''
  },
  pancakeBunnies: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    25: ''
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    25: ''
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
    25: ''
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    25: ''
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    25: ''
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    25: ''
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
    25: ''
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
    25: ''
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
    25: ''
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    25: ''
  },
  cakeVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    25: '0x8FEf43b1f3046F8f58A76c64aD01Bc8d82ff0ad1',
    338: '0x1f9A42625A44290eBA52CB82E6d1509D27c891fD',
    137: '0x17C65D311b607F685b06c86e34f5EA0Cd6F22Fc2'
  },
  cakeFlexibleSideVault: {
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    97: '',
    25: '0x24B5F7A9dC76F0Cefd2629087C56A996198A2529',
    338: '0xA3517109FB9FF8f828E4314fcB00079450f69A4b',
    137: '0xCa207941946218126BD7BBe44C5d457753490b4A'
  },
  predictionsBNB: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
    25: ''
  },
  predictionsCAKE: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '',
    25: ''
  },
  chainlinkOracleBNB: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
    25: ''
  },
  chainlinkOracleCAKE: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '',
    25: ''
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '',
    25: ''
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
    25: ''
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
    25: ''
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    25: ''
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
    25: ''
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    25: ''
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    25: ''
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    25: ''
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    25: ''
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    25: ''
  },
  potteryDraw: {
    56: '0x01871991587d5671f3A2d4E2BcDC22F4E026396e',
    97: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
    25: ''
  },
  // TODO: multi
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    25: '0xB25297d357D797192e926C2e19d76FF3A664158a',
    338: "0x0c8aFa9177Db3965Af0aAa24f0D67824e0AeECE4",
    137: '0xbd673C4166d9bdCA2322049F0ca98FFAb9d270a1'
  },
  iCake: {
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
    25: ''
  },
  referral: {
    56: '',
    97: '',
    25: '0x9bd34a0eD30d4b4aB5eb5D73051D8c6DeB28008F',
    338: '0x14De1dA094C729c46923FDb10B3099b6C4851cB5'
  },
  partnerLock: {
    56: '',
    97: '',
    25: '0x0c6e5b5179eb6170b0DCA032f8d40F3FDF3aD79d',
    338: '',
    137: '0x1E47BbE8D9d6a995118ad40Cb366f5AFb3bf260B',
  },
  merkleVesting: {
    56: '',
    97: '',
    25: '0xA4d327BCe56A6Ae265D001bDdCb47de4863A2d21',
    338: '0xd0A565a42D3E7127FB88FF80EA149964384FCF7d'
  },
  partnerVesting: {
    56: '',
    97: '',
    25: '0x0FC45A76161584490c4727D090429d91aC5c1A14',
    338: '',
    137: '0xbA79203AaC4262e302Dd4d13a3f9f247c2894a9b'
  },
  candyLock: {
    56: '',
    97: '',
    25: '0x7CeA583ea310b3A8a72Ed42B3364aff16d24B3A2',
    338: ''
  },
  candyLockV2: {
    56: '',
    97: '',
    25: '0x094EcA6E834Fbe726f8b7670267244dC3983be73',
    338: ''
  },
  candyLockV3: {
    56: '',
    97: '',
    25: '0xbAEe5f69927B995478b05c400E15755217804118',
    338: ''
  },
  tokenFactory: {
    56: '',
    97: '',
    25: '0xeDf0CFCd225d53428AfCba03b70f3F5673F5AeA2',
    137: '0x31469A6f1052698c61813ad10Ce6038d17c759E7',
    338: ''
  },
  bCakeFarmBooster: {
    56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    97: '',
    25: '0x6156927a3B4b6A900AD905EF9c5Fe4196766a772',
    338: '0x3FeE164919Ec6138b3127311Ff49f2Dae206D8EF'
  },
  bCakeFarmBoosterProxyFactory: {
    56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    97: '',
    25: '0x6cfABd1Ff774453BF125a29AA9258D1B18B38ebf',
    338: '0x8EaC7fE03d5229BFc5eF99C223C999A5DEC6bB30'
  },
  nftVault: {
    56: '',
    97: '',
    25: '0xA3517109FB9FF8f828E4314fcB00079450f69A4b',
    338: ''
  },
  lockVaults: {
    338: "0xe1A8f68F6d887c49271c1848541af7647b0c6B1e",
    56: "",
    97: "",
    25: "0x427f1230A547566a51F5Ffd5698BB65c06acA2D2"
  }
}
