import chunk from 'lodash/chunk'
import { ChainId } from '@pancakeswap/sdk'
import { Address, erc20ABI } from 'wagmi'
import { getMasterChefAddress } from 'utils/addressHelpers'
import { publicClient } from 'utils/wagmi'
import { SerializedFarm } from '../types'
import { SerializedFarmConfig } from '../../config/constants/types'


const fetchFarmCalls = (farm: SerializedFarm, chainId: number) => {
  const { lpAddress, token, quoteToken } = farm
  return [
    // Balance of token in the LP contract
    {
      abi: erc20ABI,
      address: token.address as Address,
      functionName: 'balanceOf',
      args: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      abi: erc20ABI,
      address: quoteToken.address as Address,
      functionName: 'balanceOf',
      args: [lpAddress],
    },
    // Balance of LP tokens in the master chef contract
    {
      abi: erc20ABI,
      address: lpAddress as Address,
      functionName: 'balanceOf',
      args: [getMasterChefAddress(chainId)],
    },
    // Total supply of LP tokens
    {
      abi: erc20ABI,
      address: lpAddress as Address,
      functionName: 'totalSupply',
    },
    // Token decimals
    {
      abi: erc20ABI,
      address: token.address as Address,
      functionName: 'decimals',
    },
    // Quote token decimals
    {
      abi: erc20ABI,
      address: quoteToken.address as Address,
      functionName: 'decimals',
    },
  ]
}

export const fetchPublicFarmsData = async (
  farms: SerializedFarmConfig[],
  chainId = ChainId.CRONOS
): Promise<any[]> => {
  const farmCalls = farms.flatMap((farm) => fetchFarmCalls(farm, chainId))
  const chunkSize = farmCalls.length / farms.length
  const client = publicClient({ chainId })
  const farmMultiCallResult = await client.multicall({
    contracts: farmCalls,
    allowFailure: false
  })

  return chunk(farmMultiCallResult, chunkSize)
}
