import { ChainId, Token } from '@pancakeswap/sdk'
import collections from 'config/constants/nfts/collections'

const CANDY_IMAGES_1 = 'https://candy.mypinata.cloud/ipfs/QmWeAUn3gF7QEPTN6SPxAwPmtEnRrmXvCsryNYWn4y2xH1' // 1 - 2500
const CANDY_IMAGES_2 = 'https://candy.mypinata.cloud/ipfs/QmSLWNaxdLCbAFYQFtamZrraz1StkyZjq1YCtE26uBmz3X' // 2501 - 5000
const CANDY_IMAGES_3 = 'https://candy.mypinata.cloud/ipfs/QmVkbWCoGf4hSV8sUSwnpVUKjaPQXznNuSU5PJopG7aMwe' // 2501 - 7500
const CANDY_IMAGES_4 = 'https://candy.mypinata.cloud/ipfs/QmVxZUZSm8GB3ZYQJDNEnao6Kf144PxJW3f5sb3exPWWaS' // 7501 - 10000

/**
 * Get Bored Candy NFT token image from token id, hard-coded from the pinata uploaded data
 * @param tokenId 
 * @param imgExt 
 * @returns 
 */
const getBoredCandyNftImageUrl = (tokenId: number, imgExt = '.png') => {
  let image = `${CANDY_IMAGES_4}/${tokenId}${imgExt}`
  if (tokenId <= 2500) {
    image = `${CANDY_IMAGES_1}/${tokenId}${imgExt}`;
  } else if (tokenId <= 5000) {
    image = `${CANDY_IMAGES_2}/${tokenId}${imgExt}`;
  } else if (tokenId <= 7500) {
    image = `${CANDY_IMAGES_3}/${tokenId}${imgExt}`;
  }
  return image
}

/**
 * Get nft token image data from EbisusBay CDN
 * @param collection 
 * @param tokenId 
 * @returns 
 */
export const getNftImageUrlFromEbisusBay = async (collection: string, tokenId: number) => {
  // Bored Candy NFT
  if (collection === collections.bcc.address) {
    if (tokenId <= 1000)
      // return `https://cdn.ebisusbay.com/QmatNiKmnxSYnK3W6G1fQTMUjhBxwstz7ddxgP6DSRdBiC/${tokenId}.png`
      return `https://ik-proxy.b-cdn.net/QmatNiKmnxSYnK3W6G1fQTMUjhBxwstz7ddxgP6DSRdBiC/${tokenId}.png`
    if (tokenId <= 2000)
      return `https://ik-proxy.b-cdn.net/QmapnkVayNUsRknoZiiz8Gb87GrUcMW7pwiDW2YfKgNRWj/${tokenId}.png`
    if (tokenId <= 4000)
      return `https://ik-proxy.b-cdn.net/QmWzzomZw8jEBzCjhrtX4LdFR5ZMwSqdSVUqvP17wBGJL4/${tokenId}.png`
    if (tokenId <= 6000)
      return `https://ik-proxy.b-cdn.net/QmNUfeDudooPTZURgMsXH4Enm7nVPXETDPsvT4ecAD2PV3/${tokenId}.png`
    if (tokenId <= 8000)
      return `https://ik-proxy.b-cdn.net/QmbFSAdySw69Jvu86txLgkwXdwBQyiHgLiHbBZiAjDTiUn/${tokenId}.png`
    return `https://ik-proxy.b-cdn.net/QmWNVjJ5AY2VKp3iHicBiMBmaxZ1zaW5jAjqD6CwbEhR6y/${tokenId}.png`
  }
  // Rebel Punk NFT
  if (collection === collections.rbl.address) {
    const response = await fetch(`https://ipfs.io/ipfs/Qmb7Tm2dYq7qKDbrPxUUNMeQG3wevs5kCJcj7iLVefChaf/${tokenId}.json`)
    const apiResult = await response.json()
    const rawIpfsImage = apiResult.image
    // return `https://cdn.ebisusbay.com/${rawIpfsImage.replace('ipfs://', '')}`
    return `https://ik-proxy.b-cdn.net/${rawIpfsImage.replace('ipfs://', '')}`
  }
  // CROLADIESVIP NFT
  if (collection === collections.croladiesvip.address) {
    // return `https://cdn.ebisusbay.com/bafybeibej466xukv5lfckenissm7ruo5t732gx3ealcqvaafzvb3zvg62u/${tokenId}.png`
    return `https://ik-proxy.b-cdn.net/bafybeibej466xukv5lfckenissm7ruo5t732gx3ealcqvaafzvb3zvg62u/${tokenId}.png`
  }
  // TDOG NFT
  if (collection === collections.tdog.address) {
    // return `https://cdn.ebisusbay.com/QmPcW6QYe6Pw134uwP3pTEA3WWNagSezVfxpdnE7pAeCFw/OG${tokenId}.png`
    return `https://ik-proxy.b-cdn.net/QmPcW6QYe6Pw134uwP3pTEA3WWNagSezVfxpdnE7pAeCFw/OG${tokenId}.png`
  }
  // Bingo NFT
  if (collection === collections.bingo.address) {
    return `https://candy.mypinata.cloud/ipfs/QmXHqiTkKACnWUF77dYCUgCy6K2bxsKFXmANk1y28hEiie/${tokenId}.png`
  }
  // TeenDACC NFT
  if (collection === collections.teendacc.address) {
    return `https://nftstorage.link/ipfs/bafybeiag46izyffwe4r4dg6rjdlg6k2cl73nhl3owvuos7sbrtuwnpfjzi/${tokenId}.png`
  }
  // Crovilian NFT
  if (collection === collections.cvln.address) {
    return `https://cdn.ltsglxy.network/ipfs/QmdgKwYb8iE7KsXUe8vH1t2ZmpDFyWsM9wRnK6toT8AFdb/${tokenId}.png`
  }
  // SpicyCroPepe NFT
  if (collection === collections.scpepe.address) {
    return `https://cdn.ltsglxy.network/ipfs/QmUZCuHb5C457We4qyLmejfNCGa8joHYVYdBcjkhuwkwGz/SpicyCroPepeImages/${tokenId}.png`
  }
  // Wicked Azz Pawnz NFT
  if (collection === collections.wapz.address) {
    return `https://ik-proxy.b-cdn.net/proxy/https://corgistudio.fra1.digitaloceanspaces.com/l_324aa958-45cf-48cf-9937-7fd54034a525/image/${tokenId}.png`
  }
  if (collection === collections.cropepe.address) {
    return `https://cdn.ltsglxy.network/ipfs/Qmf5uEAEdRQNsmKaqGGhuDZw9zq6gGAVAn1Xgcsh3T2xD3/ShinyCroPepeImg/${tokenId}.png`
  }
  if (collection === collections.crodoge.address) {
    return `https://cdn.ltsglxy.network/ipfs/QmTcC1Wi28rnkehTnBJqLcbh2LDMEQaJQMp7hEA2YU6pN9/${tokenId}.png`
  }
  if (collection === collections.cvln.address) {
    return `https://cdn.ltsglxy.network/ipfs/QmdgKwYb8iE7KsXUe8vH1t2ZmpDFyWsM9wRnK6toT8AFdb/${tokenId}.png`
  }
  if (collection === collections.bzz.address) {
    return 'https://ipfs.io/ipfs/QmVmv4eCY7CrmkFdbJzybnmQM5guzERbahM6xwxqUJ5nyA/hidden.png'
  }
  if (collection === collections.banshees.address) {
    return `https://bafybeibcanqsuimzuyqrqw6ojedmiputkk7rdoa5fvts4okalsiy22qd7u.ipfs.nftstorage.link/${tokenId}.png?ext=png`
  }
  if (collection === collections.duels.address) {
    if (tokenId <= 500) return "https://nftstorage.link/ipfs/bafybeieo7meqa2q7eutq47matfqwiqjou4b2ph2yce25gedocy53eignqu/onslaught.png"
    if (tokenId <= 1250) return "https://nftstorage.link/ipfs/bafybeieo7meqa2q7eutq47matfqwiqjou4b2ph2yce25gedocy53eignqu/warlord.png"
    if (tokenId <= 2250) return "https://nftstorage.link/ipfs/bafybeieo7meqa2q7eutq47matfqwiqjou4b2ph2yce25gedocy53eignqu/battleborn.png"
    if (tokenId <= 3500) return "https://nftstorage.link/ipfs/bafybeieo7meqa2q7eutq47matfqwiqjou4b2ph2yce25gedocy53eignqu/duelist.png"
    return "https://nftstorage.link/ipfs/bafybeieo7meqa2q7eutq47matfqwiqjou4b2ph2yce25gedocy53eignqu/brass.png"
    // return 'https://cdn-prod.ebisusbay.com/img/nft-placeholder.webp'
  }

  return ''
}

export default getBoredCandyNftImageUrl
