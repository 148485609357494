import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  PrizeIcon,
  ResourcesIcon,
  ResourcesFilledIcon,
} from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import {
  SUPPORT_FARMS,
  SUPPORT_LAUNCHPAD,
  SUPPORT_NFTS,
  SUPPORT_LOCK,
  SUPPORT_POOLS,
  SUPPORT_VESTING,
  SUPPORT_PARTNER_FARMS,
  SUPPORT_VAULTS
} from 'config/constants/supportChains'
import { TRANSAK_API_KEY } from 'config/constants/endpoints'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId, account) => {
  if (item.label === 'Buy CRO') {
    if (!account) {
      return {
        ...item,
        disabled: true
      }
    }
    return item
  }
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (t: ContextApi['t'], isDark: boolean, languageCode?: string, account?: string, chainId?: number) => ConfigMenuItemsType[] =
  (t, isDark, languageCode, account, chainId) =>
    [
      {
        label: t('Trade'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: true,
        items: [
          {
            label: t('Swap'),
            href: '/swap',
          },
          // {
          //   label: t('Limit'),
          //   href: '/limit-orders',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/decorations/3d-coin.png',
          // },
          {
            label: t('Liquidity'),
            href: '/liquidity',
          },
          {
            label: chainId === ChainId.POLYGON ? t('Buy MATIC') : t('Buy CRO'),
            href: account 
            ? `https://global.transak.com/?apiKey=${TRANSAK_API_KEY}&cryptoCurrencyCode=${chainId === ChainId.POLYGON ? 'MATIC' : 'CRO'}&isAutoFillUserData=true&walletAddress=${account}` 
            : "",
            type: DropdownMenuItemType.EXTERNAL_LINK
          },
          // {
          //   label: t('Perpetual'),
          //   href: `https://perp.pancakeswap.finance/${perpLangMap(languageCode)}/futures/BTCUSDT?theme=${perpTheme(
          //     isDark,
          //   )}`,
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          // {
          //   label: t('Bridge'),
          //   href: 'https://cronos.org/bridge/',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          //   supportedChainIds: SUPPORT_ONLY_CRONOS
          // },
        ].map((item) => addMenuItemSupported(item, chainId, account)),
      },
      {
        label: t('Earn'),
        href: '/farms',
        icon: EarnIcon,
        fillIcon: EarnFillIcon,
        image: '/images/decorations/pe2.png',
        items: [
          {
            label: t('Farms'),
            href: '/farms',
            supportChainIds: SUPPORT_FARMS,
          },
          {
            label: t('Partner Farms'),
            href: '/farms/partners',
            supportChainIds: SUPPORT_PARTNER_FARMS,
          },
          {
            label: t('Stakings'),
            href: '/stakings',
            supportChainIds: SUPPORT_FARMS,
          },
          {
            label: t('Pools'),
            href: '/pools',
            supportChainIds: SUPPORT_POOLS,
          },
          {
            label: t('Vault'),
            href: '/vaults',
            supportChainIds: SUPPORT_VAULTS,
          },
          {
            label: t('Candy Lock'),
            href: '/candylock',
            supportChainIds: SUPPORT_LOCK,
          },
          {
            label: t('Candy Lock V2'),
            href: '/candylock/v2',
            supportChainIds: SUPPORT_LOCK,
          },
          {
            label: t('Candy Lock V3'),
            href: '/candylock/v3',
            supportChainIds: SUPPORT_LOCK,
          },
          // {
          //   label: t('Vesting'),
          //   href: '/vestings',
          //   supportChainIds: SUPPORT_VESTING,
          // },
          {
            label: t('Referral'),
            href: '/referral',
          }
        ].map((item) => addMenuItemSupported(item, chainId, account)),
      },
      {
        label: t('NFT'),
        href: '/nfts/owned',
        icon: NftIcon,
        fillIcon: NftFillIcon,
        supportChainIds: SUPPORT_NFTS,
        image: '/images/decorations/nft.png',
        items: [
          {
            label: t('Mint'),
            href: '/nfts/mint',
          },
          {
            label: t('Fixed Staking'),
            href: '/nfts/staking/fixed',
          },
          {
            label: t('Shared Staking'),
            href: '/nfts/staking/shared',
          },
          {
            label: t('Partner Staking'),
            href: '/nfts/staking/partner',
          },
          {
            label: t('Owned'),
            href: '/nfts/owned',
          },
          // {
          //   label: t('Marketplace'),
          //   href: 'https://www.peppermintcro.com/',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
        ],
      },
      {
        label: t('Launchpad'),
        href: '/launchpad',
        icon: TrophyIcon,
        fillIcon: TrophyFillIcon,
        supportChainIds: SUPPORT_LAUNCHPAD,
        // showItemsOnMobile: false,
        items: [
          {
            label: t('Launchpad'),
            href: '/launchpad'
          },
          // {
          //   label: t('$HITCOIN ICO'),
          //   href: '/launchpad/hitcoin'
          // },
          {
            label: t('Partner Lock'),
            href: '/launchpad/lock'
          },
          {
            label: t('Partner Vesting'),
            href: '/launchpad/vesting'
          },
          {
            label: t('Tokenpad'),
            href: '/launchpad/tokenpad'
          },
        ],
      },
      {
        label: t('Games'),
        href: '#',
        icon: ResourcesIcon,
        fillIcon: ResourcesFilledIcon,
        showItemsOnMobile: true,
        hideSubNav: true,
        items: [
          // {
          //   label: t('Candy Flow Web3'),
          //   href: 'https://games.candycity.finance',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          {
            label: t('Candy City iOS'),
            href: 'https://apps.apple.com/ch/app/bored-candy-city/id6444065135?l=en',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Candy City Android'),
            href: 'https://play.google.com/store/apps/details?id=com.TheBoredCandy.CandyCity',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Candy Melons iOS'),
            href: 'https://apps.apple.com/app/id6477691983',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Candy Melons Android'),
            href: 'https://play.google.com/store/apps/details?id=com.TheBoredCandy.CandyWatermelon&pcampaignid=web_share',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          // {
          //   label: t('Mobile Game'),
          //   href: 'https://mobile.candycity.finance',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          {
            label: t('Deposit Candy to mobile game'),
            href: 'https://app.alturanft.com/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          // {
          //   label: t('Candy Pool'),
          //   href: 'https://candypool.candycity.finance/',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          // {
          //   label: t('Candy Hunt'),
          //   href: '/hunt'
          // },
        ],
      },
      // {
      //   label: t('Win'),
      //   href: '/prediction',
      //   icon: TrophyIcon,
      //   fillIcon: TrophyFillIcon,
      //   supportChainIds: SUPPORT_ONLY_BSC,
      //   items: [
      //     {
      //       label: t('Trading Competition'),
      //       href: '/competition',
      //       image: '/images/decorations/tc.png',
      //       hideSubNav: true,
      //     },
      //     {
      //       label: t('Prediction (BETA)'),
      //       href: '/prediction',
      //       image: '/images/decorations/prediction.png',
      //     },
      //     {
      //       label: t('Lottery'),
      //       href: '/lottery',
      //       image: '/images/decorations/lottery.png',
      //     },
      //     {
      //       label: t('Pottery (BETA)'),
      //       href: '/pottery',
      //       image: '/images/decorations/lottery.png',
      //     },
      //   ],
      // },
      // {
      //   label: t('NFT'),
      //   href: `${nftsBaseUrl}`,
      //   icon: NftIcon,
      //   fillIcon: NftFillIcon,
      //   supportChainIds: SUPPORT_ONLY_BSC,
      //   image: '/images/decorations/nft.png',
      //   items: [
      //     {
      //       label: t('Overview'),
      //       href: `${nftsBaseUrl}`,
      //     },
      //     {
      //       label: t('Collections'),
      //       href: `${nftsBaseUrl}/collections`,
      //     },
      //     {
      //       label: t('Activity'),
      //       href: `${nftsBaseUrl}/activity`,
      //     },
      //   ],
      // },
      {
        label: '',
        href: '#',
        icon: MoreIcon,
        hideSubNav: true,
        items: [
          // {
          //   label: t('Info'),
          //   href: '/info',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          // },
          // {
          //   label: t('IFO'),
          //   href: '/ifo',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/ifos/ifo-bunny.png',
          // },
          // {
          //   label: t('Voting'),
          //   href: '/voting',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/voting/voting-bunny.png',
          // },
          // {
          //   type: DropdownMenuItemType.DIVIDER,
          // },
          // {
          //   label: t('Leaderboard'),
          //   href: '/teams',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/decorations/leaderboard.png',
          // },
          // {
          //   label: t('Roadmap'),
          //   href: '/#roadmap',
          //   image: '/images/decorations/leaderboard.png',
          // },
          // {
          //   type: DropdownMenuItemType.DIVIDER,
          // },
          {
            label: t('Blog'),
            href: 'http://boredcandycity.medium.com/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Community'),
            href: 'https://twitter.com/BoredCandyCity',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Join Us'),
            href: 'https://discord.gg/boredcandycity',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          // {
          //   label: t('Docs'),
          //   href: 'https://docs.pancakeswap.finance',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
        ].map((item) => addMenuItemSupported(item, chainId, account)),
      },
    ].map((item) => addMenuItemSupported(item, chainId, account))

export default config
