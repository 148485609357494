import BigNumber from 'bignumber.js'
import nftStakingAbi from 'config/abi/nftStaking.json'
import nftStakingV2Abi from 'config/abi/nftStakingV2.json'
import nftStakingV3Abi from 'config/abi/nftStakingV3.json'
import { publicClient } from 'utils/wagmi'
import { NFT_STAKING_TYPE, SerializedNftStakingConfig } from 'config/constants/types'
import erc721ABI from 'config/abi/erc721collection.json'
import RarityMerkleTree from 'config/constants/nfts/merkle/rarity-merkle.json'
import DuelsRarityMerkleTree from 'config/constants/nfts/merkle/duel-rarity-merkle.json'
import { getNftImageUrlFromEbisusBay } from 'utils/getNftImageUrl'
import collections from 'config/constants/nfts/collections'

export const fetchStakedNfts = async (
  account: string,
  dataToFetch: SerializedNftStakingConfig[],
  type: NFT_STAKING_TYPE,
  chainId: number
) => {
  const client = publicClient({ chainId })
  const abi = type === NFT_STAKING_TYPE.FIXED
    ? nftStakingAbi
    : type === NFT_STAKING_TYPE.SHARED
      ? nftStakingV2Abi
      : nftStakingV3Abi
  // fetch staked nfts
  const stakedCountCalls = dataToFetch.map((data) => (
    { abi, address: data.address, functionName: 'viewUserInfo', args: [account] }
  ))
  // @ts-ignore
  const rawStakedCounts = await client.multicall({ contracts: stakedCountCalls, allowFailure: false })
  const stakedCounts = rawStakedCounts.map((value) => Number(value[2]))

  const stakedNftsCalls = dataToFetch.map((data, index) => (
    { abi, address: data.address, functionName: 'stakedNfts', args: [account, 0, stakedCounts[index]] }
  ))
  // @ts-ignore
  const rawStakedNfts = await client.multicall({ contracts: stakedNftsCalls, allowFailure: false })

  const stakedNftsData = await Promise.all(rawStakedNfts.map((nfts, index) => new Promise((resolve) => {
    if (stakedCounts[index] === 0) {
      resolve([])
    } else {
      const isDuelNftStaking = dataToFetch[index].collection.address === collections.duels.address
      // @ts-ignore
      Promise.all(nfts.map((nft) => new Promise((resolvee) => {
        const tokenId = Number(nft)
        const rarity = isDuelNftStaking 
        // @ts-ignore
        ? DuelsRarityMerkleTree.claims?.[tokenId]?.rarity
        // @ts-ignore
        : RarityMerkleTree.claims?.[tokenId]?.rarity
        const proof = isDuelNftStaking 
        // @ts-ignore
        ? DuelsRarityMerkleTree.claims?.[tokenId]?.proof
        // @ts-ignore
        : RarityMerkleTree.claims?.[tokenId]?.proof

        getNftImageUrlFromEbisusBay(dataToFetch[index].collection.address, tokenId).then((image) => {
          resolvee({ id: tokenId, rarity, image, proof })
        })
      }))).then((data) => {
        resolve(data)
      })
    }
  })))
  return stakedNftsData
}

export const fetchNftStakingsRewards = async (
  account: string,
  dataToFetch: SerializedNftStakingConfig[],
  type: NFT_STAKING_TYPE,
  chainId: number
) => {
  const client = publicClient({ chainId })
  const pendingRewardCalls = dataToFetch.map((data) => {
    const nftStakingAddress = data.address
    return {
      abi: type === NFT_STAKING_TYPE.FIXED
        ? nftStakingAbi
        : type === NFT_STAKING_TYPE.SHARED
          ? nftStakingV2Abi
          : nftStakingV3Abi,
      address: nftStakingAddress,
      functionName: 'pendingRewards',
      args: [account],
    }
  })
  // @ts-ignore
  const rawPendingRewards = await client.multicall({ contracts: pendingRewardCalls, allowFailure: false })
  return rawPendingRewards.map((pendingReward) => new BigNumber(pendingReward.toString()).toJSON())
}

export const fetchNftStakingsApproved = async (
  account: string,
  dataToFetch: SerializedNftStakingConfig[],
  chainId: number
) => {
  const client = publicClient({ chainId })
  const nftApprovedCalls = dataToFetch.map((data) => {
    const nftAddress = data.collection.address
    const nftStakingAddress = data.address
    return { abi: erc721ABI, address: nftAddress, functionName: 'isApprovedForAll', args: [account, nftStakingAddress] }
  })
  // @ts-ignore
  const rawNftsApproved = await client.multicall({ contracts: nftApprovedCalls, allowFailure: false })

  return rawNftsApproved
}
