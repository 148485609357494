import BigNumber from 'bignumber.js'
import cakeVaultAbi from 'config/abi/cakeVaultV2.json'
import { getCakeVaultAddress, getCakeFlexibleSideVaultAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getCakeContract } from 'utils/contractHelpers'
import { publicClient } from 'utils/wagmi'
import { CANDY } from '@pancakeswap/tokens'

const balanceOfAbi = [
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const

export const fetchPublicVaultData = async (chainId) => {
  const cakeContract = getCakeContract(chainId)
  const cakeVaultAddress = getCakeVaultAddress(chainId)
  const client = publicClient({ chainId })
  const [sharePrice, shares, totalLockedAmount, totalCakeInVault] = await client.multicall({
    contracts: [
      {
        // @ts-ignore
        abi: cakeVaultAbi,
        address: cakeVaultAddress,
        functionName: 'getPricePerFullShare',
      },
      {
        // @ts-ignore
        abi: cakeVaultAbi,
        address: cakeVaultAddress,
        functionName: 'totalShares',
      },
      {
        // @ts-ignore
        abi: cakeVaultAbi,
        address: cakeVaultAddress,
        functionName: 'totalLockedAmount',
      },
      {
        abi: balanceOfAbi,
        address: CANDY[chainId].address,
        functionName: 'balanceOf',
        args: [cakeVaultAddress],
      },
    ],
    allowFailure: false,
  })

  const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
  const totalLockedAmountAsBigNumber = totalLockedAmount ? new BigNumber(totalLockedAmount.toString()) : BIG_ZERO
  const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
  return {
    totalShares: totalSharesAsBigNumber.toJSON(),
    totalLockedAmount: totalLockedAmountAsBigNumber.toJSON(),
    pricePerFullShare: sharePriceAsBigNumber.toJSON(),
    totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
  }
  // try {

  // } catch (error) {
  //   return {
  //     totalShares: null,
  //     totalLockedAmount: null,
  //     pricePerFullShare: null,
  //     totalCakeInVault: null,
  //   }
  // }
}

export const fetchPublicFlexibleSideVaultData = async (chainId) => {
  try {
    const cakeVaultAddress = getCakeFlexibleSideVaultAddress(chainId)
    const client = publicClient({ chainId })
    const [sharePrice, shares, totalCakeInVault] = await client.multicall({
      contracts: [
        {
          // @ts-ignore
          abi: cakeVaultAbi,
          address: cakeVaultAddress,
          functionName: 'getPricePerFullShare',
        },
        {
          // @ts-ignore
          abi: cakeVaultAbi,
          address: cakeVaultAddress,
          functionName: 'totalShares',
        },
        {
          abi: balanceOfAbi,
          address: CANDY[chainId].address,
          functionName: 'balanceOf',
          args: [cakeVaultAddress],
        },
      ],
      allowFailure: false,
    })

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      pricePerFullShare: null,
      totalCakeInVault: null,
    }
  }
}

export const fetchVaultFees = async (cakeVaultAddress, chainId) => {
  const client = publicClient({ chainId })

  try {
    const [performanceFee, withdrawalFee, withdrawalFeePeriod] = await client.multicall({
      contracts: [
        {
          // @ts-ignore
          abi: cakeVaultAbi,
          address: cakeVaultAddress,
          functionName: 'performanceFee',
        },
        {
          // @ts-ignore
          abi: cakeVaultAbi,
          address: cakeVaultAddress,
          functionName: 'withdrawFee',
        },
        {
          // @ts-ignore
          abi: cakeVaultAbi,
          address: cakeVaultAddress,
          functionName: 'withdrawFeePeriod',
        },
      ],
      allowFailure: false,
    })

    return {
      performanceFee: Number(performanceFee),
      withdrawalFee: Number(withdrawalFee),
      withdrawalFeePeriod: Number(withdrawalFeePeriod),
    }

  } catch (error) {
    return {
      performanceFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  }
}

export default fetchPublicVaultData
