import { ChainId } from '@pancakeswap/sdk'

export const verifyBscNetwork = (chainId: number) => {
  return chainId === ChainId.BSC || chainId === ChainId.BSC_TESTNET
}

export const verifyCronosNetwork = (chainId: number) => {
  return chainId === ChainId.CRONOS || chainId === ChainId.CRONOS_TESTNET
}

