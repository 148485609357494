import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import chunk from 'lodash/chunk'
import { getLockVaultContract } from "utils/contractHelpers";
import { publicClient } from "utils/wagmi";
import lockVaultAbi from "../../config/abi/lockvault.json";

const initialState = {
  locks: [],
  isLoading: true,
}

export const fetchAllLocksRewards = createAsyncThunk<
  any, { chainId: number, signer: string }
>("/locksvaults/fetchAllLocksRewards", async ({ chainId, signer }) => {
  if (!chainId && !signer) return [];
  const contract = getLockVaultContract(chainId)
  const calls = []
  const callsRewards = []
  const address = signer
  const lastLockId = Number(await contract.read.lastUserLockId([address]))
  const client = publicClient({ chainId })

  // @ts-ignore
  for (let i = 1; i <= lastLockId; i++) {
    calls.push({
      abi: lockVaultAbi,
      functionName: "userLockInfo",
      address: contract.address,
      args: [address, i]
    })
    /**
     * getClaimableLockRewards
     */
    callsRewards.push({
      abi: lockVaultAbi,
      functionName: "getClaimableLockRewards",
      address: contract.address,
      args: [address, i]
    })
  }

  let dataParsed = []
  try {
    const results = await client.multicall({ contracts: calls.concat(callsRewards), allowFailure: false })
    const [rewards, claimRewards] = results.length > 0 ? chunk(results, lastLockId) : [[], []]

    dataParsed = rewards?.map((r, i) => {
      const claim = claimRewards[i]
      return {
        id: i + 1,
        createdAt: Number(r[1]),
        amount: new BigNumber(r[0].toString()).div(10 ** 18).toString(10),
        alreadyClaimed: new BigNumber(r[3].toString()).div(10 ** 18).toString(10),
        lastRewardUpdated: Number(r[2]),
        userRewardsToBeClaim: new BigNumber(claim[0].toString()).div(10 ** 18).toString(10),
      }
    })

  } catch (err) {
    console.log(err)
  }
  return dataParsed
})

const lockvaultsSlice = createSlice({
  name: "lockvaults",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllLocksRewards.fulfilled, (state, action) => {
      state.locks = action.payload
      state.isLoading = false
    })
  }
})

export default lockvaultsSlice.reducer